import { Form, Row } from 'react-bootstrap'
import styled from 'styled-components'

export const AddButton = styled.button`
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  border: none;
  background-color: transparent;
  padding-left: 8px;
  padding-right: 8px;
  text-align: left;
  font-size: 12px;
  span {
    margin-left: 4px;
  }
`

export const PdfWrapper = styled.div`
  /* position: absolute;
  top: -100%;
  left: -100%; */
  /* z-index: -1;
  padding: 16px;
  width: 21cm;*/
  height: 1010px !important; 

  .diagnostic-field {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 500px;
      border: 1px solid rgb(229, 229, 229);
      border-radius: 5px;
      font-size: 12px;
      height: 25px;
      padding: 0px 6px;
      justify-content: space-between;
      margin-left: 6px;
  }

  .diagnostic-field .icon {
      display: flex;
      align-items: center;
  }

  #pdf-body {
      margin-bottom: 30px;
  }

  .title-container {
    display: flex;
    justify-content: center;
    margin: 5px 0px 12px;
  
    h2 {
      margin: auto;
      font-size: 18px;
    }
  }

  .row {
    margin-bottom: 2px;
  }

  label {
    margin-bottom: 2px;
  }

  input:disabled {
    border: none;
    margin-bottom: 2px;
  }

  table {
    border-collapse: collapse;
    margin-bottom: 8px;
    border: 1px solid #dddddd;
  }

  th {
    border: 1px solid #dddddd;
    padding: 6px 10px;
    text-align: center;
  }

  td {
    padding: 2px;
  }

  td,
  th {
    font-size: 12px;
    font-weight: normal;
    height: 16px;
  }

  tbody {
    tr:nth-child(even) {
      background-color: #dddddd;
    }
  }
`

export const PdfBorder = styled.div`
  border: 2px solid #868686;
  padding: 12px 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const PdfTitle = styled.h2`
  font-size: 14px;
  color: #4f4f4f;
  text-align: center;
`

export const PdfLabel = styled.p`
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 2px;
  span {
    font-weight: normal;
  }
`

export const PdfText = styled.p`
  font-size: 11px;
  margin-bottom: 2px;
  max-width: 100%;
  word-wrap: normal;
  word-break: break-all;
`

export const PdfTable = styled.table`
  td {
    font-size: 10px;
  }
  .border {
    border: 1px solid #4f4f4f;
  }
`

export const FormCheck = styled(Form.Check)`
  .form-check-input:disabled ~ .form-check-label,
  .form-check-input[disabled] ~ .form-check-label {
    opacity: 1 !important;
  }
  .form-check-input:disabled,
  .form-check-input[disabled] {
    opacity: 1 !important;
  }
`

export const PdfBox = styled.div`
  border: 1px solid #000;
  border-bottom: 3px solid #000;
  padding: 10px 10px 30px 10px;
  font-weight: 500;
`
export const Layout = styled.div`
  margin: 30;
  width: 1115px;
  position: absolute;
  opacity: 0;
  top: -660px;
  z-index: -1;
  height: fit-content;

  & .box.b {
    border-bottom: 2px solid #000;
  }
`

export const Section = styled.div`
  margin: 30;
`

export const Header = styled(Row)`
  margin-bottom: 30px;
  width: 80%;
  text-align: center;
`

export const HeaderText = styled.p`
  color: #2a8ed3;
  letter-spacing: 2px;
`

export const CheckContainer = styled.div`
  margin: 30px 0px 0px;
`

export const SignatureContainer = styled(Row)`
  margin: 15px 0px 0px;
`

export const FieldRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-right: 45px;
  }
`

export const AssignatureLabel = styled.label<{
  width: string
}>`
  height: 18px;
  border-bottom: 1px solid #000;
  margin-left: 5px;
  top: 2px;
  position: relative;
  width: ${(props) => props.width || '100%'};
`

export const TabTitleWrapper = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    display: inline-block;
    margin-right: 4px;
  }

  svg {
    margin-bottom: 2px;
  }
`