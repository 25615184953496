import axios from 'axios'
import html2canvas from 'html2canvas'
import $ from 'jquery'
import JsPDF from 'jspdf'
import { useEffect, useRef, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import CustomPDF from '../../components/transfusionRequest/CustomPDF'
import { Block } from '../../components'
import { CancelTransfusionalModal } from '../../components/cancelTransfusionRequestModal'
import { PageBadge, PageHeader, PageTitle, SubHeader } from '../../components/page/styles'
import { NewRequestSection } from '../../components/transfusionRequest/NewRequest'
import { PatientDetailsSection } from '../../components/transfusionRequest/PatientDetails'
import { PdfTransfustionRequest } from '../../components/transfusionRequest/PdfTransfustionRequest'
import {
  cancelTransfutionRequest,
  getTransfutionRequest,
  postUrlBiometric,
  validateBiometric,
  validateRequisition,
} from '../../service'
import { chunkArray } from '../../utils'

export const TransfusionRequestPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [rTdata, setRtData] = useState(null)
  const [items, setItems] = useState([])
  const [sentItems, setSentItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [links, setLinks] = useState<{
    edit: string
    cancel: string
    origin: string
    validate: string
  }>({
    edit: '',
    cancel: '',
    origin: '',
    validate: '',
  })
  const [rtStatus, setRtStatus] = useState<string>('')
  const [isBiometricModalOpen, setIsBiometricModalOpen] = useState(false)
  const [tokenBio, setTokenBio] = useState()
  const [isAuth, setIsAuth] = useState(false)
  const [newHTML, setNewHTML] = useState('')
  const [unityName, setUnityName] = useState('')
  const [isValidated, setIsValidated] = useState(false)
  const [isRequested, setIsRequested] = useState(false)
  const [requestType, setRequestType] = useState<'confirm' | 'cancel' | null>(null)
  const [justification, setJustification] = useState('')
  const [isValidatedBloodBank, setIsValidatedBloodBank] = useState(false)
  const blockRef = useRef(null);
  const targetRef = useRef();

  const transfusionPdf = new JsPDF({
    orientation: 'portrait',
    unit: 'px',
    format: [600, 650],
  });

  // eslint-disable-next-line consistent-return
  async function captureContentItems(item, index) {
    try {

      const content = item;
      const canvas = await html2canvas(content);
      const imgData = await canvas.toDataURL('image/png');

      if (index) {
        transfusionPdf.addPage()
      }

      transfusionPdf.addImage(
        imgData,
        'PNG',
        20,
        10,
        560,
        620,
        null,
      )

      return imgData

    } catch (e) {
      console.error({ e })
    }
  };

  const toPDF = async () => {
    try {
      const listItems = Array.from(document.getElementsByClassName('page'));

      await Promise.all(listItems.map(async (item, index) =>
        captureContentItems(item, index)
      ))

      transfusionPdf.save(`Requisicao-Transfusao(${rTdata.id}).pdf`);
    } catch (err) {
      console.error({ err })
    }
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  const appForwardRef = (props) => <PdfTransfustionRequest {...props} ref={targetRef} />

  const sendToOrigin = () => {
    toast.info('Você será redirecionado para página de origem em instantes')
    setTimeout(() => {
      window.location.href = links.origin
    }, 3000)
  }

  const printDocument = () => {
    const input1 = document.getElementById('divToPrint1')
    const input2 = document.getElementById('divToPrint2')
    let point = 0

    Promise.all([html2canvas(input1, { scale: 1 }), html2canvas(input2, { scale: 1 })]).then(
      (canvasArray) => {
        const pdf = new JsPDF({
          orientation: 'portrait',
          unit: 'pt',
          format: [595.28, 595.28],
        })
        canvasArray.forEach((canvas) => {
          const imgWidth = 555.28
          const imgHeight = ((canvas.height * imgWidth) / canvas.width) * 1.3
          const positionX = (pdf.internal.pageSize.getWidth() - imgWidth) / 2
          const positionY = 20
          pdf.addImage(
            canvas.toDataURL('image/png'),
            'PNG',
            positionX,
            positionY,
            imgWidth,
            imgHeight,
            null,
          )

          if (point < 1) {
            pdf.addPage()
          }

          point += 1
        })
        pdf.save('Termo de consetimento.pdf')
      },
    )
  }


  const checkRedirection = () => {
    printDocument()

    if (toPDF) {
      toPDF()
    }
    if (!links.origin || links.origin === "") {
      navigate('/feedback?status=true')
    } else {
      sendToOrigin()
    }
  }

  const doValidateRequisition = async () => {
    try {
      setLoading(true)
      await validateRequisition(id, links.validate)
      checkRedirection()
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const fixData = (data) => {
    let newData = data.replace('paramDedos= ;', "paramDedos= '';")
    newData = newData.replace('<?xml version="1.0" encoding="UTF-8"?>', '')
    newData = newData.replace('href="#"', 'href="javascript:void(0);"')
    newData = newData.replace(/<img src=""/g, '<img')
    newData = newData.replace(
      /<link rel="stylesheet" href="(.*?)"[^>]*/g,
      '<link rel="text/css" href="../../assets/css/biometria.css"',
    )
    newData = newData.replace(
      '<!DOCTYPE composition PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">',
      '',
    )
    newData = newData.replace(
      `<script type="text/javascript" src="${process.env.REACT_APP_HTTP_WSENV}resources/js/capturaTentativas.js"/>`,
      `<script type="text/javascript" src="${process.env.REACT_APP_HTTPS_WSENV}resources/js/capturaTentativas.js"></script>`,
    )
    return newData
  }

  const newUrlBio = (url: string) => url.replace('http', 'https')

  const validateBio = async (baseAuth, newBase64) => {
    try {
      const { data } = await validateBiometric({ baseAuth, base64: newBase64, token: tokenBio })
      toast.success(data?.content?.biometria?.mensagemValidacao || 'Biometria realizada com sucesso')
      setIsAuth(true)

      if (requestType === 'cancel') {
        setIsCancelModalOpen(true)
      } else {
        printDocument()
        toPDF()
      }
    } catch (error) {
      toast.error(error.response.data.content || error.response.data?.notifications[0]?.message)
    } finally {
      setIsBiometricModalOpen(false)

      if (requestType !== "cancel") {
        sendToOrigin()
      }
    }
  }

  const checkBio = () => {
    const interval = setInterval(() => {
      if (isAuth) {
        clearInterval(interval)
        return
      }
      if (sessionStorage.getItem('bio_base64')) {
        validateBio(rTdata.contextName, sessionStorage.getItem('bio_base64'))
        clearInterval(interval)
      }
    }, 1000)
  }

  const injetarHTML = (data) => {
    $('#biometria').append(data)
    $('#confirmar').remove()
    $('#cancelar').remove()
    checkBio()
  }

  const getUrlLegacy = async () => {
    try {
      const { data } = await postUrlBiometric(rTdata.doctorLoginName, rTdata.contextName)
      setTokenBio(data.content.biometry.token)

      const newContent = data.content
      const newUrl = newUrlBio(newContent.biometry.url)
      const response = await axios.get(newUrl)

      const newHtml = fixData(response.data)
      setIsBiometricModalOpen(true)
      setNewHTML(newHtml)
    } catch (error) {
      toast.error('Algo deu errado. Tente novamente.')
      setIsBiometricModalOpen(false)
      console.error(error)
    }
    setIsBiometricModalOpen(true)
  }

  const doGetTransfutionRequest = async () => {
    try {
      setLoading(true)

      const { data } = await getTransfutionRequest(id)

      setRtData({
        ...(data?.content?.transfusionRequest || null),
        unityName: data?.content?.unityName,
        previousTransfusionDescription: data?.content?.previousTransfusionDescription,
        previousTransfusionReactionsDescription: data?.content?.previousTransfusionReactionsDescription,
      })

      const rtItems = data.content.transfusionRequestItems.map((item) => ({ ...item }))
      const firstItem = rtItems.shift()
      const chunkedArray = chunkArray(rtItems, 2)
      chunkedArray.unshift([firstItem])

      setSentItems(chunkedArray)
      setItems(rtItems || [])
      setRtStatus(data?.content?.statusRequest || '')
      setUnityName(data?.content?.unityName || '')
      setLinks({
        edit: data?.content?.transfusionRequest?.linkEdit || '',
        cancel: data?.content?.transfusionRequest?.linkCancel || '',
        origin: data?.content?.transfusionRequest?.linkOrigin || '',
        validate: data?.content?.transfusionRequest?.linkValidate || '',
      })
      const valueBloodbank = sessionStorage.originType;
      if (valueBloodbank === 'bloodbank') {
        setIsValidatedBloodBank(true);
      }
    } catch ({ request, response }) {
      toast.error('Algo deu errado na requisição. Tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const doCancelRequest = async () => {
    try {
      await cancelTransfutionRequest(id, justification, links.cancel)
      toast.success('Cancelamento realizado com sucesso.')

      if (!links.origin || links.origin === "") {
        navigate('/feedback?status=false')
      } else {
        sendToOrigin()
      }
    } catch (error) {
      toast.error('Algo deu errado. Tente novamente mais tarde.')
    }
  }

  const confirmRequest = () => {
    getUrlLegacy();
    setRequestType('confirm')
  }

  const confirmCancel = () => {
    getUrlLegacy()
    setRequestType('cancel')
  }

  useEffect(() => {
    doGetTransfutionRequest()
  }, [id])

  useEffect(() => {
    if (isBiometricModalOpen) {
      injetarHTML(newHTML)
    }
  }, [newHTML, isBiometricModalOpen])

  useEffect(() => {
    if (!isBiometricModalOpen) {
      sessionStorage.setItem('bio_base64', '')
      $('#biometria').empty()
    }
  }, [isBiometricModalOpen])

  useEffect(() => {
    setIsValidated(rtStatus.toLowerCase() === 'validado')
    setIsRequested(rtStatus.toLowerCase() === 'solicitado')
  }, [rtStatus])

  useEffect(() => {
    if (isAuth && requestType === 'confirm') {
      doValidateRequisition()
    }
  }, [isAuth, requestType])

  useEffect(() => {
    if (!isCancelModalOpen) {
      setRequestType(null)
    }
  }, [isCancelModalOpen])

  return (
    <>

      <Block>
        <div ref={blockRef}>

          <PageHeader>
            <PageTitle>REQUISIÇÃO DE TRANSFUSÃO DE SANGUE</PageTitle>
            {/* <div>
                <Button variant='outline-primary' disabled>
                  Nova ficha
                </Button>
                <Button variant='outline-primary' disabled>
                  Histórico
                </Button>
              </div> */}
          </PageHeader>
          <SubHeader>
            <div>
              <p className='bold-text s-12'>Hospital:</p>
              <p className='s-14'>{unityName}</p>
            </div>
            <PageBadge>
              Nº RT: #{id} - {rtStatus?.toUpperCase() || ''}
            </PageBadge>
          </SubHeader>
          {loading && !rTdata && 'Carregando registros...'}
          {!loading && rTdata && (
            <>
              <PatientDetailsSection data={rTdata} />
              <NewRequestSection data={rTdata} items={items} />
              <CustomPDF data={rTdata} />
            </>
          )}
        </div>
        <Row className='h-flex-end'>
          <Col md={1}>
            <Button
              type='button'
              className='w-100'
              onClick={() => navigate(-1)}
              variant='outline-secondary'
              size='sm'
            >
              Voltar
            </Button>
          </Col>
          {!isValidatedBloodBank && !isValidated ? (
            <>
              <Col md={2}>
                <Button
                  type='button'
                  className={`w-100 ${!isRequested ? 'disabled' : ''}`}
                  size='sm'
                  variant='outline-danger'
                  onClick={confirmCancel}
                  disabled={!isRequested}
                >
                  Cancelar Requisição
                </Button>
              </Col>
              <Col md={2}>
                <Button
                  as='a'
                  href={links?.edit || ''}
                  type='button'
                  className={`w-100 ${!isRequested ? 'disabled' : ''}`}
                  size='sm'
                  variant='outline-primary'
                  disabled={!isRequested}
                >
                  Editar Requisição
                </Button>
              </Col>
            </>
          ) : null}

          {(isValidated) ? (
            <Col md={2}>
              <Button
                as='a'
                className={`w-100 ${loading ? 'disabled' : ''}`}
                size='sm'
                variant='outline-primary'
                disabled={loading}
                onClick={() => toPDF()}
              >
                Reimprimir Requisição
              </Button>
            </Col>
          ) : (<div />)}

          {(!isValidated && !isValidatedBloodBank) ? (
            <Col md={2}>
              <Button
                as='a'
                className='w-100'
                size='sm'
                onClick={confirmRequest}
                disabled={loading || !rTdata}
              >
                Confirmar requisição
              </Button>
            </Col>
          ) : (
            <div />
          )}


        </Row>
      </Block>

      <Modal show={isBiometricModalOpen} onHide={() => setIsBiometricModalOpen(false)}>
        <Modal.Body>
          <div id='biometria' />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='button'
            size='sm'
            variant='outline-danger'
            onClick={() => setIsBiometricModalOpen(false)}
          >
            Cancelar biometria
          </Button>
        </Modal.Footer>
      </Modal>

      <div style={{ position: "absolute", top: 0, height: 0, overflow: "hidden" }}>
        {rTdata && appForwardRef({ ...rTdata, items: sentItems })}
      </div>

      <CancelTransfusionalModal
        show={isCancelModalOpen}
        handleClose={() => setIsCancelModalOpen(false)}
        confirmCancel={doCancelRequest}
        justification={justification}
        setJustification={setJustification}
      />
    </>
  )
}
