import styled from 'styled-components'

export const Suggestions = styled.ul`
  border: 1px solid #c4c4c4;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
  position: absolute;
  z-index: 1;
  background-color: white;
  border-radius: 4px;
  li {
    padding: 0.5rem;
    font-size: 12px;
    &:hover,
    &.suggestion-active {
      background-color: rgba(0, 101, 169, 0.75);
      color: white;
      cursor: pointer;
      font-weight: 700;
    }
    &:not(:last-of-type) {
      border-bottom: 1px solid #c4c4c4;
    }
  }
`
