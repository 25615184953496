import { FC } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { SubHeader, SubTitle } from '../page/styles'
import { CancelTransfusionalModalStyles } from './styles'

export const JustificationModal: FC<any> = ({
  handleClose,
  handleConfirm,
  show,
}) => (
  <CancelTransfusionalModalStyles show={show} onHide={handleClose}>
    <Modal.Body>
      <SubHeader style={{ justifyContent: 'center' }}>
        <SubTitle style={{ color: '#0064A9', fontSize: 13, textAlign: 'center' }}>
          Informe a justificativa para o envio do hemocomponente.
        </SubTitle>
      </SubHeader>
    </Modal.Body>
    <Modal.Footer style={{ justifyContent: 'center' }}>
      <Button size='sm' variant='outline-secondary' onClick={handleClose}>
        Fechar
      </Button>
      <Button
        size='sm'
        variant='primary'
        onClick={() => handleConfirm()}
      >
        Enviar justificativa
      </Button>
    </Modal.Footer>
  </CancelTransfusionalModalStyles>
)
