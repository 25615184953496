import { FC, useEffect, useState } from 'react'
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import { TableStyles } from './styles'
import { TabsPropTypes } from './types'

const headers = [
  'Cód. Unid.',
  'Nome da Unidade',
  'Cód. Prestador Autorização',
  'Status',
  'Visualizar/Editar',
]

export const UnitiesTabTable: FC<TabsPropTypes> = ({ onSelect, data }) => {
  const [sorted, setSorted] = useState<any[]>([])

  useEffect(() => {
    setSorted(
      data.sort((a, b) => {
        if (a.id > b.id) {
          return -1
        }
        if (a.id < b.id) {
          return 1
        }
        return 0
      }),
    )
  }, [data])

  return (
    <TableStyles className='tabs-table'>
      <thead>
        <tr>
          {headers.map((item) => (
            <th key={item}>{item}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sorted.map((item) => (
          <tr key={item.id}>
            <td>{item.unitCode}</td>
            <td>{item.unitName}</td>
            <td>{`${item.code}`}</td>
            <td>{item.unitStatus ? 'Ativo' : 'Inativo'}</td>
            <td style={{ textAlign: 'center' }}>
              <button
                style={{ width: '100%' }}
                className='table-icon-btn'
                type='button'
                onClick={() => onSelect(item.id, item.unitCode)}
              >
                <EditIcon />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </TableStyles>
  )
}
