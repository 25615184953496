/* eslint-disable react/jsx-no-useless-fragment */
import { format } from 'date-fns'
import { FC } from 'react'
import { Col } from 'react-bootstrap'
import { maskCpfOrCnpj } from '../../utils/mask'
import DocField from '../docField'
import { DocLabel } from '../form/styles'
import { SubTitle } from '../page/styles'
import { PatientFieldRow } from './styles'
import { DetailsSectionType } from './types'

export const BloodOutputPatientDetailsSection: FC<DetailsSectionType> = ({ rtData }) => {

  const getField = (name) => rtData[name] || ''

  const getGender = () => (getField('patientSex') === 'F' ? 'Feminino' : 'Masculino')

  return (
    <>
      {
        Object.keys(rtData).length ? (
          <>
            <PatientFieldRow style={{ gap: 20 }}>
              <Col className='d-flex  flex-dir-col space-between w-auto' style={{ maxWidth: 'fit-content', flex: 'auto' }} >
                <DocLabel>Nome do Paciente:</DocLabel>
                <DocField style={{ fontWeight: 700 }} value={getField('patientName')} />
              </Col>

              <Col className='d-flex  flex-dir-col space-between w-auto' style={{ maxWidth: 'fit-content', flex: 'auto' }} >
                <DocLabel>Nome da Mãe:</DocLabel>
                <DocField value={getField('patientMar')} />
              </Col>

              <Col className='d-flex  flex-dir-col space-between w-auto' style={{ maxWidth: 'fit-content', flex: 'auto' }} >
                <DocLabel>Data de Nasc.:</DocLabel>
                <DocField value={format(new Date(getField('patientBornDate')), 'dd/MM/yyyy')} />
              </Col>

              <Col className='d-flex  flex-dir-col space-between w-auto' style={{ maxWidth: 'fit-content', flex: 'auto' }} >
                <DocLabel>Idade:</DocLabel>
                <DocField value={`${Math.floor(
                  (Date.now() - new Date(rtData.patientBornDate).getTime()) / 31557600000,
                )} anos`} />
              </Col>

              <Col className='d-flex  flex-dir-col space-between w-auto' style={{ maxWidth: 'fit-content', flex: 'auto' }} >
                <DocLabel>Sexo:</DocLabel>
                <DocField value={getGender()} />
              </Col>

              <Col className='d-flex  flex-dir-col space-between w-auto' style={{ maxWidth: 'fit-content', flex: 'auto' }} >
                <DocLabel>CPF:</DocLabel>
                <DocField value={maskCpfOrCnpj(getField('patientCpf'))} />
              </Col>

              <Col className='d-flex  flex-dir-col space-between w-auto' style={{ maxWidth: 'fit-content', flex: 'auto' }} >
                <DocLabel>RG:</DocLabel>
                <DocField value={maskCpfOrCnpj(getField('patientRg'))} />
              </Col>

              <Col className='d-flex  flex-dir-col space-between w-auto' style={{ maxWidth: 'fit-content', flex: 'auto' }} >
                <DocLabel>Peso:</DocLabel>
                <DocField
                  value={`${getField('patientWeight')} kg`}
                  disabled={!getField('patientWeight').lenght}
                />
              </Col>

              <Col className='d-flex  flex-dir-col space-between w-auto' style={{ maxWidth: 'fit-content', flex: 'auto' }} >
                <DocLabel>Unidade:</DocLabel>
                <DocField value={getField('unityName')} />
              </Col>

              <Col className='d-flex  flex-dir-col space-between w-auto' style={{ maxWidth: 'fit-content', flex: 'auto' }} >
                <DocLabel>Clínica:</DocLabel>
                <DocField value={getField('')} />
              </Col>

              <Col className='d-flex  flex-dir-col space-between w-auto' style={{ maxWidth: 'fit-content', flex: 'auto' }} >
                <DocLabel>Enfermaria/APT°:</DocLabel>
                <DocField value={getField('')} />
              </Col>

              <Col className='d-flex  flex-dir-col space-between w-auto' style={{ maxWidth: 'fit-content', flex: 'auto' }} >
                <DocLabel>Leito:</DocLabel>
                <DocField value={getField('bedName')} />
              </Col>

              <Col className='d-flex  flex-dir-col space-between w-auto' style={{ maxWidth: 'fit-content', flex: 'auto' }} >
                <DocLabel>ABO/Rh:</DocLabel>
                <DocField value={getField('aboRh')} />
              </Col>
            </PatientFieldRow>
          </>
        ) : (
          <SubTitle>Nenhum registro encontrado!</SubTitle>
        )
      }
    </>
  )
}
