import { Modal, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { SubHeader } from '../page/styles'

export const ModalStyles = styled(Modal)`
  display: flex !important;
  align-items: center;

  .component-researcher {
    width: 100%;
  }

  .component-researcher input {
    width: 100%;
    height: 47px;
    border-radius: 5px;
  }

  .modal-body hr {
    margin: 20px 0px;
  }

  .form-check {
    display: flex;
    align-items: center;
  }

  .form-check .form-check-label {
    font-size: 14px;
    line-height: 14px;
    height: 13px;
    margin-left: 10px;
    white-space: nowrap;
  }

  .modal-content {
    padding: 13px 13px !important;
  }

  .justify-area textarea {
    height: 70px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #c4c4c4;
  }

  .modal-header .modal-title {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    margin-bottom: 15px;
    color: #000;
  }

  textarea {
    padding: 15px 12px;
    resize: none;
    font-size: 14px;
    color: #dd0404;
  }

  .modal-footer button,
  .modal-body button {
    font-size: 12px;
    height: 24px;
    padding: 0px 10px !important;
  }

  table {
    margin-bottom: 21px;
  }

  table th {
    font-weight: 400;
    font-size: 12px;
    padding-right: 15px;
    line-height: 14px;
    text-align: start;
    color: rgb(117, 117, 117);
  }

  table tr {
    height: 20px;
  }

  .component-researcher input {
    padding: 12px;
  }

  table th:nth-child(3) {
    padding: 0px;
  }

  .theader {
    font-weight: 400;
    font-size: 12px;
    padding-right: 15px;
    line-height: 14px;
    color: #757575;
  }

  .tdata {
    font-weight: 800;
    font-size: 13px;
    padding-right: 15px;
    line-height: 18px;
    color: #000000 !important;
  }

  .modal {
    &-header {
      border: none;
      justify-content: center;
      padding: 0px;
    }

    &-body {
      width: 100%;
      padding: 0px;
    }

    &-dialog {
      max-width: fit-content;
      width: 100%;
    }
    &-content {
      width: 423px;
      padding: 0px 11px;
      max-width: 100%;

      .btn {
        padding: 4px 20px;
        &-primary {
          background-color: #0064a9;
        }
      }
    }

    &-footer {
      border-top: none;
      padding: 0px;
    }
  }
`

export const SubHeaderStampModal = styled(SubHeader)`
  display: flex;
  justify-content: center;
  margin: 0px;
`
export const InfoBlock = styled.div`
  border-radius: 10px;
  border: 1px dashed #a7a7a7;
  padding: 10px;
  width: 100%;
  min-height: 130px;
`

export const Label = styled.p`
  font-size: 12px;
  color: #4f4f4f;
  margin-bottom: 0px;
`

export const Text = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`

export const TextALt = styled(Text)`
  font-size: 12px;
  margin-bottom: 2px;
`

export const LabelAlt = styled(Label)`
  font-size: 12px;
`

export const InputInstructions = styled(Row)`
    justify-content: center!important;
    align-items: center!important;

    hr {
      margin: 10px 0px !important;
    }
`
