import { ArcElement, Chart as ChartJS, Tooltip } from 'chart.js'
import { FC, useEffect, useState } from 'react'
import { DoughChartTitle, DoughnutChartStyles, DoughnutChartWrapper } from './styles'
import { DoughnutChartPropTypes } from './types'

ChartJS.register(ArcElement, Tooltip)

const InitialDataSets = {
  data: [300, 50, 100],
  backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
  hoverOffset: 4,
}

export const DoughnutChart: FC<DoughnutChartPropTypes> = ({ data, title }) => {
  const [labels, setLabels] = useState<string[]>([])
  const [values, setValues] = useState<number[]>([])
  const [colors, setColors] = useState<string[]>([])

  useEffect(() => {
    const tempLabels: string[] = []
    const tempValues: number[] = []
    const tempColors: string[] = []

    data.forEach((item) => {
      tempLabels.push(item.label)
      tempValues.push(item.value)
      tempColors.push(item.color)
    })

    setLabels(tempLabels)
    setValues(tempValues)
    setColors(tempColors)
  }, [data])

  return (
    <DoughnutChartWrapper>
      <DoughChartTitle>{title}</DoughChartTitle>
      <DoughnutChartStyles
        height='100%'
        width='100%'
        data={{ labels, datasets: [{ ...InitialDataSets, data: values, backgroundColor: colors }] }}
      />
    </DoughnutChartWrapper>
  )
}
