import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  *{
    box-sizing:border-box;
    margin:0px;
    padding:0px;
    list-style:none;
    text-decoration:none;
    outline: none;
    outline: none;
    color:#4F4F4F;
  }

  #root{
    min-height:100vh;
    height:100vh;
    width:100%;
    max-width:100vw;
    overflow-x:hidden;
  }

  p{
    margin:0;
  }

  hr{
    margin-top:8px;
    margin-bottom:8px;
  }

  .no-padding-x {
    .col:first-child {
      padding-left: 0;
    }
    .col:last-child {
      padding-right: 0;
    }
  }

  .table-row{
    margin-top:20px;
    margin-bottom:20px;
  }

  .d-flex{
    display:flex;
  }

  .flex-dir-col{
    flex-direction:column;
  }

  .space-between{
    justify-content:space-around;
  }

  .h-flex-end{
    justify-content:flex-end;
  }

  .bold-text{
    font-weight:bold;
  }

  .mb-{
    &10{
      margin-bottom:10px;
    }
    &18{
      margin-bottom:18px;
    }
    &8{
      margin-bottom:8px;
    }
  }

  .mt-{
    &10{
      margin-top:10px;
    }
    &18{
      margin-top:18px;
    }
  }

  .fs-{
    &13{
      font-size:13px;
    }
  }

  .s-{
    &10{
      font-size:10px;
    }
    &12{
      font-size:12px;
    }
    &14{
      font-size:14px;
    }
  }

  .w-100{
    width:100%;
  }

  .hpx-{
    &40{
      height:40px;
    }
      &60{
      height:60px;
    }
  }

  .reset-row{
    justify-content:flex-start;
    align-items:flex-start;
  }

  input{
    box-shadow:none;
    border: 1px solid #c4c4c4 ;
  }

  button{
    box-shadow:none!important;
  }

  .subtitle-text{
    color: #868686;
    font-size: 12px;
  }

  .btn-primary{
    background-color:#0064A9;
    border:none;
  }

  .btn-outline-primary {
    border-color:#0064A9;
    color:#0064A9;
  }

  .disabled{
    cursor: initial;
    pointer-events:none;
    opacity: 0.65s;
  }

  .tab-row{
    border:1px solid #C4C4C4;
    border-radius:6px;
    padding:14px 8px;
  }

  .tabs-component{
    max-width:100%;
    overflow-x:auto;
    overflow-y:hidden;
    flex-wrap:nowrap;
    .nav-link{
      border-radius:6px 6px 0px 0px;
      border-color:#C4C4C4!important;
      font-weight:bold;
      font-size:12px;
      margin-right:2px;
      color:#4F4F4F;
      border-bottom:none;
      white-space:nowrap;
      &.active{
        background-color:#E5E5E5;
        color:#000;
      }
    }
  }

  .text-center{
    text-align:center;
  }

  .text-end {
    justify-content: end;
  }
  
  select {
    -moz-appearance: none;
  }

  .react-datepicker__navigation-icon--previous {
    right: 0px;
  }

  .react-datepicker__navigation-icon{
    top: 0px;
  }

  .react-datepicker__navigation-icon--next {
    left: 0px;
  }

  .react-datepicker__navigation-icon {
    position: relative;
    top: -1px;
    font-size: 20px;
    width: 32px !important;
  }

  .react-datepicker__navigation-icon--next::before {
    left: 0px;
    top: 14px;
  }


  .react-datepicker__navigation-icon--previous::before{
    right: 0px;
    top: 14px;
  }

  .react-datepicker-time__header {
    font-size: 0px;

  }

  .react-datepicker-time__header::after {
    content: "Hora";
    display: block !important;
    font-size: 15px;

  }

  div#transfusion-reactions span.checkmark {
    position: absolute;
    top: calc(50% - 10px);
    left: -1px;
  }
  
`
