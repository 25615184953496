import { Button, Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { AppSelect, Block } from '../../components'
import { DocInput } from '../../components/form/styles'
import { PageHeader, PageTitle } from '../../components/page/styles'
import { UsersTable } from '../../components/tables/UsersTable'

export const ListUsersView = () => {
  const navigate = useNavigate()


  return (
    <Block className='list-user-page'>
      <PageHeader>
        <PageTitle>Usuários</PageTitle>
        <Button variant='primary' onClick={() => navigate('/usuario/cadastrar')}>
          Criar novo usuário
        </Button>
      </PageHeader>
      <Row>
        <Col md={2}>
          <DocInput name='user-code' placeholder='Código do usuário' />
        </Col>
        <Col md={4}>
          <DocInput name='user-name' className='w-100' placeholder='Nome do usuário' />
        </Col>
        <Col md={1}>
          <AppSelect
            placeholderText='Status'
            options={[
              { label: 'Ativo', value: 'active' },
              { label: 'Inativo', value: 'inactive' },
            ]}

            onChange={() => null}
          />
        </Col>
      </Row>
      <Row>
        <UsersTable
          headers={['Código', 'Nome do usuário', 'Status', 'Visualizar', 'Editar']}
          data={[{ cod: '1', name: 'Test', status: 'Ativo' }]}
        />
      </Row>
    </Block>
  )
}
