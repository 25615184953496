import styled from 'styled-components'
import { Button, Row } from 'react-bootstrap'
import { CustomDatePicker } from '../../components/datePicker/styles'


export const FormFieldsRow = styled(Row)`
  margin: 0px -20px;

  && > div {
    margin: 10px
  }

  && input {
    background-color: #fff;
  }
`


export const DateField = styled(CustomDatePicker)`
  height: 25px;
`

export const RTNumber = styled(Button)`
  padding: 6px 15px;
  font-size: 12px;
  color: #4F4F4F;
  border-color: #C4C4C4;

  &&:hover {
    background-color: inherit;
    color: inherit;
    border-color: #C4C4C4; 
  }
`