import { Row } from 'react-bootstrap'
import styled from 'styled-components'

export const PatientFieldRow = styled(Row)`
  margin: -20px -22px;

  && > div {
    margin: 10px;
  width: auto;

  } 

`

