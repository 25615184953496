import styled from "styled-components";

export const DocFieldStyle = styled.div`
  border-radius: 5px;
  background-color: transparent;
  font-size: 14px;
  height: 25px;
  padding: 0;
  width: 100%;
  &:disabled {
    border-color: rgba(196, 196, 196, 0);
  }
  &.borderless {
    border: none;
  }
`