import { FC } from 'react'
import { Step, Steps, TimeLineStyles } from './styles'
import { StepTypes, TimeLinePropTypes } from './types'

export const TimeLine: FC<TimeLinePropTypes> = ({ steps }) => (
  <TimeLineStyles>
    <Steps>
      {steps.map(({ label, timeStamp, status }: StepTypes) => (
        <Step key={label}>
          <p className='label'>
            {label}
            {status ? ` (${status})` : ''}
          </p>

          <p className='timestamp'>{timeStamp}</p>
          <span className={`dot ${timeStamp ? 'active' : null}`} />
        </Step>
      ))}
    </Steps>
  </TimeLineStyles>
)
