import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { SubHeader } from '../page/styles'

export const TypingStampModalStyles = styled(Modal)`
  display: flex !important;
  align-items: center;
  .modal {
    &-body {
      width: 100%;
    }

    &-dialog {
      max-width: fit-content;
      width: 100%;
    }
    &-content {
      width: 580px;
      padding: 0px 15px;
      max-width: 100%;

      .btn {
        padding: 4px 20px;
        &-primary {
          background-color: #0064a9;
        }
      }
    }

    &-footer {
      border-top: none;
      padding: 0px;
    }
  }
`

export const SubHeaderStampModal = styled(SubHeader)`
  display: flex;
  justify-content: center;
  margin: 0px;
`
export const InfoBlock = styled.div`
  border-radius: 10px;
  border: 1px dashed #a7a7a7;
  padding: 10px;
  width: 100%;
  min-height: 130px;
`

export const Label = styled.p`
  font-size: 11px;
  margin-bottom: 0px;
`

export const Text = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`

export const TextALt = styled(Text)`
  font-size: 12px;
  margin-bottom: 2px;
`

export const LabelAlt = styled(Label)`
  font-size: 12px;
`