/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-no-useless-fragment */
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { PageTitle } from '../page/styles'
import { WarnModalStyles } from './styles'
import { WarnModalPropTypes } from './types'

export const WarnModal = ({
  show,
  title,
  warn,
  handleClose,
  callback
}: WarnModalPropTypes) => {

  const handleSubmit = async () => {
    handleClose()
    callback(true)
  }

  const handleCancel = () => {
    handleClose()
    callback(false)
  }

  return (
    <WarnModalStyles show={show} >
      <Modal.Body >
        <div className='logo-wrapper'>
          <PageTitle style={{ textAlign: 'center', fontSize: 18, color: '#DD0404' }}>
            {title}
          </PageTitle>
        </div>
        <div dangerouslySetInnerHTML={{ __html: warn }} />
      </Modal.Body>
      <Modal.Footer>
        <Row className='w-100' style={{ justifyContent: 'center', marginTop: 20 }} >
          <Col style={{ flex: 0, padding: 0, marginRight: 20 }} >
            <Button
              style={{ height: 42, fontSize: 12, width: 93 }}
              variant='primary'
              onClick={handleSubmit}
            >
              Sim
            </Button>
          </Col>
          <Col style={{ flex: 0, padding: 0 }}>
            <Button
              style={{ height: 42, fontSize: 12, width: 93 }}
              variant='primary'
              onClick={handleCancel}
            >
              Não!
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </WarnModalStyles>
  )
}
