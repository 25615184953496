/* eslint-disable react/display-name */
import { format } from 'date-fns'
import { forwardRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import { formatNumber, getDateModalityTransf, getField } from '../../utils'
import { maskCpfOrCnpj, maskRg } from '../../utils/mask'
import { SubHeader, SubTitle } from '../page/styles'
import { PdfBorder, PdfLabel, PdfTable, PdfText, PdfTitle, PdfWrapper } from './styles'
import { ReactComponent as DiagnosticIcon } from '../../assets/icons/ellipse.svg'

interface PdfTransfusionRequestData {
  unityName: string;
  id: number;
  attendanceCode: string;
  patientName: string;
  pf: string;
  patientBornDate: string;
  patientSex: 'F' | 'M';
  patientWeight: number;
  attendanceCodeCid: string;
  attendanceCid: string;
  items: {
    id: number;
    bloodComponentName: string;
    amountDoseDrug: number;
    codeUnityUsual: string;
    specialProced: string;
    specialProcedJustDescription: string;
    drugInformation: string;
    useIndicationDescription: string;
    useContraIndicationDescription: string;
    justContraIndicationDescription: string;
  }[][];
  doctorName: string;
  doctorCrmNumber: string;
}

export const PdfTransfustionRequest = forwardRef<any>((data: PdfTransfusionRequestData, ref) => (
  <>
    <PdfWrapper className='page' ref={ref}>
      <PdfBorder>
        <div id="pdf-header">
          <div className='title-container'>
            <PdfTitle>REQUISIÇÃO DE TRANSFUSÃO DE SANGUE</PdfTitle>
            <div className='page-number'>
              <span>p.1</span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 6,
              marginBottom: 6,
            }}
          >
            <h3 style={{ fontSize: 14 }}>
              Hospital:&nbsp;
              <span style={{ fontWeight: 'normal', fontSize: 16 }}>{getField('unityName', data)}</span>
            </h3>
            <span style={{ fontWeight: 'normal', fontSize: 16 }}>
              Nº Transfusão: #{getField('id', data)}
            </span>
          </div>
          <hr style={{ marginBottom: 8 }} />
          <SubHeader style={{ marginBottom: 6 }}>
            <SubTitle>DADOS DO PACIENTE</SubTitle>
            <SubTitle>N° Atend.: {getField('attendanceCode', data)}</SubTitle>
          </SubHeader>
          <Row>
            <Col className='d-flex flex-dir-col space-between' md={3}>
              <PdfLabel>Nome do Paciente:</PdfLabel>
              <PdfText style={{ fontWeight: "bold", textTransform: "uppercase" }}>{getField('patientName', data)}</PdfText>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <PdfLabel>PF:</PdfLabel>
              <PdfText>{getField('pf', data)}</PdfText>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <PdfLabel>Data de Nasc.:</PdfLabel>
              <PdfText>{format(new Date(data.patientBornDate), 'dd/MM/yyyy')}</PdfText>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <PdfLabel>Idade:</PdfLabel>
              <PdfText>{`${Math.floor(
                (Date.now() - new Date(data.patientBornDate).getTime()) / 31557600000,
              )} anos`}</PdfText>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <PdfLabel>Sexo:</PdfLabel>
              <PdfText>{`${getField('patientSex', data) === 'F' ? 'Feminino' : 'Masculino'}`}</PdfText>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={1}>
              <PdfLabel>Peso:</PdfLabel>
              <PdfText>{`${getField('patientWeight', data)} kg`}</PdfText>
            </Col>
          </Row>

        </div>
        <div id="pdf-body">
          <Row>
            <Col className='d-flex flex-dir-col space-between' md={4}>
              <PdfLabel>Nome da Mãe:</PdfLabel>
              <PdfText>{getField('patientMar', data)}</PdfText>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <PdfLabel>CPF:</PdfLabel>
              <PdfText>{maskCpfOrCnpj(getField('patientCpf', data))}</PdfText>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <PdfLabel>RG:</PdfLabel>
              <PdfText>{maskRg(getField('patientRg', data))}</PdfText>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <PdfLabel>Outros docs:</PdfLabel>
              <PdfText />
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <PdfLabel>Telefone:</PdfLabel>
              <PdfText />
            </Col>
          </Row>
          <Row>
            <Col className='d-flex flex-dir-col space-between' md={3}>
              <PdfLabel>Convênio:</PdfLabel>
              <PdfText>{getField('healthInsuranceName', data)}</PdfText>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={5}>
              <PdfLabel>Matrícula Convênio:</PdfLabel>
              <PdfText>{getField('healthInsuranceWallet', data)}</PdfText>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={3}>
              <PdfLabel>
                Nª GIH/AIH: <span />
              </PdfLabel>
              <PdfText>{getField('teste', data)}</PdfText>
            </Col>
          </Row>
          <Row>
            <Col className='d-flex flex-dir-col space-between' md={6}>
              <PdfLabel>
                Clínica: <span />
              </PdfLabel>
              <PdfText>{getField('teste', data)}</PdfText>

            </Col>
            <Col className='d-flex flex-dir-col space-between' md={3}>
              <PdfLabel>
                Enfermaria/APT°:
                <span />
              </PdfLabel>
              <PdfText>{getField('teste', data)}</PdfText>

            </Col>
            <Col className='d-flex flex-dir-col space-between' md={3}>
              <PdfLabel>
                Leito:
                <span />
              </PdfLabel>
              <PdfText>{getField('bedName', data)}</PdfText>
            </Col>
          </Row>

          <Row>
            <Col className='d-flex flex-dir-col space-between'>
              <PdfLabel>Diagnóstico:</PdfLabel>
              <p style={{ fontSize: 14, marginBottom: 4 }}>{`${getField(
                'attendanceCodeCid',
                data,
              )} - ${getField('attendanceCid', data)}`}</p>
            </Col>
          </Row>
          <Row>
            <Col className='d-flex flex-dir-col space-between'>
              <PdfLabel>Indicação da Transfusão:</PdfLabel>
              <PdfText>{getField('useIndicationDescription', data).split(',').join(', ')}</PdfText>
            </Col>
          </Row>
          <Row>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <PdfLabel>
                Hb: <span>{`${formatNumber(getField('resultHbDescription', data))} g/DL`}</span>
              </PdfLabel>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <PdfLabel>
                Ht: <span>{`${formatNumber(getField('resultHtDescription', data))} %`}</span>
              </PdfLabel>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <PdfLabel>
                Plaq: <span>{`${formatNumber(getField('resultPlaqDescription', data))}/mm³`}</span>
              </PdfLabel>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <PdfLabel>
                TAP/INR: <span>{getField('resultTapDescription', data)}</span>
              </PdfLabel>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <PdfLabel>
                FIBR: <span>{getField('resultFibrDescription', data)}</span>
              </PdfLabel>
            </Col>
          </Row>
          <Row>
            <Col className='d-flex flex-dir-col space-between' md={3}>
              <PdfLabel>Modalidade Transfusão:</PdfLabel>
              <PdfText>{getField('modalityTransfName', data)}</PdfText>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <PdfLabel>Data:</PdfLabel>
              <PdfText>{getDateModalityTransf(data, getField)}</PdfText>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={3}>
              <PdfLabel>Hist. de Transfusão Prévia:</PdfLabel>
              <PdfText>{getField('previousTransfusionDescription', data)}</PdfText>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={3}>
              <PdfLabel>Há quanto tempo:</PdfLabel>
              <PdfText>{getField('monthsLastTrafusion', data)}</PdfText>
            </Col>
          </Row>
          <Row>
            <Col className='d-flex flex-dir-col space-between' md={4}>
              <PdfLabel>Reação Transfusional Prévia:</PdfLabel>
              <PdfText>{getField('previousTransfusionReactionsDescription', data)}</PdfText>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={3}>
              <PdfLabel>Tipo:</PdfLabel>
              <PdfText>{getField('previousTransfusionReactions', data)}</PdfText>
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={3}>
              <PdfLabel>História Gestacional:</PdfLabel>
              <PdfText>{getField('gestationalHistory', data)} </PdfText>
            </Col>
          </Row>
          <hr />
          <SubTitle>SOLICITAÇÃO</SubTitle>
          <Row>
            <Col className='d-flex flex-dir-col space-between' md={12}>
              <PdfLabel>Diagnóstico</PdfLabel>
              <div className='diagnostic-field'>
                <div className='input-field'>{data.attendanceCodeCid} - {data.attendanceCid}</div>
                <div className='icon'>
                  <span><DiagnosticIcon /></span>
                </div>
              </div>
            </Col>
          </Row>
        </div>


        <Row style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Col style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            {data?.items[0] ? (
              <Row className="requested-item" key={data?.items[0][0].id} style={{ border: "1px solid #E5E5E5", width: '100%' }}>
                <Col style={{ padding: "20px !important" }}  >
                  <Row style={{ padding: "15px 8px" }}>
                    <Row>
                      <Col>
                        <div style={{ fontSize: "13px" }}>COMPONENTE SOLICITADO</div>
                        <SubTitle>{data?.items[0][0]?.bloodComponentName}</SubTitle>
                      </Col>
                    </Row>
                    <Col className='d-flex flex-dir-col space-between' md={6} style={{ marginTop: "12px" }}>
                      <Row>
                        <Col>
                          <div style={{ display: "flex" }}>
                            <PdfLabel style={{ marginRight: "6px" }}>Qtd.*:</PdfLabel>
                            <PdfText>{getField('amountDoseDrug', data?.items[0][0])}</PdfText>
                          </div>
                        </Col>
                        <Col>
                          <div style={{ display: "flex" }}>
                            <PdfLabel style={{ marginRight: "6px" }}>Unid. Med.:</PdfLabel>
                            <PdfText>{getField('codeUnityUsual', data?.items[0][0])}</PdfText>
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <PdfLabel style={{ fontWeight: '500 !important' }}>Procedimentos Especiais:</PdfLabel>
                        <div style={{ display: "flex" }}>
                          {getField('specialProced', data?.items[0][0]).split(',').map((proced) => (
                            <PdfText key={proced} style={{ fontWeight: 'bold !important' }}>{proced}</PdfText>
                          ))}
                        </div>
                      </div>
                      <div>
                        <PdfLabel>Justificativa:</PdfLabel>
                        <PdfText>{getField('specialProcedJustDescription', data?.items[0][0])}</PdfText>
                      </div>
                      <div>
                        <PdfLabel>Informações adicionais:</PdfLabel>
                        <PdfText>{getField('drugInformation', data?.items[0][0])}</PdfText>
                      </div>
                    </Col>
                    <Col className='d-flex flex-dir-col space-between' md={6}>
                      <div>
                        <PdfLabel>Indicação da transfusão:</PdfLabel>
                        <PdfText>{getField('useIndicationDescription', data?.items[0][0]).split(',').join(', ')}</PdfText>
                      </div>
                      <div>
                        <PdfLabel>Contraindicações:</PdfLabel>
                        <PdfText>{getField('useContraIndicationDescription', data?.items[0][0]).split(',').join('. ')}</PdfText>
                      </div>
                      <div>
                        <PdfLabel>Justificativa da contraindicação:</PdfLabel>
                        <PdfText>{getField('justContraIndicationDescription', data?.items[0][0])}</PdfText>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <div />
            )}
          </Col>
        </Row>


      </PdfBorder >
    </PdfWrapper >

    {data.items.filter((_, index) => index !== 0).map((items, index) => (
      <PdfWrapper className='page' key={items[0].id} ref={ref}>
        <PdfBorder>
          <div id="pdf-header">
            <div className='title-container'>
              <PdfTitle>REQUISIÇÃO DE TRANSFUSÃO DE SANGUE</PdfTitle>

              <div className='page-number'>
                <span>p.{index + 1}</span>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 6,
                marginBottom: 6,
              }}
            >
              <h3 style={{ fontSize: 14 }}>
                Hospital:&nbsp;
                <span style={{ fontWeight: 'normal', fontSize: 16 }}>{getField('unityName', data)}</span>
              </h3>
              <span style={{ fontWeight: 'normal', fontSize: 16 }}>
                Nº Transfusão: #{getField('id', data)}
              </span>
            </div>
            <hr style={{ marginBottom: 8 }} />
            <SubHeader style={{ marginBottom: 6 }}>
              <SubTitle>DADOS DO PACIENTE</SubTitle>
              <SubTitle>N° Atend.: {getField('attendanceCode', data)}</SubTitle>
            </SubHeader>
            <Row>
              <Col className='d-flex flex-dir-col space-between' md={3}>
                <PdfLabel>Nome do Paciente:</PdfLabel>
                <PdfText style={{ fontWeight: "bold", textTransform: "uppercase" }}>{getField('patientName', data)}</PdfText>
              </Col>
              <Col className='d-flex flex-dir-col space-between' md={2}>
                <PdfLabel>PF:</PdfLabel>
                <PdfText>{getField('pf', data)}</PdfText>
              </Col>
              <Col className='d-flex flex-dir-col space-between' md={2}>
                <PdfLabel>Data de Nasc.:</PdfLabel>
                <PdfText>{format(new Date(data.patientBornDate), 'dd/MM/yyyy')}</PdfText>
              </Col>
              <Col className='d-flex flex-dir-col space-between' md={2}>
                <PdfLabel>Idade:</PdfLabel>
                <PdfText>{`${Math.floor(
                  (Date.now() - new Date(data.patientBornDate).getTime()) / 31557600000,
                )} anos`}</PdfText>
              </Col>
              <Col className='d-flex flex-dir-col space-between' md={2}>
                <PdfLabel>Sexo:</PdfLabel>
                <PdfText>{`${getField('patientSex', data) === 'F' ? 'Feminino' : 'Masculino'}`}</PdfText>
              </Col>
              <Col className='d-flex flex-dir-col space-between' md={1}>
                <PdfLabel>Peso:</PdfLabel>
                <PdfText>{`${getField('patientWeight', data)} kg`}</PdfText>
              </Col>
            </Row>
          </div>

          <Row style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
            <Col style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
              {items.map((hemocomponent) => (
                <Row className="requested-item" key={hemocomponent.bloodComponentName} style={{ border: "1px solid #E5E5E5", marginBottom: "20px", width: '100%' }}>
                  <Col style={{ padding: "20px !important" }}  >
                    <Row style={{ padding: "15px 8px" }}>
                      <Row>
                        <Col>
                          <div style={{ fontSize: "13px" }}>COMPONENTE SOLICITADO</div>
                          <SubTitle>{hemocomponent.bloodComponentName}</SubTitle>
                        </Col>
                      </Row>
                      <Col className='d-flex flex-dir-col space-between' md={6} style={{ marginTop: "12px" }}>
                        <Row>
                          <Col>
                            <div style={{ display: "flex" }}>
                              <PdfLabel style={{ marginRight: "6px" }}>Qtd.*:</PdfLabel>
                              <PdfText>{getField('amountDoseDrug', data?.items[0][0])}</PdfText>
                            </div>
                          </Col>
                          <Col>
                            <div style={{ display: "flex" }}>
                              <PdfLabel style={{ marginRight: "6px" }}>Unid. Med.:</PdfLabel>
                              <PdfText>{getField('codeUnityUsual', data?.items[0][0])}</PdfText>
                            </div>
                          </Col>
                        </Row>
                        <div>
                          <PdfLabel style={{ fontWeight: '500 !important' }}>Procedimentos Especiais:</PdfLabel>
                          <div style={{ display: "flex" }}>
                            {getField('specialProced', hemocomponent).split(',').map((proced) => (
                              <PdfText key={proced} style={{ fontWeight: 'bold !important' }}>{proced}</PdfText>
                            ))}
                          </div>
                        </div>
                        <div>
                          <PdfLabel>Justificativa:</PdfLabel>
                          <PdfText>{getField('specialProcedJustDescription', hemocomponent)}</PdfText>
                        </div>
                        <div>
                          <PdfLabel>Informações adicionais:</PdfLabel>
                          <PdfText>{getField('drugInformation', hemocomponent)}</PdfText>
                        </div>
                      </Col>
                      <Col className='d-flex flex-dir-col space-between' md={6}>
                        <div>
                          <PdfLabel>Indicação da transfusão:</PdfLabel>
                          <PdfText>{getField('useIndicationDescription', hemocomponent).split(',').join(', ')}</PdfText>
                        </div>
                        <div>
                          <PdfLabel>Contraindicações:</PdfLabel>
                          <PdfText>{getField('useContraIndicationDescription', hemocomponent).split(',').join('. ')}</PdfText>
                        </div>
                        <div>
                          <PdfLabel>Justificativa da contraindicação:</PdfLabel>
                          <PdfText>{getField('justContraIndicationDescription', hemocomponent)}</PdfText>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>


          {data.items.length - 2 === index ? (
            <>
              <PdfTable style={{ width: '100%', marginTop: "auto" }}>
                <thead>
                  <tr>
                    <th>Etiqueta Amostra</th>
                    <th>Data</th>
                    <th>Colhida</th>
                    <th>Responsável pela coleta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='mb-10'>
                    <td
                      style={{
                        paddingLeft: '20px',
                        background: "#E5E5E5"
                      }}
                    />
                    <td
                      style={{
                        paddingLeft: '20px',
                        background: "#E5E5E5"
                      }}
                    />
                    <td
                      style={{
                        paddingLeft: '20px',
                        background: "#E5E5E5"
                      }}
                    />
                    <td
                      style={{
                        paddingLeft: '20px',
                        background: "#E5E5E5"
                      }}
                    />
                  </tr>
                </tbody>
              </PdfTable>

              <Row id="pdf-bottom" style={{ bottom: 20 }}>
                <Row style={{ marginTop: 40, justifyContent: 'space-between' }}>
                  <Col className='d-flex' style={{ alignItems: 'flex-end' }} md={6}>
                    <hr style={{ width: '100%' }} />
                  </Col>
                  <Col md={2}>
                    <p style={{ fontSize: 12, marginBottom: 4, fontWeight: 'bold' }}>
                      Data:&nbsp;
                    </p>
                  </Col>
                  <Col md={2}>
                    <p style={{ fontSize: 12, marginBottom: 4, fontWeight: 'bold' }}>
                      Hora:&nbsp;
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <p style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0, fontSize: 12 }}>
                      {getField('doctorName', data)}
                    </p>
                    <p
                      style={{
                        fontSize: 12,
                        marginBottom: 4,
                        marginTop: 0,
                        textAlign: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      CRM:&nbsp;{getField('doctorCrmNumber', data)}
                    </p>
                  </Col>
                </Row>
              </Row>
            </>
          ) : (
            <div />
          )}
        </PdfBorder >
      </PdfWrapper >
    ))}

  </>
))
