import { FC } from 'react'
import { Row } from 'react-bootstrap'
import { SubBlockStyles, SubBlockTitle } from './styles'
import { SubBlockTypes } from './types'

export const SubBlock: FC<SubBlockTypes> = ({ title, children, className = '', subtitle }) => (
  <SubBlockStyles className={className}>
    <Row className='d-flex justify-content-between '>
      <div className='w-auto '>
        {title ? <SubBlockTitle>{title}</SubBlockTitle> : null}
      </div>
      <div className='w-auto'>
        {subtitle ? <SubBlockTitle>{subtitle}</SubBlockTitle> : null}
      </div>
    </Row>

    {children}
  </SubBlockStyles>
)
