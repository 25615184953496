import { FC } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { DocTextArea } from '../form/styles'
import { SubHeader, SubTitle, SubTitleSmall } from '../page/styles'
import { CancelTransfusionalModalStyles } from './styles'
import { CancelTransfusionalModalPropTypes } from './types'

export const CancelTransfusionalModal: FC<CancelTransfusionalModalPropTypes> = ({
  handleClose,
  show,
  confirmCancel,
  setJustification,
  justification,
}) => (
  <CancelTransfusionalModalStyles show={show} onHide={handleClose}>
    <Modal.Body>
      <SubHeader>
        <SubTitle style={{ color: '#0064A9', fontSize: 13 }}>
          Informe a justificativa para cancelamento da requisição de transfusão de sangue:
        </SubTitle>
      </SubHeader>
      <SubTitleSmall>Justificativa:</SubTitleSmall>
      <DocTextArea
        className='hpx-60'
        onChange={({ target }) => setJustification(target.value)}
        maxLength={255}
      />
    </Modal.Body>
    <Modal.Footer>
      <Button size='sm' variant='outline-secondary' onClick={handleClose}>
        Fechar
      </Button>
      <Button
        size='sm'
        variant='primary'
        disabled={justification.length < 15}
        onClick={() => confirmCancel(justification)}
      >
        Enviar justificativa
      </Button>
    </Modal.Footer>
  </CancelTransfusionalModalStyles>
)
