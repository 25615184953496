/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-useless-fragment */
import { format } from 'date-fns'
import { FC } from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import { Info, Label, StyledTooltip } from './styles'
import { CustomTooltipProps } from './types'

export const CustomTooltipRt: FC<CustomTooltipProps> = ({ statusRequest, children, type }) => {
    const handleType = () => {

        if (type === 'tip') {
            return 'tipado'
        }

        if (type === 'trs') {
            return 'transfusão finalizada'
        }

        if (type === 'aut') {
            return 'autorizado'
        }

        if (type === 'be') {
            return 'bolsa enviada'
        }

        if (type === 'br') {
            return 'bolsa recebida'
        }

        return ''
    }

    const isTyped = statusRequest.find((obj) => obj.statusDescription.toLowerCase() === 'tipado')
    const isTrs = statusRequest.filter((obj) => obj.columnType.toLowerCase() === 'trs').pop()
    const isAut = statusRequest.filter((obj) => obj.columnType.toLowerCase() === 'aut').pop()

    const handleSourceObject = () => {
        let object: any;
        let validate = false;
        if (isAut !== undefined) {
            if (isAut.statusDescription === 'Parcialmente Autorizado') {
                validate = true;
                object = isAut
            }
        }

        if (!validate) {
            object = statusRequest.find((obj) =>
                obj.statusDescription.toLowerCase() === handleType()
            )
        }
        if (!object) {
            return null
        }

        if (type === 'aut') {
            return { ...object, time: object?.timeForAuthorization }
        }

        if (type === 'be') {
            return { ...object, time: object?.timeForBagOutput }
        }

        if (type === 'br') {
            return { ...object, time: object?.timeForBagReceved }
        }

        if (type === 'tip') {
            return { ...object, time: object?.timeForTipyng }
        }

        if (type === 'trs') {
            return { ...object, time: object?.timeForTransfusion }
        }

        return null
    }
    const autpOverlayContent = () => (
        <Info>
            <Label>Status</Label>
            <h6>{isAut?.statusDescription || '---'}</h6>
        </Info>
    )


    if (isTrs) {
        return (
            <span id='content'>
                <OverlayTrigger
                    placement='bottom-start'
                    overlay={
                        <StyledTooltip id='tooltip-id' >
                            <Info>
                                <Label>{`Data/Hora ${handleType()}`}</Label>
                                <h6>
                                    {format(new Date(handleSourceObject()?.registrationDate), 'dd/MM/yyyy - HH:mm')}
                                </h6>
                            </Info>
                            <Info>
                                <Label>{`Tempo p/ ${handleType()}`}</Label>
                                <h6>{handleSourceObject()?.time}</h6>
                            </Info>
                            {type === 'trs' ? autpOverlayContent() : null}
                        </StyledTooltip>
                    }
                >
                  {children}
                </OverlayTrigger>
      </span>
        )
    }
    if (isAut) {
        return (
            <span id='content'>
        <OverlayTrigger
            placement='bottom-start'
            overlay={
                handleSourceObject() ? (
                    <StyledTooltip id='tooltip-id' >
                        <Info>
                            <Label>{`Data/Hora ${handleType()}`}</Label>
                            <h6>
                                {format(new Date(handleSourceObject()?.registrationDate), 'dd/MM/yyyy - HH:mm')}
                            </h6>
                        </Info>
                        <Info>
                            <Label>{`Tempo p/ ${handleType()}`}</Label>
                            <h6>{handleSourceObject()?.time}</h6>
                        </Info>
                        {type === 'aut' ? autpOverlayContent() : null}
                    </StyledTooltip>
                ) : (
                    <div />
                )
            }
        >
          {children}
        </OverlayTrigger>
      </span>
        )
    }

    if (!isTyped) {
        return <>{children}</>
    }

    return (
        <span id='content'>
      <OverlayTrigger
          placement='bottom-start'
          overlay={
              handleSourceObject() ? (
                  <StyledTooltip id='tooltip-id' style={{ display: !isTyped && 'none' }}>
                      <Info>
                          <Label>{`Data/Hora ${handleType()}`}</Label>
                          <h6>
                              {format(new Date(handleSourceObject()?.registrationDate), 'dd/MM/yyyy - HH:mm')}
                          </h6>
                      </Info>
                      <Info>
                          <Label>{`Tempo p/ ${handleType()}`}</Label>
                          <h6>{handleSourceObject()?.time}</h6>
                      </Info>
                      {type === 'aut' ? autpOverlayContent() : null}
                  </StyledTooltip>
              ) : (
                  <div />
              )
          }
      >
        {children}
      </OverlayTrigger>
    </span>
    )
}
