import {FC, useEffect, useState} from 'react'
import { Dropdown } from 'react-bootstrap'
import {toast} from "react-toastify"
import { ReactComponent as ElipsesIcon } from '../../assets/icons/elipses.svg'
import { useAuthContext } from '../../context/auth'
import { validateProfile } from '../../utils'
import { DropdownStyles } from './styles'
import { ActionDropdownProps } from './types'
import { ACTIONS } from './utils'

export const ActionsDropdown: FC<ActionDropdownProps> = ({
  onSelect,
  disabled,
  statusRequestDto,
}) => {
    const [isDisabled, setIsDisabled] = useState(false)

  const { profile } = useAuthContext()

  const isRtTyped = statusRequestDto.filter(
    (item) => item.statusDescription.toLowerCase() === 'tipado',
  )
  const isRtNegade = statusRequestDto.filter(
      (item) => item.statusDescription === 'Negado',
  )
  const isRtCancel = statusRequestDto.filter(
      (item) => item.statusDescription === 'Cancelado',
  )
    useEffect(() => {
        if (isRtNegade.length > 0 ||isRtCancel.length > 0) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [statusRequestDto])
  const handleLabel = (label: string) => {
      let newLabel = label;
      if (isRtTyped.length > 0 && label === 'Gerar Etiqueta para Tipagem') {
          newLabel = 'Reimprimir Etiqueta de Tipagem'
      }

    return newLabel
  }
    const renderItems = () =>
        ACTIONS.map(({ value, label, on, profiles }) =>
            ((!on || isDisabled) && (
                label === 'Gerar Etiqueta para Tipagem' ||
                label === 'Gerar Saída de Hemocomponente' ||
                label === 'Gerar Recebimento de Hemocomponente'
            )) ? null :
                validateProfile({
                    profiles,
                    description: profile || '',
                    btn: (
                        <Dropdown.Item
                            onClick={(e: any) => {
                                e.preventDefault();
                                onSelect(value);
                            }}
                            key={value}
                            href={value}
                            disabled={!on}
                        >
                            {handleLabel(label)}
                        </Dropdown.Item>
                    ),
                })
        );


  return (
    <DropdownStyles onSelect={onSelect}>
      <Dropdown.Toggle disabled={disabled}>
        <ElipsesIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu>{renderItems()}</Dropdown.Menu>
    </DropdownStyles>
  )
}
