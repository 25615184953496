import { Accordion } from 'react-bootstrap'
import styled from 'styled-components'

export const NavListWrapperStyles = styled.nav`
  margin-top: 50px;
`
export const NavListStyles = styled.ul`
  padding-left: 0px;
`

export const NavItemStyles = styled.li`
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 16px;
  cursor: pointer;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  span {
    color: #c0bfbd;
  }
  svg {
    margin-right: 4px;
  }
`

export const AccordionStyledNavItem = styled(Accordion)`
  margin-bottom: 16px;
  button {
    background-color: transparent !important;
    box-shadow: none;
    font-size: 12px;
    color: #c0bfbd !important;
    padding: 0px;
    padding-top: 4px;
    padding-bottom: 4px;
    &:focus {
      border-color: transparent;
    }
    svg {
      margin-right: 4px;
    }
  }

  & > div {
    border: none !important;
    background-color: transparent;
  }

  .accordion-body {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 0px;
    li:first-child {
      padding-top: 16px;
    }
    li:last-child {
      margin-bottom: 0px;
    }
  }
`
