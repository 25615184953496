import { FC } from 'react'
import { BlockStyles, BlockTitle } from './styles'
import { BlockTypes } from './types'

export const Block: FC<BlockTypes> = ({ title, children, titleComponent, className = '', titleColor }) => (
  <BlockStyles className={className}>
    {!titleComponent && title ? <BlockTitle color={titleColor}>{title}</BlockTitle> : null}
    {titleComponent || null}
    {children}
  </BlockStyles>
)
