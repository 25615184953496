import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReactComponent as NonInitiatedIcon } from '../../assets/icons/nao-iniciado.svg'
import { ReactComponent as RecusedIcon } from '../../assets/icons/recusado.svg'
import { ReactComponent as OkIcon } from '../../assets/icons/validado.svg'
import { Block } from '../../components'
import { PageBadge, PageSection, SubHeader, SubTitle } from '../../components/page/styles'
import ReceiveBloodComponentModal from '../../components/receiveBloodComponentModal'
import { SubtilesBar, SubtitleLabel } from '../../components/subtitlesBar/styles'
import { TableStyles } from '../../components/tables/styles'
import { PatientDetailsSection } from '../../components/transfusionRequest/PatientDetails'
import { getReceivedBloodComponents, getTransfutionRequest } from '../../service'

const headers = [
  'Hemocomponente',
  'N° da Bolsa',
  'ABO/Rh',
  'Temperatura',
  'Qtd',
  'UM',
  'Validade',
  'Responsável Recebimento',
  'Data Recebimento',
  'Status',
]

export const BloodComponenteReceiptPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [rTdata, setRtData] = useState(null)
  const [show, setShow] = useState(false)
  const [bloodComponents, setBloodComponents] = useState([])
  const [isValidated, setIsValidated] = useState(false)
  const [validateValue, setValidateValue] = useState(false);
  const doGetTransfutionRequest = async () => {
    try {
      setLoading(true)

      const { data } = await getTransfutionRequest(id);
      setRtData({
        ...(data?.content?.transfusionRequest || null),
        unityName: data?.content?.unityName,
      })
    } catch ({ request, response }) {
      toast.error('Algo deu errado na')
    } finally {
      setLoading(false)
    }
  }

  const doGetReceivedBloodComponents = async () => {
    try {
      setLoading(true)
      const { data } = await getReceivedBloodComponents(id)
      if (data) {
        setValidateValue(true);
      }

      const { content } = data;

      const totalHemocomp = data.content.filter((res) => res.status === true);

      if (totalHemocomp.length === data.content.length) {
        setIsValidated(true);
      }

      if (typeof content !== 'string') {
        setBloodComponents(content)
      }
    } finally {
      setLoading(false)
    }
  }

  const renderStatus = (item) => {

    const { status, responsable, dateReceved } = item

    if (status && Boolean(responsable && dateReceved)) {
      return <OkIcon />
    }
    if (!status && Boolean(responsable && dateReceved)) {
      return <RecusedIcon />
    }
    return <NonInitiatedIcon />
  }

  useEffect(() => {
    if (!show && id) {
      doGetReceivedBloodComponents()
    }
  }, [show, id, rTdata])

  useEffect(() => {
    if (validateValue) {
      doGetTransfutionRequest()
    }
  }, [])

  return (
    <>
      <Block title='PROTOCOLO DE RECEBIMENTO DE HEMOCOMPONENTES' titleColor='#0064A9'>
        <SubHeader style={{ justifyContent: 'flex-end' }}>
          <PageBadge>Nº RT: #{rTdata?.id || ''}</PageBadge>
        </SubHeader>
        {rTdata ? <PatientDetailsSection data={rTdata} resumed /> : null}
        <PageSection>
          <SubHeader>
            <SubTitle>Recebimento de Hemocomponentes</SubTitle>
          </SubHeader>
          <SubtilesBar>
            <SubtitleLabel>Legenda:</SubtitleLabel>
            <SubtitleLabel className='done'>Recebido</SubtitleLabel>
            <SubtitleLabel className='danger'>Recusado</SubtitleLabel>
            <SubtitleLabel className='stand-by'>Não Recebido</SubtitleLabel>
          </SubtilesBar>
          <TableStyles style={{ width: '100%' }}>
            <thead>
              <tr>
                {headers.map((item) => (
                  <th key={item}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {bloodComponents.map((item) => (
                <tr key={item.bloodComponentName}>
                  <td>{item.bloodComponentName}</td>
                  <td>{item.bagNumber}</td>
                  <td>{item.aboRh}</td>
                  <td style={{ textAlign: 'center' }}>{`${item.temperature}°C`}</td>
                  <td style={{ textAlign: 'center' }}>{item.quantity}</td>
                  <td>{item.unitMeasurement}</td>
                  <td style={{ textAlign: 'center' }}>{item.expirationDate ? format(new Date(item.expirationDate), 'dd/MM/yyyy HH:mm:ss') : ''}</td>
                  <td>{item.responsable}</td>
                  {item.dateReceved ? (
                    <td style={{ textAlign: 'center' }}>{format(new Date(item.dateReceved), 'dd/MM/yyyy HH:mm:ss')}</td>
                  ) : (
                    <td />
                  )}
                  <td className='text-center'>{renderStatus(item)}</td>
                </tr>
              ))}
            </tbody>
          </TableStyles>
        </PageSection>
        <Row className='text-end'>
          <Col md={{ span: 1, offset: 8 }}>
            <Button
              type='button'
              className='w-100'
              variant='outline-secondary'
              size='sm'
              onClick={() => navigate('/')}
            >
              Voltar
            </Button>
          </Col>
      {!isValidated? (
          <Col md={3}>
          <Button
            type='button'
            className='w-100'
            variant='success'
            size='sm'
            onClick={() => setShow(true)}
            disabled={loading}
           >
            Receber Hemocomponentes
          </Button>
          </Col>
      ) : null}

        </Row>
      </Block>
      <ReceiveBloodComponentModal
        show={show}
        list={bloodComponents}
        handleClose={() => setShow(false)}
        handleConfirm={() => setShow(false)}
      />
    </>
  )
}
