/* eslint-disable react/jsx-props-no-spreading */
import { format } from 'date-fns'
import { FC, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { formatNumber, getDateModalityTransf } from '../../utils'
import { maskCpfOrCnpj, maskRg } from '../../utils/mask'
import { DocInput, DocLabel } from '../form/styles'
import { PageSection, SubHeader, SubTitle } from '../page/styles'
import { PatientDetailsSectionEndRequestPropTypes } from './types'
import { RtTypeFieldModal } from '../rtTypeFieldModal'

export const PatientDetailsSection: FC<PatientDetailsSectionEndRequestPropTypes> = ({
  data,
  resumed,
}) => {

  const getField = (name) => data[name] || ''
  const getGender = () => (getField('patientSex') === 'F' ? 'Feminino' : 'Masculino')
  const [showModal, setShowModal] = useState(false)

  function openModal(e) {
    e.preventDefault()
    e.stopPropagation()

    setShowModal(true)
  }




  return (
    <PageSection>
      {Object?.keys(data).length ? (
        <>
          <SubHeader>
            <SubTitle>DADOS DO PACIENTE</SubTitle>
            <SubTitle>N° Atend.: {data.attendanceCode}</SubTitle>
          </SubHeader>
          <Row className='mb-8'>
            <Col className='d-flex flex-dir-col space-between' md={3}>
              <DocLabel>Nome do Paciente:</DocLabel>
              <DocInput
                className='bold-text'
                disabled
                type='text'
                value={getField('patientName')}
              />
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={3}>
              <DocLabel>Nome da Mãe:</DocLabel>
              <DocInput disabled type='text' value={getField('patientMar')} />
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={1}>
              <DocLabel>Data de Nasc.:</DocLabel>
              <DocInput
                disabled
                type='text'
                value={format(new Date(data.patientBornDate), 'dd/MM/yyyy')}
              />
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={1}>
              <DocLabel>Idade:</DocLabel>
              <DocInput
                disabled
                type='text'
                value={`${Math.floor(
                  (Date.now() - new Date(data.patientBornDate).getTime()) / 31557600000,
                )} anos`}
              />
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <DocLabel>CPF:</DocLabel>
              <DocInput disabled type='text' value={maskCpfOrCnpj(getField('patientCpf'))} />
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <DocLabel>RG:</DocLabel>
              <DocInput disabled type='text' value={maskRg(getField('patientRg'))} />
            </Col>
          </Row>
          <Row className='mb-8'>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <DocLabel>Sexo:</DocLabel>
              <DocInput disabled type='text' value={getGender()} />
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={1}>
              <DocLabel>Peso:</DocLabel>
              <DocInput
                value={`${getField('patientWeight')} kg`}
                disabled={!getField('patientWeight').lenght}
              />
            </Col>
          </Row>
          {resumed ? (
            <Row>
              <Col className='d-flex flex-dir-col space-between' md={3}>
                <DocLabel>Unidade:</DocLabel>
                <DocInput disabled type='text' value={`${getField('unityName')}`} />
              </Col>
              <Col className='d-flex flex-dir-col space-between' md={2}>
                <DocLabel>Clínica:</DocLabel>
                <DocInput disabled type='text' value='' />
              </Col>
              <Col className='d-flex flex-dir-col space-between' md={2}>
                <DocLabel>Enfermaria/APT°:</DocLabel>
                <DocInput disabled type='text' value='' />
              </Col>
              <Col className='d-flex flex-dir-col space-between' md={1}>
                <DocLabel>Leito:</DocLabel>
                <DocInput disabled type='text' value={getField('bedName')} />
              </Col>

              <Col className='d-flex flex-dir-col space-between' md={1}>
                <DocLabel>ABO/Rh:</DocLabel>
                <DocInput disabled type='text' value={getField('aboRh')} />
              </Col>
            </Row>
          ) : (
            <>
              <Row className='mb-8'>
                <Col className='d-flex flex-dir-col space-between' md={2}>
                  <DocLabel>Telefone:</DocLabel>
                  <DocInput disabled type='text' value={getField('patientTelephone')} />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={1}>
                  <DocLabel>Convênio:</DocLabel>
                  <DocInput disabled type='text' value={getField('healthInsuranceName')} />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={2}>
                  <DocLabel>Matrícula Convênio:</DocLabel>
                  <DocInput disabled type='text' value={getField('healthInsuranceWallet')} />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={1}>
                  <DocLabel>Nª GIH/AIH:</DocLabel>
                  <DocInput disabled type='text' value='' />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={2}>
                  <DocLabel>Clínica:</DocLabel>
                  <DocInput disabled type='text' value='' />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={2}>
                  <DocLabel>Enfermaria/APT°:</DocLabel>
                  <DocInput disabled type='text' value='' />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={1}>
                  <DocLabel>Leito:</DocLabel>
                  <DocInput disabled type='text' value={getField('bedName')} />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={1}>
                  <DocLabel>ABO/Rh:</DocLabel>
                  <DocInput disabled type='text' value={getField('aboRh')} />
                </Col>
              </Row>

              <hr />
              <Row className='mb-8'>
                <Col className='d-flex flex-dir-col space-between' md={1}>
                  <DocLabel>Hb:</DocLabel>
                  <DocInput
                    disabled
                    type='text'
                    value={`${formatNumber(getField('resultHbDescription'))} g/DL`}
                  />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={1}>
                  <DocLabel>Ht:</DocLabel>
                  <DocInput
                    disabled
                    type='text'
                    value={`${formatNumber(getField('resultHtDescription'))} %`}
                  />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={2}>
                  <DocLabel>Plaq:</DocLabel>
                  <DocInput
                    disabled
                    type='text'
                    value={`${formatNumber(getField('resultPlaqDescription'))}/mm³`}
                  />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={2}>
                  <DocLabel>TAP/INR:</DocLabel>
                  <DocInput disabled type='text' value={getField('resultTapDescription')} />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={2}>
                  <DocLabel>FIBR:</DocLabel>
                  <DocInput disabled type='text' value={getField('resultFibrDescription')} />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={2}>
                  <DocLabel>Modalidade Transfusão:</DocLabel>
                  <DocInput disabled type='text' value={getField('modalityTransfName')} />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={2}>
                  <DocLabel>Data:</DocLabel>
                  <DocInput type='text' disabled value={getDateModalityTransf(data, getField)} />
                </Col>
              </Row>
              <Row className='mb-8'>
                <Col className='d-flex flex-dir-col space-between' md={2}>
                  <DocLabel>Hist. de Transfusão Prévia:</DocLabel>
                  <DocInput type='text' disabled value={getField('previousTransfusionDescription')} />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={2}>
                  <DocLabel>Há Quanto Tempo:</DocLabel>
                  <DocInput type='text' disabled value={getField('monthsLastTrafusion')} />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={2}>
                  <DocLabel>Reação Transfusional Prévia:</DocLabel>
                  <DocInput type='text' disabled value={getField('previousTransfusionReactionsDescription')} />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={1} >
                  <DocLabel>Tipo:</DocLabel>
                  <DocInput
                    id="typeInput"
                    style={{ whiteSpace: 'nowrap', textOverflow: "ellipsis", cursor: "pointer", border: "none" }}
                    onClick={(e) => openModal(e)}
                    type='text'
                    readOnly
                    value={getField('previousTransfusionReactions') ? getField('previousTransfusionReactions') : '...'}
                  />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={2}>
                  <DocLabel>História Gestacional:</DocLabel>
                  <DocInput type='text' disabled value={getField('gestationalHistory')} />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={1}>
                  <DocLabel>G:</DocLabel>
                  <DocInput type='text' disabled value={getField('gestationalHistoryG')} />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={1}>
                  <DocLabel>P:</DocLabel>
                  <DocInput type='text' disabled value={getField('gestationalHistoryP')} />
                </Col>
                <Col className='d-flex flex-dir-col space-between' md={1}>
                  <DocLabel>A:</DocLabel>
                  <DocInput type='text' disabled value={getField('gestationalHistoryA')} />
                </Col>
              </Row>
            </>
          )}
        </>
      ) : (
        <SubTitle>Nenhum registro encontrado!</SubTitle>
      )
      }

      <RtTypeFieldModal
        handleClose={() => {
          setShowModal(false)
        }}
        data={{ options: getField('previousTransfusionReactions'), description: "" }}
        show={showModal}
      />
    </PageSection >
  )
}
