import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components'

export const HeaderStyles = styled.div`
  background-color: #0064a9;
  padding: 14px;
  display: flex;
  justify-content: space-between;
`

export const HeaderBlock = styled.div`
  display: flex;
  align-items: center;
`

export const TitleStyles = styled.h3`
  text-transform: uppercase;
  color: white;
  font-size: 14px;
  margin: 0;
  margin-left: 12px;
`
export const UserLabelStyles = styled.h6`
  color: white;
  font-weight: normal;
  font-size: 14px;
  margin-right: 12px;
  margin-bottom: 0px;
`

export const HeaderBtn = styled.button`
  border: none;
  background-color: transparent;
`

export const DropDownToggle = styled(Dropdown.Toggle)`
  border: none;
  background-color: transparent !important;
  padding: 0px;
  box-shadow: none !important;
  span {
    display: inline-block;
    margin-left: 8px;
    color: white;
  }
  &:hover,
  &:active {
    background-color: transparent;
  }
`

export const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const MenuButton = styled.button`
  border: none;
  background-color: transparent;
  margin-right: 8px;
`