import styled from 'styled-components'

export const TableStyles = styled.table`
  margin-top: 10px;
  border: 1px solid #c4c4c4;

  &.tabs-table {
    width: 100%;
  }

  .table-icon-btn {
    background-color: transparent;
    border: none;
  }

  th {
    border: 1px solid #c4c4c4;
    font-size: 11px;
    padding: 4px;
    cursor: pointer;
  }

  tbody {
    tr {
      height: 18px;
    }
    tr:not(:nth-child(2n)) {
      background-color: #e5f0f6;
    }
  }

  td {
    font-size: 11px;
    color: #4f4f4f;
    &.centered {
      text-align: center;
    }
  }

  .status-col {
    text-align: center;
  }

  .request-table-body {
    td {
      padding: 0px 4px;
    }
  }

  &.profiles-table,
  &.tabs-table {
    tbody {
      tr td:last-child {
        cursor: pointer;
      }
      td {
        padding: 0px 4px;
        &:first-child {
          text-align: center;
        }
      }
    }
  }
`