import { format } from 'date-fns'

export const formatNumber = (text: string) => (text ? parseFloat(text).toLocaleString('pt-BR') : '')

export const getDateModalityTransf = (data, callback) =>
  callback('modalityTransfName', data).toLowerCase().includes('programada/reserva') &&
    data?.dateModalityTransf
    ? format(new Date(data.dateModalityTransf), 'dd/MM/yyyy HH:mm')
    : format(new Date(data.registrationDate), 'dd/MM/yyyy HH:mm')

export const getUrlData = (): { token: string; Origin: string, baseAuth: string, OriginType: string } => {
  const values = window.location.search.split('?')

  let urlData: {
    token: string;
    Origin: string,
    OriginType: string,
    baseAuth: string
  } = {
    token: '',
    Origin: '',
    baseAuth: '',
    OriginType: ''
  }

  values.forEach((item) => {
    urlData = {
      ...urlData,
      [item.split('=')[0]]: item.split('=')[1]
    }
  })


  return urlData
}

export const getField = (name, data) => data[name] || ''

interface ValidateProfileTypes {
  profiles: string[] | 'all'
  description: string
  btn: any
}

export function chunkArray(array, chunkSize: number) {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

export const validateProfile = (payload: ValidateProfileTypes) => {
  const { profiles, description, btn } = payload
  const normalized = description
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()

  if (profiles === 'all' || profiles.includes(normalized)) {
    return btn
  }

  return null
}


export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}