import 'bootstrap/dist/css/bootstrap.min.css'
import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { GlobalStyle } from './GlobalStyles'
import { Router } from './Router'
import { AuthContextProvider } from './context/auth/index'
import './polyfill.js'
import reportWebVitals from './reportWebVitals'

function fallbackRender({ error }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  )
}



const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ErrorBoundary
    fallbackRender={fallbackRender}
    onReset={(details) => {
      // Reset the state of your app so the error doesn't happen again
    }}
  >
    <GlobalStyle />
    <ToastContainer />
    <Suspense fallback={<div>Carregando...</div>}>
      <AuthContextProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </AuthContextProvider>
    </Suspense>
  </ErrorBoundary>
  ,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
