/* eslint-disable consistent-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
export const BLOOD_COMPONENT_TABLE_HEADERS = [
  { label: 'Hemocomponente', value: 'statusRequest' },
  { label: 'N° da Bolsa', value: 'typing' },
  { label: 'ABO/Rh', value: 'id' },
  { label: 'Temp.', value: 'attendanceCode' },
  { label: 'Qtd', value: 'patientName' },
  { label: 'UM', value: 'attendanceType' },
  { label: 'Validade', value: 'modalityTransfName' },
  { label: 'Responsável', value: 'unityName' },
  { label: 'Data de Saída', value: 'departureDate' },
  { label: 'Editar', value: 'edit' },
  { label: 'Excluir', value: 'delete' },

]

export const INITIAL_VALUES = {
  databaseId: 0,
  id: '',
  bloodcomponent: '0',
  purseNumber: '',
  abo: '0',
  temperature: '',
  qty: '',
  um: '0',
  validity: '',
  justification: null,
  departureDate: '',
  responsible: '',
  pending: 'false',
  isBloodTypeCompatible: true,
  isBloodComponentAuthorized: true
}

export const WARNS = {
  bloodType: {
    text: "O tipo sanguíneo informado no hemocomponente não é compatível com o tipo sanguíneo do paciente. <br /> <br />  Deseja confirmar a informação do hemocomponente?"
  },
  bloodComponent: {
    text: 'O hemocomponente selecionado está em processo de autorização pela operadora, deseja prosseguir?'
  }
}


export const ABO_TYPES = [
  { value: '1', label: 'A-', warn: false },
  { value: '2', label: 'A+', warn: false },
  { value: '3', label: 'B-', warn: false },
  { value: '4', label: 'B+', warn: false },
  { value: '5', label: 'AB-', warn: false },
  { value: '6', label: 'AB+', warn: false },
  { value: '7', label: 'O-', warn: false },
  { value: '8', label: 'O+', warn: false },

]


export const ABO_TYPES_MATCH = [
  { value: 'AB+', matches: ['*'] },
  { value: 'AB-', matches: ['A-', 'B-', 'AB-', 'O-'] },
  { value: 'A+', matches: ['A+', 'A-', 'O+', 'O-'] },
  { value: 'A-', matches: ['A-', 'O-'] },
  { value: 'B+', matches: ['B+', 'B-', 'O+', 'O-'] },
  { value: 'B-', matches: ['B-', 'O-'] },
  { value: 'O+', matches: ['O+', 'O-'] },
  { value: 'O-', matches: ['O-'] },
]


export const UM_TYPES = [
  { value: '1', label: 'ml' },
  { value: '2', label: 'bl' },
  { value: '3', label: 'ui' },
]


export const formmatNumber = (value: string) => {

  const isNegative = value.includes('-')
  if (isNegative && value.length > 1) {
    const formmated = `-${new Intl.NumberFormat('pt-BR').format(Number(value.replace('-', '').slice(0, 7)) / 100)}`


    return formmated
  }

  const formmated = new Intl.NumberFormat('pt-BR').format(Number(value.replace('-', '').slice(0, 7)) / 100)

  return formmated
}

export const replaceData = (dataString: string) => {


  const data = dataString?.replace(/^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})$/, '$3-$2-$1T$4:$5:00');

  return new Date(data)
}


export const sumAccountsByType = (objects) => {
  const sumsByType = {};

  for (let i = 0; i < objects.length; i += 1) {
    const obj = objects[i];

    if (
      typeof obj === "object" &&
      obj.hasOwnProperty("hemocomponent") &&
      obj.hasOwnProperty("quantity")
    ) {
      const { hemocomponent, quantity, isBloodComponentRefused } = obj;
      let objectQuantity = quantity


      if (isBloodComponentRefused === true) {
        objectQuantity = 0
      }


      const newQuantity = sumsByType[hemocomponent]?.quantity ? Number(sumsByType[hemocomponent].quantity) + Number(objectQuantity) : objectQuantity

      sumsByType[hemocomponent] = {
        hemocomponent,
        quantity: newQuantity,
      };
    }
  }

  const results = [];

  for (const hemocomponent in sumsByType) {
    results.push({
      hemocomponent,
      quantity: sumsByType[hemocomponent].quantity,
      warned: sumsByType[hemocomponent].warned,
    });
  }

  return results;
}


export const areArraysOfObjectsEqual = (array1, array2) => {
  const differentItems = [...array1.filter((item) => item.databaseId === 0)];

  const noNewObjects = array1.filter((item) => item.databaseId !== 0)

  for (let i = 0; i < noNewObjects.length; i += 1) {
    const obj1 = array1[i];
    const obj2 = array2[i];

    if (obj1 && obj2) {
      const obj1Keys = Object.keys(obj1);
      const obj2Keys = Object.keys(obj2);

      if (obj1Keys.length !== obj2Keys.length) {
        differentItems.push(obj1);
      } else {
        let areObjectsEqual = true;

        for (let j = 0; j < obj1Keys.length; j += 1) {
          const key = obj1Keys[j];

          if (obj1[key] !== obj2[key]) {
            areObjectsEqual = false;
            break;
          }
        }

        if (!areObjectsEqual) {
          differentItems.push(obj1);
        }
      }
    }
  }


  return differentItems
}
