import { format } from 'date-fns'
import { FC } from 'react'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import { ReactComponent as CanceledIcon } from '../../assets/icons/cancelado.svg'
import { ReactComponent as OkIcon } from '../../assets/icons/done.svg'
import { ReactComponent as WarningIcon } from '../../assets/icons/late.svg'
import { ReactComponent as SuspendedIcon } from '../../assets/icons/suspended.svg'
import { getField } from '../../utils'
import { DocInput, DocLabel, DocTextArea } from '../form/styles'
import { PageSection, SubHeader, SubTitle, SubTitleSmall } from '../page/styles'
import { SubtilesBar, SubtitleLabel } from '../subtitlesBar/styles'
import { TabItemData } from './TabItemData'
import { TabTitleWrapper } from './styles'
import { PatientDetailsSectionEndRequestPropTypes } from './types'

export const NewRequestSection: FC<PatientDetailsSectionEndRequestPropTypes> = ({
  data,
  items,
}) => {
  const generateTabTitle = (status) => {
    const value = status.toLowerCase()
    if (value === 'autorizado') {
      return <OkIcon />
    }

    if (value === 'solicitação_negada' || value === 'solicitação negada') {
      return <CanceledIcon />
    }
    if (value === 'solicitação_em_auditoria' || value === 'solicitação em auditoria') {
      return <WarningIcon />
    }
    if (value === 'suspenso') {
      return <SuspendedIcon />
    }

    return null
  }

  return (
    <PageSection>
      <SubHeader>
        <SubTitle>Solicitação</SubTitle>
      </SubHeader>
      <Row className='reset-row'>
        <Col md={6}>
          <DocLabel>Diagnóstico:</DocLabel>
          <DocInput
            type='text'
            value={`${getField('attendanceCodeCid', data)} - ${getField('attendanceCid', data)}`}
            className='w-100'
            disabled
          />
        </Col>
      </Row>
      <SubtilesBar style={{ justifyContent: 'flex-start' }}>
        <SubtitleLabel>Status:</SubtitleLabel>
        <SubtitleLabel className='done'>Autorizado</SubtitleLabel>
        <SubtitleLabel className='late'>Solicitaçao em auditoria</SubtitleLabel>
        <SubtitleLabel className='danger'>Solicitação negada</SubtitleLabel>
        <SubtitleLabel className='stand-by'>Suspenso</SubtitleLabel>
      </SubtilesBar>

      <Tabs defaultActiveKey={items[0]?.id} className='mt-10 tabs-component'>
        {items.map((item) => (
          <Tab
            key={item.id}
            eventKey={item.id}
            title={<TabTitleWrapper>
              <span>{item.bloodComponentName}</span> {generateTabTitle(item?.statusDescription || '')}
            </TabTitleWrapper>}
          >
            <TabItemData data={item} />
          </Tab>
        ))}
      </Tabs>
      <Row>
        <Col md={6}>
          <SubTitleSmall>Justificativa de Cancelamento:</SubTitleSmall>
          <DocTextArea
            as='textarea'
            className='hpx-40'
            placeholder='Descrição da justificativa para o cancelamento da requisição de transfusão de sangue.'
            disabled
          />
        </Col>
        <Col md={6} style={{ display: 'flex', paddingBottom: 8, padding: '8px 0px 0px' }}>
          <Row className='d-flex space-between' >
            <Col md={6} className='d-flex flex-dir-col h-flex-end'>
              <DocLabel>Médico Solicitante:</DocLabel>
              <DocInput style={{ textOverflow: 'ellipsis' }} type='text' disabled value={getField('doctorName', data)} />
            </Col>
            <Col md={6} className='d-flex flex-dir-col h-flex-end'>
              <DocLabel>CRM:</DocLabel>
              <DocInput type='text' disabled value={getField('doctorCrmNumber', data)} />
            </Col>
            <Col md={6} className='d-flex flex-dir-col h-flex-end'>
              <DocLabel>Data:</DocLabel>
              <DocInput
                type='text'
                disabled
                value={format(new Date(data.registrationDate), 'dd/MM/yyyy')}
              />
            </Col>
            <Col md={6} className='d-flex flex-dir-col h-flex-end'>
              <DocLabel>Hora:</DocLabel>
              <DocInput
                type='text'
                disabled
                value={format(new Date(data.registrationDate), 'HH:mm')}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </PageSection>
  )
}
