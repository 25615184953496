import { FC, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Header } from '..'
import { SideBar } from '../sideBar'
import { LayoutContainerStyles, LayoutStyles } from './styles'

export const Layout: FC<{ samToken: string }> = ({ samToken }) => {
  const location = useLocation()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    setIsMenuOpen(false)
  }, [location])

  return (
    <LayoutStyles>
      <Header samToken={samToken} toggleMenu={() => setIsMenuOpen((cur) => !cur)} />
      <SideBar isMenuOpen={isMenuOpen} closeMenu={() => setIsMenuOpen(false)} />
      <LayoutContainerStyles>
        <Outlet />
      </LayoutContainerStyles>
    </LayoutStyles>
  )
}
