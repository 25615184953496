import { FC, CSSProperties } from 'react'
import { format } from 'date-fns'
import { TableStyles } from './styles'
import { TablesPropTypes } from './types'
import { ReactComponent as NonInitiatedIcon } from '../../assets/icons/nao-iniciado.svg'
import { ReactComponent as RecusedIcon } from '../../assets/icons/recusado.svg'
import { ReactComponent as OkIcon } from '../../assets/icons/validado.svg'
import { ReactComponent as CanceledIcon } from '../../assets/icons/cancelado.svg'
import { ReactComponent as WarningIcon } from '../../assets/icons/late.svg'
import { ReactComponent as SuspendedIcon } from '../../assets/icons/suspended.svg'

export const CheckingHemocomponentTable: FC<TablesPropTypes> = ({ headers, data }) => {

  const renderStatus = (item) => {
    const { status, responsable, dateReceved } = item

    if (status && Boolean(responsable && dateReceved)) {
      return <OkIcon />
    }

    if (!status && Boolean(responsable && dateReceved)) {
      return <RecusedIcon />
    }

    return <NonInitiatedIcon />
  }

  const generateTabTitle = (status) => {
    const value = status.toLowerCase()

    if (value === 'autorizado') {
      return <OkIcon />
    }

    if (value === 'solicitação_negada' || value === 'solicitação negada') {
      return <CanceledIcon />
    }

    if (value === 'solicitação_em_auditoria' || value === 'solicitação em auditoria') {
      return <WarningIcon />
    }

    if (value === 'suspenso') {
      return <SuspendedIcon />
    }

    return null
  }

  const handleStatus = (tabs: string, item: any) => {
    if (tabs === "statusAuthorization") {
      return generateTabTitle(item?.statusAuthorization)

    }

    return renderStatus(item)
  }

  const renderRow = (header: any, item: any) => {
    if (header.type === 'status') {
      return handleStatus(header.value, item)
    }

    if (header.type === 'time' && item[header.value]) {
      return format(new Date(item[header.value]), 'dd/MM/yyyy - HH:mm')
    }

    return item[header.value] as string
  }

  const shouldCenter = (column: string) => [
    'status',
    'dateReceved',
    'departureDate',
    'bagNumber',
    'statusAuthorization',
    'dateAuhotization',
    'passwordAuthorization',
    'quantity',
    'doctorCrm',
    'dateValidation'
  ].includes(column);

  const defaultCellStyle: CSSProperties = { textAlign: 'left', paddingLeft: 5 };

  return (
    <TableStyles>
      <thead>
      <tr>
        {headers?.map((item) => (
            <th key={item.label} style={shouldCenter(item.value) ? { textAlign: 'center' } : defaultCellStyle}>
              {typeof item === 'string' ? item : item.label}
            </th>
        ))}
      </tr>
      </thead>
      <tbody>
      {data?.map((item) => (
          <tr key={item.id}>
            {headers.map((header: any) => (
                <td key={header.value} style={shouldCenter(header.value) ? { textAlign: 'center' } : defaultCellStyle}>
                  {renderRow(header, item)}
                </td>
            ))}
          </tr>
      ))}
      </tbody>
    </TableStyles>
  )
}
