import ptBR from "date-fns/locale/pt-BR";
import { format, parse } from 'date-fns'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { registerLocale } from "react-datepicker";
import { Block, LoginModal, SubBlock, WarnModal } from '../../components'
import { BlockTitle } from '../../components/block/styles'
import { BloodOutputPatientDetailsSection } from '../../components/bloodComponentsOutput'
import { RecusedJustificationModal } from '../../components/bloodComponentTooltip/justificationModal'
import { BloodTypeModal } from '../../components/bloodTypeModal'
import {
  DocLabel,
  FormDropdownButton,
  FormInputDate,
  FormTextArea,
} from '../../components/form/styles'
import { JustificationModal } from '../../components/justificationModal'
import StyledOption from '../../components/styledOption'
import { BloodComponentTable } from '../../components/tables/BloodComponentTable'
import { FormCheck } from '../../components/transfusionRequest/styles'
import {
  bloodComponentOutput,
  deletebloodComponentOutput,
  editBloodComponentOutput,
  getBloodComponentOutputById,
  getBloodComponentOutputJustifications,
  getTransfutionRequest,
} from '../../service'
import { DateField, FormFieldsRow, RTNumber } from './styles'
import { BloodComponentRow, BloodOptions } from './types'
import {
  ABO_TYPES,
  ABO_TYPES_MATCH,
  BLOOD_COMPONENT_TABLE_HEADERS,
  INITIAL_VALUES,
  UM_TYPES,
  WARNS,
  areArraysOfObjectsEqual,
  replaceData,
  sumAccountsByType,
} from './utils'
import { InsertJustificationModal } from "../../components/insertJustificationModal";

registerLocale("ptBR", ptBR);

interface CustomHTMLInputElement extends HTMLInputElement {
  target: {
    value: string | boolean | number
  }
}

export const BloodComponentPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const textAreaRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isWarnModalOpen, setIsWarnModalOpen] = useState(false)
  const [isWarnModalText, setIsWarnModalText] = useState<string>('')
  const [isEditing, setIsEditing] = useState(false)
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const [rawBloodComponents, setRawBloodComponents] = useState<BloodComponentRow[]>([])
  const [bloodComponents, setBloodComponents] = useState<BloodComponentRow[]>([])
  const [isValid, setIsValid] = useState(false)
  const [rtData, setRtData] = useState<any>()
  const [data, setData] = useState<BloodComponentRow>({ ...INITIAL_VALUES })
  const [currentField, setCurrentField] = useState<string>('')
  const [COMPONENT_TYPES, setComponentTypes] = useState([])
  const [justificationModalOpen, setJustificationModalOpen] = useState(false)
  const [insertJustificationModal, setInsertJustificationModal] = useState(false)
  const [recusedModal, setRecusedModal] = useState(false)
  const [recusedBloodComponentJustification, setRecusedBloodComponentJustification] = useState('')
  const [hasChangedList, setHasChangedList] = useState(false)
  const [rawJustifications, setRawJustifications] = useState<any[]>([])
  const [enteredValue, setEnteredValue] = useState(0)
  const [isDeleting, setIsDeleting] = useState(false)
  const [loginAccess, setLoginAccess] = useState(null)
  const [selectedId, setSelectedId] = useState(null)

  const cleanFields = () => {

    setData({
      ...INITIAL_VALUES,
      um: '0',
      qty: '',
      pending: data.pending,
      justification: data?.justification ? data?.justification : '',
    })
  }

  const handleJustificationToAllComponents = (justificationString: string) => {

    const items = bloodComponents.map((item) => {

      if (!item.isBloodComponentRefused && !item.isBloodComponentReceved) {
        return { ...item, justification: justificationString }
      }

      return { ...item }
    })

    setBloodComponents(items)
  }

  const doGetTransfutionRequestComponents = async () => {
    const { data: requestData } = await getBloodComponentOutputById(Number(id))
    const { data: justificationData } = await getBloodComponentOutputJustifications(Number(id))

    setRawJustifications(justificationData.content)

    if (typeof requestData.content === 'string' || !requestData.content) {
      requestData.content = []
    }

    const items = requestData.content.map((component, key) => ({
      id: key,
      databaseId: component.id,
      bloodcomponent: component.hemocomponent,
      purseNumber: component.bagNumber,
      abo: ABO_TYPES.find((abo) => abo.label === component.aboRh)?.value,
      temperature: component.temperature,
      qty: String(component.quantity),
      um: UM_TYPES.find((um) => um.label === component.unitMeasurement.toLowerCase())?.value,
      validity: component.expirationDate,
      responsable: component.responsible,
      departureDate: component.departureDate,
      justification: component.justificationOutput,
      justificationRefused: component.justificationRefused,
      isBloodTypeCompatible: component.isBloodTypeCompatible,
      isBloodComponentAuthorized: component.isBloodComponentAuthorized,
      isBloodComponentRefused: component.isBloodComponentRefused,
      isBloodComponentReceved: component.isBloodComponentReceved,
    }))

    const justifiedItems = justificationData.content
    const existentJustifiedItems = justificationData.content.find((item) => requestData.content.find((sentItem) => sentItem.justification === item.justificationOutput))

    if (items.length && existentJustifiedItems) {
      items[0].justification = justifiedItems[0]?.justification
    }

    if (justifiedItems.length && items.length && existentJustifiedItems) {
      setData({
        ...data,
        pending: 'true',
        justification: justifiedItems[0]?.justification,
      })
    }

    setRawBloodComponents([...items])
    setBloodComponents(items)
  }

  const handleChange = ({ target }: any, element: string) => {
    if (element === 'um' && target.value.trim() !== '1') {
      data.qty = '1'
    } else if (element === 'um' && target.value.trim() === '1') {
      data.qty = ''
    }

    if (target.value === 'false' && element === 'pending') {
      data.justification = ''
      setData((cur) => ({ ...cur, [element]: target.value.trim() }))

      handleJustificationToAllComponents('')

      return
    }

    if (element === 'bloodcomponent' && target.value !== '0') {
      const um = rtData.items
        .find((component, index) => index + 1 === Number(target.value))
        ?.unitMeasure.toLowerCase()

      data.um = UM_TYPES.find((type) => type.label === um).value

      if (data.um === '2' || data.um === '3') {
        data.qty = '1'
      }
    }

    if (element === 'bloodcomponent' && target.value === '0') {
      data.um = '0'
      data.qty = ''

      cleanFields()
      setCurrentField(element)

      return
    }

    setCurrentField(element)
    setData((cur) => ({ ...cur, [element]: target.value.trim() }))
  }

  const handleChangeTextArea = ({ target }: ChangeEvent<HTMLTextAreaElement>, element: string) => {
    handleJustificationToAllComponents(target.value)

    setData((cur) => ({ ...cur, [element]: target.value }))
  }

  const handleCallbackLogin = async (loginData: any) => {
    try {
      const responsable = loginData[0]?.operator?.name

      const changedBloodComponents = areArraysOfObjectsEqual(bloodComponents, rawBloodComponents)

      const filteredByJustification = [...changedBloodComponents].sort((curItem: any) => {
        if (curItem.justication === null) {
          return 1
        }

        return -1
      })

      const items = filteredByJustification.map((bloodComponent) => ({
        id: bloodComponent.databaseId,
        transfusionRequestId: rtData.id,
        hemocomponent: COMPONENT_TYPES.find(
          (component) => String(component.value) === String(bloodComponent.bloodcomponent) ||
            component.label === bloodComponent.bloodcomponent
        )?.label,
        bagNumber: bloodComponent.purseNumber,
        aboRh: ABO_TYPES.find((abo) => abo.value === bloodComponent.abo)?.label,
        temperature: bloodComponent.temperature,
        quantity: Number(bloodComponent.qty),
        unitMeasurement: UM_TYPES.find((um) => um.value === bloodComponent.um)?.label,
        expirationDate: bloodComponent.validity,
        responsible: bloodComponent.responsable || responsable,
        isBloodTypeCompatible: bloodComponent.isBloodTypeCompatible,
        isBloodComponentAuthorized: bloodComponent.isBloodComponentAuthorized,
        departureDate: bloodComponent.departureDate || new Date().toISOString(),
        requestItemId: COMPONENT_TYPES.find(
          (component) => String(component.value) === String(bloodComponent.bloodcomponent) ||
            component.label === bloodComponent.bloodcomponent
        )?.id,
      })) as any[]


      if (!rawBloodComponents.length) {
        const justification = filteredByJustification[0]?.justification
        items[0].justification = justification !== '' ? justification : null
      }

      await bloodComponentOutput({ bloodComponentOutputs: [...items], aboRh: rtData.aboRh })
      setIsLoginModalOpen(false)


      cleanFields()

      await doGetTransfutionRequestComponents()
      setHasChangedList(false)
      toast.success('Componente registrado com sucesso!')

      textAreaRef.current.style.borderColor = '#c4c4c4'
      textAreaRef.current.style.borderWidth = '1px'
    } catch (e) {
      toast.error('Erro ao cadastrar')
    }
  }

  const onSelectTableRow = (rowData: BloodComponentRow) => {

    setData({
      ...rowData,
      bloodcomponent: Number(rowData.bloodcomponent),
      validity: format(new Date(rowData.validity), 'dd/MM/yyyy HH:mm'),
      pending: data.pending,
      justification: data.justification
    })
    setIsEditing(true)
  }

  function loadComponentTypes(rtItemsRaw, objectsRaw) {

    const rtItems = [...(rtItemsRaw || [])]
    const objects = sumAccountsByType(objectsRaw || [])


    const filteredQuantity = rtItems?.map((rtItem) => {
      const item = rtItem

      if (
        item.amountDoseDrug <=
        Number(objects.find((object) => object.hemocomponent === item.bloodComponentName)?.quantity)
      ) {
        item.disabled = true
      } else {
        item.disabled = false
      }

      return item
    })

    const filtered = filteredQuantity?.filter(
      (item) =>
        item.statusDescription?.toLowerCase() !== 'solicitação_negada' &&
        item.statusDescription?.toLowerCase() !== 'suspenso',
    )

    setComponentTypes(
      filtered?.map((item, key) => ({
        id: item.id,
        value: key + 1,
        label: item.bloodComponentName,
        disabled: item.disabled,
        warn: item.statusDescription?.toLowerCase() === 'solicitação_em_auditoria',
      })),
    )
  }

  const deleteBloodComponent = async ({ justification, responsable }: { justification: string, responsable: string }) => {

    try {
      setIsDeleting(false)

      await deletebloodComponentOutput({
        id: selectedId,
        justification,
        responsable
      })

      await doGetTransfutionRequestComponents()
      loadComponentTypes(rtData.items, bloodComponents)

      setSelectedId(null)
      toast.success('Componente deletado com sucesso!')

    } catch (error) {
      console.error({ error })
      toast.error('Erro ao deletar')
    }
  }

  const onSelecteDelete = async (databaseId: number, rowId: string) => {
    try {

      if (databaseId !== 0) {
        setSelectedId(databaseId)
        setIsDeleting(true)
        setIsLoginModalOpen(true)

        return
      }

      const items = [...bloodComponents.filter((component) => component.id !== rowId)]
      const rawItems = [...rawBloodComponents.filter((component) => component.id !== rowId)]

      setBloodComponents(items)
      setRawBloodComponents(rawItems)

      if (!items.length) {
        setData({
          ...data,
        })
      }

      const itemsToFilter = items.map((bloodComponent) => ({
        id: bloodComponent.databaseId,
        transfusionRequestId: rtData.id,
        hemocomponent: COMPONENT_TYPES.find(
          (component) =>
            String(component.value) === String(bloodComponent.bloodcomponent) ||
            component.label === bloodComponent.bloodcomponent,
        )?.label,
        bagNumber: bloodComponent.purseNumber,
        aboRh: ABO_TYPES.find((abo) => abo.value === bloodComponent.abo)?.label,
        temperature: bloodComponent.temperature,
        quantity: String(bloodComponent.qty),
        unitMeasurement: UM_TYPES.find((um) => um.value === bloodComponent.um)?.label,
        expirationDate: bloodComponent.validity,
        responsible: '',
        isBloodTypeCompatible: bloodComponent.isBloodTypeCompatible,
        isBloodComponentAuthorized: bloodComponent.isBloodComponentAuthorized,
        departureDate: format(new Date(), 'dd/MM/yyyy HH:mm'),
        isBloodComponentRefused: bloodComponent.isBloodComponentRefused,
        isBloodComponentReceved: bloodComponent.isBloodComponentReceved,
      }))

      loadComponentTypes(rtData.items, itemsToFilter)

      toast.success('Hemocomponente deletado com sucesso!')
    } catch (error) {
      toast.error('Algo deu errado na operação. Tente novamente!')
    }
  }

  async function handleEditBloodComponentOnApi(loginData, index: number) {
    const responsable = loginData[0]?.operator?.name

    const editedItem = {
      id: bloodComponents[index].databaseId,
      hemocomponent: COMPONENT_TYPES.find(
        (component) =>
          String(component.value) === String(bloodComponents[index].bloodcomponent) ||
          component.label === bloodComponents[index].bloodcomponent,
      )?.label,
      bagNumber: bloodComponents[index].purseNumber,
      aboRh: ABO_TYPES.find((abo) => abo.value === bloodComponents[index].abo)?.label,
      temperature: bloodComponents[index].temperature,
      quantity: Number(bloodComponents[index].qty),
      unitMeasurement: UM_TYPES.find((um) => um.value === bloodComponents[index].um)?.label,
      expirationDate: bloodComponents[index].validity,
      responsible: responsable,
      isBloodTypeCompatible: bloodComponents[index].isBloodTypeCompatible,
      isBloodComponentAuthorized: bloodComponents[index].isBloodComponentAuthorized,
      departureDate: bloodComponents[index].departureDate || new Date().toISOString()
    }

    await editBloodComponentOutput({ bloodComponentOutput: [editedItem] })

    setIsLoginModalOpen(false)
  }

  const handleEditBloodComponent = async (loginData?: any) => {

    try {
      if (data.databaseId && !loginData[0]?.operator?.name) {
        setIsLoginModalOpen(true)

        return
      }

      setLoading(true)

      const index = bloodComponents.map((component) => component.id).indexOf(data.id)
      bloodComponents[index] = data
      bloodComponents[index].validity = replaceData(data.validity).toISOString()

      if (bloodComponents[index].departureDate) {
        bloodComponents[index].departureDate = new Date().toISOString()
      }

      if (data.databaseId && loginData[0]?.operator?.name) {
        handleEditBloodComponentOnApi(loginData, index)
      }

      setBloodComponents([...bloodComponents])
      cleanFields()

      setIsEditing(false)
      setLoading(false)

      const items = bloodComponents.map((bloodComponent) => ({
        id: bloodComponent.databaseId,
        transfusionRequestId: rtData.id,
        hemocomponent: COMPONENT_TYPES.find(
          (component) =>
            String(component.value) === String(bloodComponent.bloodcomponent) ||
            component.label === bloodComponent.bloodcomponent,
        )?.label,
        bagNumber: bloodComponent.purseNumber,
        aboRh: ABO_TYPES.find((abo) => abo.value === bloodComponent.abo)?.label,
        temperature: bloodComponent.temperature,
        quantity: String(data.qty),
        unitMeasurement: UM_TYPES.find((um) => um.value === bloodComponent.um)?.label,
        expirationDate: bloodComponent.validity,
        responsible: '',
        isBloodTypeCompatible: bloodComponent.isBloodTypeCompatible,
        isBloodComponentAuthorized: bloodComponent.isBloodComponentAuthorized,
        isBloodComponentRefused: !!bloodComponent.isBloodComponentRefused,
        isBloodComponentReceved: !!bloodComponent.isBloodComponentReceved,
        departureDate: bloodComponent.departureDate,
        justification: bloodComponent.justification,
      }))

      loadComponentTypes(rtData.items, items)

      toast.success('Hemocomponente editado com sucesso!')
    } catch (error) {
      toast.error('Algo deu errado na operação. Tente novamente!')
      setLoading(false)
    }
  }

  const handleAddBloodComponent = async () => {
    try {
      setLoading(true)

      const itemBagNumberAlreadyExists = bloodComponents.find(
        (bloodComponent) =>
          bloodComponent.purseNumber === data.purseNumber
      )

      if (itemBagNumberAlreadyExists) {
        toast.info('Bolsa com número já cadastrado!')

        setLoading(false)
        return
      }

      const lastId = Number(bloodComponents[bloodComponents.length - 1]?.id) || 0

      bloodComponents.push({
        ...data,
        id: String(lastId + 1),
        databaseId: 0,
        validity: replaceData(data.validity).toISOString(),
      })

      setBloodComponents(() => [...bloodComponents])

      const items = bloodComponents.map((bloodComponent) => ({
        id: bloodComponent.databaseId,
        transfusionRequestId: rtData.id,
        hemocomponent: COMPONENT_TYPES.find(
          (component) =>
            String(component.value) === String(bloodComponent.bloodcomponent) ||
            component.label === bloodComponent.bloodcomponent,
        )?.label,
        bagNumber: bloodComponent.purseNumber,
        aboRh: ABO_TYPES.find((abo) => abo.value === bloodComponent.abo)?.label,
        temperature: bloodComponent.temperature,
        quantity: String(bloodComponent.qty),
        unitMeasurement: UM_TYPES.find((um) => um.value === bloodComponent.um)?.label,
        expirationDate: bloodComponent.validity,
        responsible: '',
        isBloodTypeCompatible: bloodComponent.isBloodTypeCompatible,
        isBloodComponentAuthorized: bloodComponent.isBloodComponentAuthorized,
        isBloodComponentRefused: !!bloodComponent.isBloodComponentRefused,
        isBloodComponentReceved: !!bloodComponent.isBloodComponentReceved,
        departureDate: format(new Date(), 'dd/MM/yyyy HH:mm'),
        justification: bloodComponent.justification,
      }))

      loadComponentTypes(rtData.items, items)

      cleanFields()
      setLoading(false)
      toast.success('Hemocomponente adicionado com sucesso!')
    } catch (error) {
      toast.error('Algo deu errado na operação. Tente novamente!')
      setLoading(false)
    }
  }

  const handleWarnResponse = (response: boolean, field: string) => {
    const event = { target: { value: '0' } } as unknown as ChangeEvent<
      CustomHTMLInputElement | HTMLInputElement
    >

    if (!response) {
      handleChange(event, field)
    }

    if (field === 'abo') {
      setData((_data) => ({
        ..._data,
        isBloodTypeCompatible: false,
      }))
    }

    if (field === 'bloodcomponent') {
      setData((_data) => ({
        ..._data,
        isBloodComponentAuthorized: false,
      }))
    }
  }

  const handleCheckWarnedOption = (_event, options, text: string) => {
    const event = _event
    const isWarned = options.find((_type) => String(_type.value) === event.target.value)

    if (isWarned?.warn) {
      setIsWarnModalOpen(true)
      setIsWarnModalText(text)
    }
  }

  const renderOptions = (list: BloodOptions[], selected) =>
    [{ label: 'Selecione', value: '0', warn: false }, ...list]
      .map(({ value, label, warn, disabled }) => {
        if (warn) {
          return (
            <StyledOption disabled={disabled} key={value} value={value}>
              * {label}
            </StyledOption >
          )
        }

        return (
          <option style={{ display: disabled ? 'none' : '' }} key={value} value={value} selected={value === selected}>
            {label}
          </option>
        )
      })

  const handleHemoTypes = (_list: BloodOptions[], selected) => {
    const matches = ABO_TYPES_MATCH.find((type) => type.value === rtData?.aboRh)?.matches
    let list = _list

    let matched = list?.map((_type) => {
      const type = _type

      type.warn = false

      if (!matches?.find((_match) => _match === type?.label) && matches && matches[0] !== '*') {
        type.warn = true
      }

      return type
    })

    if (matches?.length && matches[0] === '*') {
      matched = list.map((item) => ({ value: item.value, label: item.label, warn: false }))
    }

    list = matched

    return [{ label: 'Selecione', value: '0', warn: false }, ...list].map(
      ({ value, label, warn, disabled }) => {
        if (warn) {
          return (
            <StyledOption key={value} value={value} disabled={disabled}>
              * {label}
            </StyledOption>
          )
        }

        return (
          <option key={value} value={value} selected={value === selected}>
            {label}
          </option>
        )
      },
    )
  }

  const handleCancel = () => {
    navigate('/inicio')
  }

  const handleSendBloodComponents = () => {
    const filteredByJustification = [...bloodComponents].sort((curItem: any) => {
      if (curItem.justication === null) {
        return 1
      }

      return -1
    })

    const items = filteredByJustification.map((bloodComponent) => ({
      id: bloodComponent.databaseId,
      transfusionRequestId: rtData.id,
      hemocomponent: COMPONENT_TYPES.find(
        (component) =>
          Number(component.value) === Number(bloodComponent.bloodcomponent) ||
          component.label === bloodComponent.bloodcomponent,
      )?.label,
      bagNumber: bloodComponent.purseNumber,
      aboRh: ABO_TYPES.find((abo) => String(abo.value) === bloodComponent.abo)?.label,
      temperature: bloodComponent.temperature,
      quantity: String(bloodComponent.qty),
      unitMeasurement: UM_TYPES.find((um) => String(um.value) === bloodComponent.um)?.label,
      expirationDate: bloodComponent.validity,
      responsible: '',
      isBloodTypeCompatible: bloodComponent.isBloodTypeCompatible,
      isBloodComponentAuthorized: bloodComponent.isBloodComponentAuthorized,
      departureDate: format(new Date(), 'dd/MM/yyyy HH:mm'),
      justification: bloodComponent.justification,
    }))

    const objects = sumAccountsByType(items)

    const less = rtData.items.find((item) => {
      const sentObject = objects.find((object) => object.hemocomponent === item.bloodComponentName)

      if (item.amountDoseDrug > sentObject?.quantity || sentObject?.warned) {
        return true
      }

      return false
    })

    if (rawBloodComponents.length === 0 && (rtData?.items?.length > objects?.length || less) && !data.justification) {
      setJustificationModalOpen(true)

      return
    }

    setIsLoginModalOpen(true)
  }

  const doGetTransfutionRequest = async () => {
    try {
      setLoading(true)

      const { data: requestData } = await getTransfutionRequest(id)
      const { data: requestItens } = await getBloodComponentOutputById(Number(id))

      setRtData({
        ...(requestData?.content?.transfusionRequest || null),
        unityName: requestData?.content?.unityName,
        items: requestData?.content?.transfusionRequestItems,
      })

      setIsModalOpen(!requestData?.content?.transfusionRequest.aboRh)
      loadComponentTypes(requestData?.content.transfusionRequestItems, requestItens.content)

      setLoading(false)
    } catch (error) {

      toast.error('Algo deu errado na requisição. Tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModal = (redirect = true) => {
    setIsModalOpen(false)

    if (redirect) {
      navigate('/inicio')
    }
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date()
    const selectedDate = new Date(time)
    const date = parse(data.validity, 'dd/MM/yyyy', new Date())

    if (date.getDate() > new Date().getDate() || currentDate.getMonth() < date.getMonth()) {
      return true
    }

    return currentDate.getTime() < selectedDate.getTime()
  }

  const onOpenModal = (item: BloodComponentRow) => {
    if (item.isBloodComponentRefused !== true) {
      return
    }

    setRecusedModal(true)
    setRecusedBloodComponentJustification(item.justificationRefused)
  }

  useEffect(() => {
    data.justification = ''
    data.pending = 'false'
  }, [])

  useEffect(() => {

    const emptyField = Object.keys(data).find(
      (item) =>
        data[item] === '' &&
        item !== 'id' &&
        item !== 'departureDate' &&
        item !== 'isBloodComponentAuthorized' &&
        item !== 'isBloodTypeCompatible' &&
        item !== 'isBloodComponentRefused' &&
        item !== 'isBloodComponentReceved' &&
        item !== 'databaseId' &&
        item !== 'justification' &&
        item !== 'justificationRefused' &&
        item !== 'responsible',
    )

    if (
      emptyField !== undefined ||
      data.abo === "0" ||
      data.bloodcomponent === "0" ||
      data?.validity?.length < 12 ||
      data.temperature[(data?.temperature?.length || -1) - 1] === ','
    ) {
      setIsValid(false)
      return
    }

    setIsValid(true)
  }, [data])

  useEffect(() => {
    doGetTransfutionRequest()

  }, [id])

  useEffect(() => {
    if (COMPONENT_TYPES.length) {
      doGetTransfutionRequestComponents()
    }
  }, [COMPONENT_TYPES])


  useEffect(() => {
    setHasChangedList(!!areArraysOfObjectsEqual(bloodComponents, rawBloodComponents).length)

  }, [bloodComponents])


  const openInsertModal = () => {
    setInsertJustificationModal(true)
  }


  const handleCallback = (loginData) => {
    setIsLoginModalOpen(false)
    setLoginAccess(loginData.access)

    if (isEditing)
      handleEditBloodComponent(loginData.access)

    if (!isEditing && !isDeleting)
      handleCallbackLogin(loginData.access)

    if (isDeleting) {
      openInsertModal()
    }

  }

  return (
    <Block
      titleComponent={
        <>
          <Row>
            <BlockTitle color='#0064A9' style={{ marginBottom: 10 }}>
              PROTOCOLO DE SAÍDA DE HEMOCOMPONENTES
            </BlockTitle>
          </Row>

          <Row style={{ justifyContent: 'end', display: 'flex', marginBottom: 20 }}>
            <Col style={{ display: 'flex', justifyContent: 'end' }}>
              <RTNumber variant='outline-secondary'>N° RT: {rtData?.id}</RTNumber>
            </Col>
          </Row>
        </>
      }
    >
      <SubBlock title='DADOS DO PACIENTE' subtitle={`N° Atend.: ${rtData?.attendanceCode}`}>
        <Container fluid style={{ padding: 0 }}>
          <Col>{rtData && <BloodOutputPatientDetailsSection rtData={{ ...rtData }} />}</Col>
        </Container>
      </SubBlock>

      <SubBlock title='SAÍDA DE HEMOCOMPONENTE'>
        <Container fluid>
          <Col>
            <Row className='no-padding-x'>
              <hr className='no-padding-x' />
            </Row>
            <FormFieldsRow className='no-padding-x' style={{ gap: 20 }}>
              <Col
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  width: 'fit-content',
                  flex: 0,
                  padding: 0,
                }}
              >
                <DocLabel>Hemocomponente:</DocLabel>
                <FormDropdownButton
                  id='tipoAtendimento'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleCheckWarnedOption(event, COMPONENT_TYPES, WARNS.bloodComponent.text)
                    handleChange(event, 'bloodcomponent')
                  }}
                  title='Hemocomponente'
                  defaultValue='todos'
                  height={25}
                  padding='3px 10px'
                  width={290}
                >
                  {renderOptions(COMPONENT_TYPES, data.bloodcomponent)}
                </FormDropdownButton>
              </Col>

              <Col
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  width: 'fit-content',
                  flex: 0,
                  padding: 0,
                }}
              >
                <DocLabel>N° da Bolsa:</DocLabel>
                <FormInputDate
                  type='text'
                  minLength={3}
                  onChange={(_event: ChangeEvent<HTMLInputElement>) => {

                    if (!Number(_event.target.value) && _event.target.value !== "")
                      return

                    const event = _event

                    if (event.target.value.length < 51) {
                      handleChange(event, 'purseNumber')
                    } else if (event.target.value.length > 50) {
                      event.target.value = event.target.value.slice(0, 50)
                    }
                  }}
                  placeholder='Nº da Bolsa'
                  disabled={data.bloodcomponent === '0'}
                  height={25}
                  value={data.purseNumber}
                  width={200}
                />
              </Col>
              <Col
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  width: 'fit-content',
                  flex: 0,
                  padding: 0,
                }}
              >
                <DocLabel>ABO/Rh:</DocLabel>
                <FormDropdownButton
                  id='tipoAtendimento'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleCheckWarnedOption(event, ABO_TYPES, WARNS.bloodType.text)
                    handleChange(event, 'abo')
                  }}
                  defaultValue='todos'
                  height={25}
                  padding='3px 10px'
                  width={100}
                  disabled={data.bloodcomponent === '0'}
                >
                  {handleHemoTypes(ABO_TYPES, data.abo)}
                </FormDropdownButton>
              </Col>

              <Col
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  width: 'fit-content',
                  flex: 0,
                  padding: 0,
                }}
              >
                <DocLabel>Temperatura:</DocLabel>
                <FormInputDate
                  type='text'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target
                    const pattern = /^-?\d{0,2}(,\d?)?$/

                    if (pattern.test(value) && !value.includes('-,')) {
                      handleChange(event, 'temperature')
                    }
                  }}
                  disabled={data.bloodcomponent === '0'}
                  value={data.temperature}
                  height={25}
                  width={60}
                />
              </Col>

              <Col
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  width: 'fit-content',
                  flex: 0,
                  padding: 0,
                }}
              >
                <DocLabel>UM:</DocLabel>
                <FormDropdownButton
                  id='tipoAtendimento'
                  height={25}
                  padding='3px 10px'
                  width={70}
                  disabled
                  value={data.um}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, 'um')}
                >
                  {renderOptions(UM_TYPES, data.um)}
                </FormDropdownButton>
              </Col>

              <Col
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  width: 'fit-content',
                  flex: 0,
                  padding: 0,
                }}
              >
                <DocLabel>Qtd.:</DocLabel>
                <FormInputDate
                  onChange={(_event: ChangeEvent<HTMLInputElement>) => {
                    const event = _event
                    event.target.value = event.target.value.replace(/\D/g, '')
                    if (Number(event.target.value) > 0 || event.target.value === '') {
                      if (event.target.value.length < 10) {
                        handleChange(event, 'qty')
                      } else {
                        event.target.value = event.target.value.slice(0, 9)
                      }
                    }
                  }}
                  disabled={data.um === '2' || data.um === '3' || data.bloodcomponent === '0'}
                  value={data.qty}
                  height={25}
                  width={50}
                />
              </Col>

              <Col
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  width: 'fit-content',
                  flex: 0,
                  padding: 0,
                }}
              >
                <DocLabel>Validade:</DocLabel>
                <DateField
                  placeholderText='Período'
                  selectsRange
                  id='datesRange'
                  dateFormat='dd/MM/yyyy'
                  timeFormat='HH:mm'
                  minDate={new Date()}
                  filterTime={filterPassedTime}
                  readOnly={false}
                  value={data.validity}
                  showTimeSelect
                  onChange={(update: [Date | null, Date | null]) => {
                    if (update.length) {
                      const date = format(new Date(update[0]), 'dd/MM/yyyy')
                      data.validity = date

                      setData({ ...data })
                    } else {
                      const date = format(new Date(update as unknown as Date), 'HH:mm')

                      data.validity = `${data.validity.trim().slice(0, 10)} ${date}`

                      setData({ ...data })
                    }
                  }}
                  timeIntervals={1}
                  disabled={data.bloodcomponent === '0'}
                  locale="ptBR"
                />
              </Col>
            </FormFieldsRow>
            <Row className='no-padding-x' style={{ marginTop: 20, justifyContent: 'end' }}>
              <Button
                style={{
                  width: '100%',
                  maxWidth: '100px',
                  height: 24,
                  padding: 0,
                  fontSize: 12,
                  transition: '0.5s',
                }}
                variant={loading || !isValid ? 'secondary' : 'primary'}
                type='button'
                onClick={isEditing ? handleEditBloodComponent : handleAddBloodComponent}
                disabled={loading || !isValid}
              >
                {isEditing ? 'Editar' : 'Adicionar'}
              </Button>
            </Row>
            <Row className='no-padding-x' style={{ marginTop: 15 }}>
              <hr className='no-padding-x' />
            </Row>
          </Col>
          <Row className='table-row'>
            {loading ? (
              'Buscando...'
            ) : (
              <BloodComponentTable
                headers={BLOOD_COMPONENT_TABLE_HEADERS}
                data={bloodComponents}
                onSelect={onSelectTableRow}
                onOpenModal={onOpenModal}
                onSelecteDelete={onSelecteDelete}
                componentList={COMPONENT_TYPES}
              />
            )}
          </Row>

          <Col className='w-100' style={{ margin: 0, padding: 0 }}>
            <Row style={{ marginBottom: 20 }}>
              <p className='text-justify text-danger ' style={{ fontSize: 12, padding: 0 }}>
                Caso não sejam atendidas as prescrições de hemocomponentes, preencher justificativa
                abaixo.
              </p>
            </Row>

            <Row className='no-padding-x' style={{ marginBottom: 10 }}>
              <Col className='d-flex align-items-center'>
                <FormCheck
                  autoComplete="off"
                  style={{ background: '#fff' }}
                  onChange={(_event: React.ChangeEvent<any>) => {

                    const event = _event

                    if (bloodComponents.length === 0) {
                      event.stopPropagation();
                      return
                    }

                    event.target.value = event.target.value === 'false'
                    handleChange(event, 'pending')
                    textAreaRef.current.style.borderColor = '#c4c4c4'
                    textAreaRef.current.style.borderWidth = '1px'
                  }}
                  value={data.pending === 'true'}
                  checked={data.pending === 'true'}
                  disabled={rawJustifications.length}
                />

                <DocLabel style={{ marginLeft: 5, fontSize: 14, fontWeight: 500 }}>
                  Não foram atendidas todas as prescrições médicas.
                </DocLabel>
              </Col>
            </Row>

            <Row className='no-padding-x'>
              <Col>
                <DocLabel style={{ marginBottom: 5, fontSize: 12 }}>Justificativa:</DocLabel>
                <FormTextArea
                  id="textarea-input"
                  autoComplete="off"
                  ref={textAreaRef}
                  style={{ background: '#fff', transition: '0.3s' }}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                    setEnteredValue(event.target.value?.length)

                    if (enteredValue <= 200) {
                      handleChangeTextArea(event, 'justification')
                    }
                  }}
                  height={70}
                  maxLength={200}
                  disabled={
                    data.pending === 'false' ||
                    !!rawBloodComponents[0]?.justification?.length}
                  value={data.justification}
                />
              </Col>
            </Row>
          </Col>
        </Container>
      </SubBlock>

      <Row className='h-flex-end' style={{ marginTop: 20 }}>
        <Col style={{ display: 'flex', flex: 0, alignItems: 'center' }}>
          <Button
            style={{ padding: 10, fontSize: 12, paddingTop: 3, paddingBottom: 3, height: 24 }}
            type='button'
            variant='outline-secondary'
            onClick={handleCancel}
          >
            Cancelar
          </Button>
        </Col>
        <Col style={{ display: 'flex', flex: 0, alignItems: 'center' }}>
          <Button
            style={{ padding: 10, fontSize: 12, paddingTop: 3, paddingBottom: 3, height: 24 }}
            type='button'
            variant='primary'
            disabled={
              (!hasChangedList) ||
              (data.pending === 'true' && data.justification?.length < 5)
            }
            onClick={handleSendBloodComponents}
          >

            Salvar
          </Button>
        </Col>
      </Row>

      <JustificationModal
        show={justificationModalOpen}
        handleClose={async () => {
          setJustificationModalOpen(false)
        }}
        handleConfirm={() => {
          textAreaRef.current.style.borderColor = 'rgb(220, 53, 69)'
          textAreaRef.current.style.borderWidth = '2px'
          data.pending = 'true'
          setJustificationModalOpen(false)
        }}
      />

      <InsertJustificationModal
        show={insertJustificationModal}
        handleClose={async () => {
          setInsertJustificationModal(false)
        }}
        handleConfirm={(justification) => {
          setInsertJustificationModal(false)
          deleteBloodComponent({ justification, responsable: loginAccess[0].operator.name })
        }}
      />

      <LoginModal
        layoutVariant='stamp-login'
        subtitle='USUÁRIO RESPONSÁVEL PELA ENTREGA'
        show={isLoginModalOpen}
        callbackLogin={(loginData) => handleCallback(loginData)}
        handleClose={() => setIsLoginModalOpen(false)}
      />

      <BloodTypeModal
        show={isModalOpen}
        handleClose={handleCloseModal}
        callbackLogin={(selected) => {
          setRtData({ ...rtData, aboRh: selected.bloodType.label })
        }}
      />

      <WarnModal
        title='Atenção'
        warn={isWarnModalText}
        show={isWarnModalOpen}
        handleClose={() => setIsWarnModalOpen(false)}
        callback={(response) => handleWarnResponse(response, currentField)}
      />

      <RecusedJustificationModal
        handleClose={() => {
          setRecusedModal(false)
          setRecusedBloodComponentJustification('')
        }}
        justification={recusedBloodComponentJustification}
        show={recusedModal}
      />
    </Block>
  )
}
