import styled from 'styled-components'

export const SubtilesBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  &.flex-start {
    justify-content: flex-start;
  }

  &.flex-column {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    width: 150px;
    max-width: 50%;

    p {
      margin-bottom: 10px;
      margin-left: 20px;
    }
  }
`

export const SubtitleLabel = styled.p`
  color: #868686;
  font-size: 12px;
  position: relative;
  &:not(:first-child) {
    margin-left: 20px;
  }
  &::before {
    content: '';
    position: absolute;
    left: -8px;
    top: 50%;
    margin-top: -3px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
  &.done::before {
    background-color: #2ecc71;
  }
  &.done-obs::before {
    background-color: #9e00ff;
  }
  &.in-progress::before {
    background-color: #0064a9;
  }
  &.late::before {
    background-color: #e67e22;
  }
  &.stand-by::before {
    background-color: #868686;
  }
  &.danger::before {
    background-color: #dd0404;
  }
`
