import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

export const TransfusionalModalStyles = styled(Modal)`
  display: flex !important;
  align-items: center;


  .tabs-component.transfusion-history .nav-link.active {
    top: 0px;
    background: #fff;
    position: relative;
    z-index: 7;
  }

  .transfusion-history li.nav-item {
      border-bottom: 1px solid #dee2e6;
  }

  .nav-tabs.transfusion-history {
      border: none;
  }

  .tab-content {
    border: 1px solid #000;
    border-radius: 5px;
    padding: 10px 20px;
    border: 2px solid #C4C4C4;
  }

  ul.mt-10.tabs-component.transfusion-history.nav.nav-tabs {
      padding: 0px 10px;
      z-index: 5;
      position: relative;
      top: 2px;
  }

  .tabs-component.transfusion-history .nav-link.active {
      top: 1px;
      position: relative;
  }

  .tab-content table {
      margin: 10px 0px 30px;
  }

  .transfusion-history li.nav-item {
      background: #fff;
      border-bottom: 2px solid #c4c4c4;
  }


  .tabs-component .nav-link { 
    white-space: nowrap;
    transition: 0s;
  }

  .modal {
    &-dialog {
      max-width: 90vw;
      width: 100%;
    }
    &-content {
      width: 100%;
      padding: 15px;
      .title {
        text-align: center;
        font-weight: normal;
        font-size: 14px;
        &:last-of-type {
          margin-bottom: 12px;
        }
        &.main {
          font-weight: bold;
        }
      }

      .btn {
        padding: 4px 20px;
        &-primary {
          background-color: #0064a9;
        }
      }
    }

    &-body {
      padding: 0;

      table {
        width: 100%;
      }

      section {
        padding: 8px 10px;
        margin-bottom: 10px;
      }

      input {
        border: none;
      }
    }

    &-footer {
      border-top: none;
      justify-content: flex-end;
      padding: 0px;
    }

    &-input {
      border: 1px solid #d9d9d9;
      padding: 14px;
      border-radius: 4px;
      color: #4f4f4f;
      width: 100%;
      outline: none;
      &-wrapper {
        margin-top: 15px;
        margin-bottom: 15px;
        position: relative;
      }
      &-btn {
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
      }
    }
  }
`
