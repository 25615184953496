/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { forwardRef } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { getField } from '../../utils'
import { InfoBlock, TextALt } from '../typingStampModal/styles'

export const TypingStampPrintLayout = forwardRef<HTMLInputElement, any>(({ data }: any, ref) => (
  <div ref={ref} style={{ position: 'absolute', zIndex: '-1', width: 360 }}>
    <Modal.Body>
      <InfoBlock style={{ padding: 2, paddingLeft: 60, paddingRight: 60, minHeight: 80 }}>
        <Row>
          <Col md={12}>
            <TextALt className='bold-text'>{getField('patientName', data.patientData)}</TextALt>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TextALt className='bold-text'>
              {new Date(getField('patientBornDate', data.patientData)).toLocaleDateString()}
            </TextALt>
          </Col>
          <Col md={6}>
            <TextALt className='bold-text'>{getField('attendanceCode', data.patientData)}</TextALt>
          </Col>
          <Col md={12}>
            <TextALt className='bold-text'>{getField('unityName', data.patientData)}</TextALt>
          </Col>
        </Row>
      </InfoBlock>
    </Modal.Body>
    {/* <Modal.Footer>
      <Row style={{ width: '100%', margin: 0 }}>
        <Col
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          className='p-0'
        >
          <TextALt className='bold-text'>{getField('attendanceCode', data.patientData)}</TextALt>
          <div className='barcode-block'>
            <svg id='barcode' />
          </div>
        </Col>
      </Row>
    </Modal.Footer> */}
  </div>
))
