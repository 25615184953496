import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

export const CancelTransfusionalModalStyles = styled(Modal)`
  display: flex !important;
  align-items: center;
  .modal {
    &-dialog {
      max-width: 90vw;
      width: 560px;
    }
    &-content {
      width: 100%;
      padding: 15px;
      .title {
        text-align: center;
        font-weight: normal;
        font-size: 14px;
        &:last-of-type {
          margin-bottom: 12px;
        }
        &.main {
          font-weight: bold;
        }
      }

      .btn {
        padding: 4px 20px;
        &-primary {
          background-color: #0064a9;
        }
      }
    }
    &-footer {
      border-top: none;
      padding: 0px;
    }
  }
`
