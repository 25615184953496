import { Doughnut } from 'react-chartjs-2'
import styled from 'styled-components'

export const DoughnutChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100px;
`

export const DoughnutChartStyles = styled(Doughnut)`
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
`
export const DoughChartTitle = styled.h4`
  font-size: 14px;
  margin-bottom: 18px;
  font-weight: bold;
`

export const DoughnutChartRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
`
