/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-useless-fragment */
import { FC } from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import { Info, StyledTooltip } from './styles'
import { BloodComponentTooltipProps } from './types'

export const CustomTooltipBloodComponent: FC<BloodComponentTooltipProps> = ({ children, isRecused }) => {

  if (isRecused === undefined || isRecused === false) {
    return <>{children}</>
  }

  return (
    <OverlayTrigger
      placement='bottom-start'
      overlay={
        <StyledTooltip id='tooltip-id' style={{ display: isRecused === true ? 'block' : 'none' }}>
          <Info>
            <h6>Clique para ver a justificativa da recusa</h6>
          </Info>
        </StyledTooltip>
      }
    >
      {children}
    </OverlayTrigger>
  )
}
