import { Form } from 'react-bootstrap'
import styled from 'styled-components'

export const AppSelectStypes = styled(Form.Select)`
  height: 25px;
  padding: 2px 4px 4px;
  font-size: 12px;
  box-shadow: none !important;
  border: 1px solid #c4c4c4 !important;
`
