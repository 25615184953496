/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import ptBR from "date-fns/locale/pt-BR";
import { format } from 'date-fns'
import { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import { Button, Col, Container, Row, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  Autocomplete,
  Block,
  LoginModal,
  TransfusionRequestTable,
  TransfusionalModal,
  TypingStampModal,
} from '../../components'
import { CustomDatePicker, CustomDatePickerInput } from '../../components/datePicker/styles'
import { DocLabel, FormDropdownButton, FormInput } from '../../components/form/styles'
import { Actions } from '../../components/tables/types'
import { useAuthContext } from '../../context/auth'
import { getDashboardData, getTransfusionHistory, getTransfutionRequest } from '../../service'
import { PatientData } from '../../types'
import {
  INITIAL_VALUES,
  STATUS,
  TIPO_ATENDIMENTO,
  getDefaultUnity,
  getPages,
  handleRefresh,
  validateAction,
} from './utils'

const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
  <CustomDatePickerInput type='button' onClick={onClick} ref={ref}>
    {value}
  </CustomDatePickerInput>
))

export const Home = () => {
  const navigate = useNavigate()
  const { token, appKey, acessos, suggestions } = useAuthContext()

  const [data, setData] = useState(INITIAL_VALUES)
  const [datesRange, setDatesRange] = useState<[Date | null, Date | null]>([
    new Date(new Date().setDate(new Date().getDate() - 2)),
    new Date(),
  ])
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false)
  const [rts, setRts] = useState([])
  const [pages, setPages] = useState<number[]>([])
  const [isValid, setIsValid] = useState(false)
  const [unityId, setUnityId] = useState('')
  const [saveUnityId, setSaveUnityId] = useState('')
  const [validSearchUnityId, setValidSearchUnityId] = useState(false)
  const [verifyValues, setVerifyValues] = useState('')
  const [currentPage, setCurrentPage] = useState('0')
  const [selectedRow, setSelectedRow] = useState<PatientData>()
  const [actionConfig, setActionConfig] = useState<{ target: string; value: string }>(null)
  const [rtHistory, setRtHistory] = useState(null)

  const [unity, setUnity] = useState('')

  const [startDate, endDate] = datesRange

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>, element: string) => {
    setData((cur) => ({ ...cur, [element]: target.value.trim() }))
  }


  const handleCallbackLogin = (callbackData: any) => {

    if (actionConfig.target === 'etiq-tipagem') {
      setSelectedRow((row) => ({ ...row, unityName: callbackData.access[0].branch.description, collectorName: callbackData.access[0].operator.name } as PatientData))
      setIsTicketModalOpen(true)
    }
    if (actionConfig.target === 'recibo-hemocomp') {
      sessionStorage.setItem('responsible', callbackData.access[0].operator.name)
      navigate(`/recebimento-hemocomponente/${actionConfig.value}`)
    }
    setIsLoginModalOpen(false)
  }

  const renderTooltip = (tooltip) => {
    switch (tooltip) {
      case 'unity':
        return <Tooltip id="tooltip">{unity}</Tooltip>
      default:
        return null;
    }
  }

  const doGetTransfusionHistory = async (id) => {
    try {
      const response = await getTransfusionHistory(id)
      setRtHistory(response.data.content)
    } catch (error) {
      toast.error('Algo deu errado na operação. Tente novamente!')
    }
  }

  const handleDropDownClick = async (action: Actions, item: PatientData) => {
    if (action === 'rt') {
      navigate(`/requisicao-transfusao/${item?.transfusionRequest?.id || ''}`)
      return
    }

    if (action === 'recibo-hemocomp') {
      setActionConfig({
        target: 'recibo-hemocomp',
        value: item?.transfusionRequest?.id.toString() || '',
      })
      setIsLoginModalOpen(true)
      return
    }

    if (action === 'etiq-tipagem') {
      setActionConfig({ target: 'etiq-tipagem', value: '' })
      const isValidated = validateAction(item)

      const isTyped = item.statusRequestDto.find(
        (obj) => obj.statusDescription.toLowerCase() === 'tipado',
      )


      if (isValidated && !isTyped) {
        setSelectedRow(item)
        setIsLoginModalOpen(true)
      } else if (isValidated) {


        const { data: requestData } = await getTransfutionRequest(item.transfusionRequest.id)

        setSelectedRow({ ...item, unityName: requestData.content.unityName, transfusionRequestDto: item.transfusionRequestDto, collectorName: requestData.content.transfusionRequest.responsibleTyping })
        setIsTicketModalOpen(true)
      }

      return
    }

    if (action === 'saida-hemocomp') {
      const isValidatedAndTyped = item.statusRequestDto.filter(
        (obj) =>
          obj.statusDescription.toLowerCase() === 'validado' ||
          obj.statusDescription.toLowerCase() === 'tipado',
      )

      if (isValidatedAndTyped.length < 2) {
        toast.info(
          'Necessário executar a tipagem da RT para prosseguir com a saída dos hemocompomentes.',
        )

        return
      }

      navigate(`/hemocomponentes/${item?.transfusionRequest?.id || ''}`)
    }

    if (action === 'acomp-transf') {
      doGetTransfusionHistory(item?.transfusionRequest?.id || '')
    }
  }

  const getPayload = (reset, page = '0') => {

    const temp = datesRange.map((item) => format(item || new Date(), 'yyyy-MM-dd'))

    const payload = {
      ...data,
      initialDate: temp[0],
      finalDate: temp[1],
      position: page,
      unityId,
    }

    if (reset) payload.position = '0'

    sessionStorage.setItem('dashboard_filter', JSON.stringify(payload))

    return payload
  }

  const handleSearch = async (payload, page = '0') => {
    try {
      const defaultValues = {
        attendanceCode: '',
        attendanceType: '',
        initialDate: '',
        finalDate: '',
        patientName: '',
        position: '',
        unityId: '',
        status: '',
        rtCode: payload.rtCode
      }
      setLoading(true)
      const { data: responseData } = await getDashboardData(
        {
          ...payload.rtCode ? defaultValues : payload, position: page
        },
        appKey,
        token,
      )

      setLoading(false)
      if (typeof responseData.content === 'string') {
        toast.info("Nâo existem RT's para esse filtro")
      }

      setData((cur) => ({ ...cur, position: page }))
      setRts(responseData?.content?.transfusionRequests || [])
      setPages(getPages((responseData?.content?.quantityPages || 0) + 1))
      setCurrentPage(page)
    } catch (error) {

      setData((cur) => ({ ...cur, position: '0' }))
      setRts([])
      setPages([0])
      setCurrentPage(``)

      toast.error('Algo deu errado na operação. Tente novamente!')
      setLoading(false)
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleSearch(getPayload(true));
    }
  };

  const renderOptions = (list: { value: string; label: string }[], selected: string) =>
    list.map(({ value, label }) => (
      <option key={value} value={value} selected={value === selected} disabled={value === '0'}>
        {label}
      </option>
    ))

  const handleGetValue = (value: string) => {
    setIsValid(false);
    const filteredSuggestions = suggestions.filter((suggestion) => suggestion === value);

    if (data.rtCode.length > 0 && value === '') {
      setSaveUnityId('');
      setVerifyValues(unityId);
      setIsValid(true);
      return;
    }

    if (filteredSuggestions.length === 0) {
      setIsValid(false);
      return;
    }

    setUnity(value);

    if (validSearchUnityId) {
      setSaveUnityId('');
    }

    setVerifyValues('');

    if (saveUnityId !== '') {
      setIsValid(true);
      setUnityId(saveUnityId);
      getDefaultUnity(suggestions, saveUnityId);
      return;
    }

    if (verifyValues !== '' && value !== '') {
      setIsValid(true);
      setUnityId(verifyValues);
      getDefaultUnity(suggestions, verifyValues);
      return;
    }

    if (value.includes(' - ')) {
      setUnityId(value.split(' - ')[0] || '');
      setIsValid(true);
      return;
    }

    if (value === '') {
      setIsValid(false);
      return;
    }

    if (unityId !== '') {
      setIsValid(true);
    }
  };


  const handleCloseStampingModal = () => {
    try {
      setIsTicketModalOpen(false)
      handleSearch(getPayload(true))
    } catch (error) {
      toast.error('Algo deu errado na operação. Tente novamente!')
    }
  }

  const getPreviewedFilter = (origin) => {
    const initalDateFormmated = new Date(origin.initialDate)
    const finalDateFormmated = new Date(origin.finalDate)

    // eslint-disable-next-line no-unused-expressions
    initalDateFormmated.setDate(initalDateFormmated.getDate() + 1)
    finalDateFormmated.setDate(finalDateFormmated.getDate() + 1)

    setDatesRange([
      initalDateFormmated,
      finalDateFormmated
    ])
    setCurrentPage(origin.position)
    setUnityId(origin?.unityId || acessos?.filial?.codigo || '')
    setSaveUnityId(origin?.unityId || acessos?.filial?.codigo || '')
    setData(origin)
    setValidSearchUnityId(true);
  }

  useEffect(() => {
    if (rtHistory) {
      setIsModalOpen(true)
    }
  }, [rtHistory])

  useEffect(() => {
    if (!isModalOpen) {
      setRtHistory(null)
    }
  }, [isModalOpen])

  useEffect(() => {
    if (appKey && token) {
      const filter = sessionStorage.getItem('dashboard_filter')

      const dashboardFilter = filter ? JSON.parse(filter) : ""

      if (dashboardFilter) {
        getPreviewedFilter(dashboardFilter)
        handleSearch(dashboardFilter)
        return
      }

      const temp = datesRange.map((item) => format(item || new Date(), 'yyyy-MM-dd'))
      if (suggestions.length) {

        handleSearch({
          ...data,
          initialDate: data.initialDate || temp[0],
          finalDate: data.finalDate || temp[1],
          unityId: suggestions[0]?.slice(0, 3),
        })
      }
    }
  }, [appKey, token, suggestions])

  useEffect(() => {
    if (data.rtCode.length > 0) {
      return;
    }

    if (!unityId || !datesRange[0] || !datesRange[1]) {
      setIsValid(false)
      return
    }
    setIsValid(true)
  }, [unityId, datesRange])

  useEffect(() => {
    window.addEventListener('keydown', handleRefresh)

    window.addEventListener('beforeunload', (e) => {
      sessionStorage.removeItem('dashboard_filter')

      e.preventDefault()
    })

    return () => {
      window.removeEventListener('keydown', handleRefresh)
    }
  }, [])

  return (
    <>
      <Block title='DASHBOARD'>
        <Container fluid>
          <Row className='no-padding-x'>
            <Col md={2} style={{ padding: 12, width: 140 }}>
              <DocLabel>Nº Atend.</DocLabel>
              <FormInput
                type='text'
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChange(event, 'attendanceCode')
                }
                placeholder='Nº Atendimento'
                debounceTimeout={500}
                disabled={data.rtCode.length > 0 || !isValid}
                onKeyDown={handleKeyUp}
                value={data.rtCode.length > 0 ? '' : data.attendanceCode}
              />
            </Col>
            <Col md={1} style={{ padding: 12, width: 88 }}>
              <DocLabel>Nº RT</DocLabel>
              <FormInput
                type='text'
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChange(event, 'rtCode')
                }
                placeholder='Nº RT'
                debounceTimeout={500}
                disabled={false || !isValid}
                onKeyDown={handleKeyUp}
                value={data.rtCode}
              />
            </Col>
            <Col md={3} style={{ padding: 12 }}>
              <DocLabel>Nome do Paciente</DocLabel>
              <FormInput
                type='text'
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChange(event, 'patientName')
                }
                onKeyDown={handleKeyUp}
                placeholder='Nome do Paciente'
                debounceTimeout={500}
                disabled={data.rtCode.length > 0}
                value={data.rtCode.length > 0 || data.attendanceCode.length > 0 ? '' : data.patientName}
              />
            </Col>
            <Col md={1} style={{ padding: 12, width: 200 }}>
              <div>
                <DocLabel>Período *</DocLabel>
              </div>
              {
                data.rtCode.length > 0 ?
                  <FormInput
                    type='text'
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleChange(event, 'patientName')
                    }
                    placeholder='Período'
                    debounceTimeout={500}
                    disabled={data.rtCode.length > 0}
                    value={data.rtCode.length > 0 ? '' : data.patientName}
                  /> :
                  <CustomDatePicker
                    placeholderText='Período'
                    selectsRange
                    startDate={startDate}
                    endDate={endDate}
                    id='datesRange'
                    dateFormat='dd/MM/yyyy'
                    onChange={(update: [Date | null, Date | null]) => {
                      setDatesRange(update)
                    }}
                    customInput={<ExampleCustomInput />}
                    disabled={data.rtCode.length > 0}
                    locale={ptBR}
                  />
              }


            </Col>
            <Col md={2} style={{ padding: 12 }}>
              <DocLabel>Unidade *</DocLabel>
              <OverlayTrigger placement="top" overlay={renderTooltip('unity')}>
                <div>
                  {data.rtCode.length > 0 ? (
                    <Autocomplete
                      defaultValue=""
                      suggestions={suggestions}
                      getValue={handleGetValue}
                      placeholder="Unidade"
                      currentTarget=""
                      disabled={data.rtCode.length > 0}
                    />
                  ) : (
                    <Autocomplete
                      defaultValue={getDefaultUnity(suggestions, unityId)}
                      suggestions={suggestions}
                      getValue={handleGetValue}
                      placeholder="Unidade"
                      currentTarget=""
                      disabled={data.rtCode.length > 0}
                    />
                  )}
                </div>
              </OverlayTrigger>
            </Col>
            <Col md={1} style={{ padding: 12, width: 160 }}>
              <DocLabel style={{ width: 128 }}>Tipo de Atendimento *</DocLabel>
              <FormDropdownButton
                id='tipoAtendimento'
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChange(event, 'attendanceType')
                }
                title='Tipo de atendimento'
                defaultValue='todos'
                disabled={data.rtCode.length > 0}
              >
                {renderOptions(TIPO_ATENDIMENTO, data.attendanceType)}
              </FormDropdownButton>
            </Col>
            <Col md={1} style={{ padding: 12 }}>
              <DocLabel>Status *</DocLabel>
              <FormDropdownButton
                id='status'
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, 'status')}
                title='Status'
                defaultValue='todos'
                disabled={data.rtCode.length > 0}
              >
                {renderOptions(STATUS, data.status)}
              </FormDropdownButton>
            </Col>
            {/* <Col md={2}>
              <FormDropdownButton
                id='prazo'
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, 'position')}
                title='Prazo'
                defaultValue='0'
              >
                {PRAZO.map(({ value, label }) => (
                  <option key={value} value={value} disabled={!parseInt(value, 10)}>
                    {label}
                  </option>
                ))}
              </FormDropdownButton>
            </Col> */}
            <Col md={2} style={{ display: 'flex', alignItems: 'flex-end', padding: 12 }}>
              <Button
                style={{ width: '100%', height: 32, padding: 0 }}
                variant='primary'
                type='button'
                onClick={() => handleSearch(getPayload(true))}
                disabled={loading || !isValid}
              >
                Pesquisar
              </Button>
            </Col>
            <Col xs={12}>
              <span
                style={{ display: 'inline-block', marginTop: 8, marginLeft: '-12px', fontSize: 12 }}
              >
                * campos obrigatórios
              </span>
            </Col>
          </Row>
          <Row className='table-row'>
            {loading ? (
              'Buscando...'
            ) : (
              <TransfusionRequestTable
                data={rts}
                onSelect={(action, item) =>
                  handleDropDownClick(action, item as unknown as PatientData)
                }
                pages={pages}
                setPage={(val) => {
                  handleSearch(getPayload(true), val)
                }}
                currentPage={currentPage}
              />
            )}
          </Row>
        </Container>
      </Block>
      {/* <Container fluid>
        <Row className='no-padding-x'>
          <Col className='col' md={8}>
            <TransfusionProcessPanel />
          </Col>
          <Col md={4}>
            <TransfusionProcessTime />
          </Col>
        </Row>
      </Container> */}
      <LoginModal
        layoutVariant='stamp-login'
        show={isLoginModalOpen}
        callbackLogin={handleCallbackLogin}
        handleClose={() => setIsLoginModalOpen(false)}
      />
      {rtHistory ? (
        <TransfusionalModal
          show={isModalOpen}
          data={rtHistory}
          handleClose={() => setIsModalOpen(false)}
        />
      ) : null}
      {isTicketModalOpen && selectedRow ? (
        <TypingStampModal
          patientData={selectedRow}
          show={isTicketModalOpen}
          handleClose={() => handleCloseStampingModal()}
        />
      ) : null}
    </>
  )
}
