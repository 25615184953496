import { FC, useId } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { chunkArray, getField } from '../../utils'
import { SubHeader, SubTitle } from '../page/styles'
import { CustomCheckbox } from '../customCheckbox'
import { RtTypeFieldModalStyles } from './styles'


interface RtTypeFieldModalProps {
  data: {
    description: string,
    options: string
  },
  show: boolean
  handleClose: () => void
}

export const RtTypeFieldModal: FC<RtTypeFieldModalProps> = ({
  handleClose,
  data,
  show,
}) => {

  const specialProds = chunkArray(getField('options', data).split(","), 3);

  return (
    <RtTypeFieldModalStyles id="transfusion-reactions" show={show} onHide={handleClose}>
      <Modal.Body>
        <SubHeader style={{ justifyContent: 'center' }}>
          <SubTitle style={{ color: '#0064A9', fontSize: 13, textAlign: 'center' }}>
            Reações à transfusões prévias
          </SubTitle>
        </SubHeader>
        <div>
          <div>
            {specialProds.map((prods) => (
              <Row key={useId()}>
                {prods.map((prod: string) => (
                  <Col md={4} key={prod} style={{ alignItems: "center", display: "flex", padding: "10px 10px 0px" }}>
                    <CustomCheckbox
                      label={prod}
                      disabled
                      checked
                    />
                  </Col>
                ))}
              </Row>
            ))}
          </div>

          <textarea
            disabled
            style={{
              resize: "none",
              width: '100%',
              padding: '5px  10px',
              height: '270px',
              borderRadius: '5px',
              border: '2px solid',
              marginTop: "20px"
            }}
            value={data.description}
          />
        </div>

      </Modal.Body>

    </RtTypeFieldModalStyles>
  )
}
