import { format } from 'date-fns'
import { FC, useEffect, useState } from 'react'
import { Button, Col, Modal, Row, Tabs, Tab } from 'react-bootstrap'
import { DocInput, DocLabel } from '../form/styles'
import { PageSection, SubHeader, SubTitle } from '../page/styles'
import { SubtilesBar, SubtitleLabel } from '../subtitlesBar/styles'
import { CheckingHemocomponentTable } from '../tables/CheckingHemocomponentTable'
import { TimeLine } from '../timeline'
import { TransfusionalModalStyles } from './styles'
import { TransfusionalModalPropTypes } from './types'


const headers = [
  { label: 'Solicitação', value: 'solicitado', field: 'historyTransfusionRequestItemDtos' },
  // { label: 'Parcialmente Autorizado', value: 'parcialmente autorizado', field: 'historyTransfusionRequestItemDtos' },
  { label: 'Autorização', value: 'autorizado', field: 'historyTransfusionRequestItemAutorizationDtos' },
  { label: 'Bolsa Enviada', value: 'bolsa enviada', field: 'bloodComponentOutput' },
  { label: 'Bolsa Recebida', value: 'bolsa recebida', field: 'bloodComponentsRecevedDtoGet' },
  { label: 'Transfusão', value: 'transfusão', field: 'historyBloodComponentAdministratorDto' },
]

const table = [
  [
    { label: 'Hemocomponente', value: 'bloodComponentName' },
    { label: 'Qtde', value: 'quantity' },
    { label: 'UM', value: 'unitMeasurement' },
    { label: 'Médico Solicitante', value: 'doctorName' },
    { label: 'CRM', value: 'doctorCrm' },
    { label: 'Dt/Hr Validação', value: 'dateValidation', type: 'time' }
  ],
  [
    { label: 'Hemocomponente', value: 'bloodComponentName' },
    { label: 'Código Autorização', value: 'authorizationDescription' },
    { label: 'Senha de Autorização', value: 'passwordAuthorization' },
    { label: 'Status', value: 'statusAuthorization', type: 'status' },
    { label: 'Dt/Hr Autorização', value: 'dateAuhotization', type: 'time' },
  ],
  [
    { label: 'Hemocomponente', value: 'hemocomponent' },
    { label: 'N° Bolsa', value: 'bagNumber' },
    { label: 'Abo/rh', value: 'aboRh' },
    { label: 'Qtde', value: 'quantity' },
    { label: 'UM', value: 'unitMeasurement' },
    { label: 'Responsável', value: 'responsible' },
    { label: 'Dt/Hr Saída', value: 'departureDate', type: 'time' }
  ],
  [
    { label: 'Hemocomponente', value: 'bloodComponentName' },
    { label: 'N° Bolsa', value: 'bagNumber' },
    { label: 'Abo/rh', value: 'aboRh' },
    { label: 'Qtde', value: 'quantity' },
    { label: 'UM', value: 'unitMeasurement' },
    { label: 'Responsável Recebimento', value: 'responsable' },
    { label: 'Dt/Hr Recebimento', value: 'dateReceved', type: 'time' },
    { label: 'Status', value: 'status', type: 'status' }],
  [
    { label: 'Hemocomponente', value: 'bloodComponentName' },
    { label: 'N° Bolsa', value: 'bagNumber' },
    { label: 'Abo/rh', value: 'aboRh' },
    { label: 'Qtde', value: 'quantity' },
    { label: 'UM', value: 'unitMeasure' },
    { label: 'Dt/Hr Início', value: 'initialDate', type: 'time' },
    { label: 'Resp. Início', value: 'initialResponsable' },
    { label: 'Dt/Hr Fim', value: 'finishDate', type: 'time' },
    { label: 'Resp. Fim', value: 'finishResponsable' },
    { label: 'Interc.', value: 'finishedWithIntercurrence' },
  ]
];


export const TransfusionalModal: FC<TransfusionalModalPropTypes> = ({
  handleClose,
  show,
  data,
}) => {
  const [steps, setSteps] = useState([])

  const getField = (name) => data[name] || ''

  const getGender = () => (getField('patientSex') === 'F' ? 'Feminino' : 'Masculino')

  const getRegistrationDate = (value) => (
    value ? format(new Date(value?.registrationDate), 'dd/MM/yyyy - HH:mm') || '' : ''
);

  const getAuthStatus = () => {

    if (data.statusRequestDtos) {
      const status = data.statusRequestDtos.filter((item) => item?.statusId >= 4 && item?.statusId <= 7)

      return status[status.length - 1] || ''

    }

    return ''

  }

  const generateSteps = () => {

    const temp = []

    headers.forEach((item) => {
      let timeStampDate = data.statusRequestDtos?.find(
        (status) => status.statusDescription.toLowerCase() === item.value,
      )
      if (item.label === 'Transfusão') {
        timeStampDate = data.statusRequestDtos?.find((status) =>
            status.statusDescription === 'Transfusão Finalizada'
        )
      }
      const authorized = data.historyTransfusionRequestItemAutorizationDtos
          .filter((res) => res.statusAuthorization === 'Autorizado')[0]

      const request = data.historyTransfusionRequestItemAutorizationDtos
          .filter((res) => res.statusAuthorization === 'Solicitação_em_Auditoria')[0]

      if (authorized && request) {
        timeStampDate =
            data.historyTransfusionRequestItemAutorizationDtos[data.historyTransfusionRequestItemAutorizationDtos.length - 1].dateAuhotization;
        temp.push({
          label: 'Parcialmente Autorizado',
          status: item.label === 'Autorização' ? getAuthStatus()?.statusDescription : '',
          timeStamp: getRegistrationDate(timeStampDate)
        })
      } else {
        if (item.label === 'Autorização') {
          timeStampDate = getAuthStatus()
        }
        temp.push({
          label: item.label,
          status: item.label === 'Autorização' ? getAuthStatus()?.statusDescription : '',
          timeStamp: getRegistrationDate(timeStampDate)
        })
      }
    })
    setSteps(temp)
  }

  useEffect(() => {
    generateSteps()
  }, [data])

  const generateTables = (header: { label: string }) => {

    if (header.label === 'Autorização') {
      return (<SubtilesBar >
        <SubtitleLabel>Status:</SubtitleLabel>
        <SubtitleLabel className='late'>Em Auditoria</SubtitleLabel>
        <SubtitleLabel className='done'>Autorizado</SubtitleLabel>
        <SubtitleLabel className='danger'>Negado</SubtitleLabel>
        <SubtitleLabel className='stand-by'>Suspenso</SubtitleLabel>
      </SubtilesBar>)
    }

    if (header.label === 'Bolsa Recebida') {
      return (<SubtilesBar>
        <SubtitleLabel>Legenda:</SubtitleLabel>
        <SubtitleLabel className='stand-by'>Não Recebido</SubtitleLabel>
        <SubtitleLabel className='done'>Recebido</SubtitleLabel>
        <SubtitleLabel className='danger'>Recusado</SubtitleLabel>
      </SubtilesBar>)
    }

    return <div />
  }

  return (
    <TransfusionalModalStyles show={show} onHide={handleClose}>
      <Modal.Body>
        <PageSection>
          <SubHeader>
            <SubTitle>DADOS DO PACIENTE</SubTitle>
            <SubTitle>N° Atend.: {getField('attendanceCode')}</SubTitle>
          </SubHeader>
          <Row>
            <Col className='d-flex flex-dir-col space-between' md={3}>
              <DocLabel>Nome do Paciente:</DocLabel>
              <DocInput disabled type='text' value={getField('patientName')} />
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={3}>
              <DocLabel>Nome do Mãe:</DocLabel>
              <DocInput disabled type='text' value={getField('patientMar')} />
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={1}>
              <DocLabel>Data de Nasc.:</DocLabel>
              {getField('patientBornDate') ? (
                <DocInput
                  disabled
                  type='text'
                  value={format(new Date(getField('patientBornDate')), 'dd/MM/yyyy')}
                />
              ) : (
                <div />
              )}
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={1}>
              <DocLabel>Idade:</DocLabel>
              <DocInput disabled type='text' value={getField('age')} />
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={1}>
              <DocLabel>Sexo:</DocLabel>
              <DocInput disabled type='text' value={getGender()} />
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={1}>
              <DocLabel>CPF:</DocLabel>
              <DocInput disabled type='text' value={getField('patientCpf')} />
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={1}>
              <DocLabel>RG:</DocLabel>
              <DocInput disabled type='text' value={getField('patientRg')} />
            </Col>
          </Row>
          <Row>
            <Col className='d-flex flex-dir-col space-between' md={3}>
              <DocLabel>Unidade:</DocLabel>
              <DocInput disabled type='text' value={getField('unityName')} />
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={3}>
              <DocLabel>Clínica:</DocLabel>
              <DocInput disabled type='text' value='Posto de Atendimento 1111 - POSTO P1A' />
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={2}>
              <DocLabel>Enfermaria/APT°:</DocLabel>
              <DocInput disabled type='text' value='Enfermaria' />
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={1}>
              <DocLabel>Leito:</DocLabel>
              <DocInput disabled type='text' value='205' />
            </Col>
            <Col className='d-flex flex-dir-col space-between' md={1}>
              <DocLabel>Peso:</DocLabel>
              <DocInput disabled type='text' value={getField('patientWeight')} />
            </Col>
          </Row>
        </PageSection>
        <PageSection>
          <TimeLine steps={steps} />
        </PageSection>
        <PageSection>
          <Tabs className='mt-10 tabs-component transfusion-history'>
            {headers.map((header, key) => (
              <Tab
                key={header.value}
                eventKey={header.value}
                title={<p>
                  <span>{header.label}</span>
                </p>}
              >
                <div>
                  {generateTables(header)}
                  <CheckingHemocomponentTable
                      headers={table[key] as any}
                      data={data[header.field].map(item => ({
                        ...item,
                        finishedWithIntercurrence: item.finishedWithIntercurrence ? 'Sim' : 'Não'
                      }))}
                  />
                </div>
              </Tab>
            ))}
          </Tabs>

        </PageSection>
      </Modal.Body>
      <Modal.Footer>
        <Button size='sm' variant='outline-secondary' onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </TransfusionalModalStyles>
  )
}
