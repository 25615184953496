import { ActionsType } from './types'

export const ACTIONS: ActionsType[] = [
  { value: 'rt', label: 'Visualizar RT', on: true, profiles: 'all' },
  { value: 'sadt', label: 'Visualizar Guia SADT', profiles: [] },
  { value: 'termo-consent', label: 'Termo de consentimento', profiles: [] },
  {
    value: 'etiq-tipagem',
    label: 'Gerar Etiqueta para Tipagem',
    on: true,
    profiles: [
      'banco_de_sangue',
      'usuario',
      'enfermeiro',
      'tecnico_em_enfermagem',
      'administrador',
    ],
  },
  {
    value: 'saida-hemocomp',
    label: 'Gerar Saída de Hemocomponente',
    on: true,
    profiles: ['banco_de_sangue', 'usuario', 'administrador'],
  },
  {
    value: 'recibo-hemocomp',
    label: 'Gerar Recebimento de Hemocomponente',
    on: true,
    profiles: ['enfermeiro', 'tecnico_em_enfermagem', 'administrador'],
  },
  { value: 'acomp-transf', label: 'Acompanhamento Transfusional', on: true, profiles: 'all' },
  { value: 'hemovigilancia', label: 'Hemovigilância', profiles: [] },
]
