/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-key */
import { format } from 'date-fns'
import { FC } from 'react'
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import { ABO_TYPES, UM_TYPES } from '../../views/bloodComponent/utils'
import { CustomTooltipBloodComponent } from '../bloodComponentTooltip/recused'
import { TableStyles } from './styles'

interface HemocomponentTableProps {
  headers: any
  data: any
  onSelect: (prop1?: any, prop2?: any) => void
  onSelecteDelete: (prop1?: any, prop2?: any) => void
  onOpenModal: (item: any) => void
  componentList: any
}

function replaceData(dataString: string) {
  if (!dataString) {
    return new Date()
  }

  const data = dataString?.replace(
    /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})$/,
    '$3-$2-$1T$4:$5:00',
  )

  return new Date(data)
}

export const BloodComponentTable: FC<HemocomponentTableProps> = ({
  headers,
  data,
  onSelect,
  onSelecteDelete,
  onOpenModal,
  componentList,
}) => (
  <TableStyles>
    <thead>
      <tr>
        {headers
          .filter((header) => header.label !== 'id')
          .map((item) => (
            <th key={item.value}>{item.label}</th>
          ))}
      </tr>
    </thead>
    <tbody style={{ textOverflow: 'ellipsis' }}>
      {data.map((item, key) => (
        <CustomTooltipBloodComponent isRecused={item.isBloodComponentRefused}>
          <tr
            key={item}
            onClick={() => onOpenModal(item)}
            style={{
              textDecoration: item.isBloodComponentRefused === true ? 'line-through' : '',
              cursor: item.isBloodComponentRefused === true ? 'pointer' : ''
            }}
          >
            <td>
              {item.bloodcomponent}
            </td>
            <td>{item.purseNumber}</td>
            <td>{ABO_TYPES.find((_item) => _item.value === item.abo)?.label}</td>
            <td style={{ textAlign: 'center' }}>{item.temperature}°C</td>
            <td style={{ textAlign: 'center' }}>{item.qty}</td>
            <td>{UM_TYPES.find((_item) => _item.value === item.um)?.label}</td>
            <td style={{ textAlign: 'center' }}>{format(replaceData(item?.validity), 'dd/MM/yyyy - HH:mm')}</td>
            <td>{item.responsable}</td>
            <td style={{ textAlign: 'center' }}>
              {item.departureDate
                ? format(replaceData(item.departureDate), 'dd/MM/yyyy - HH:mm')
                : ''}
            </td>
            <td style={{ textAlign: 'center' }}>
              {!item.isBloodComponentRefused && !item.isBloodComponentReceved ? (
                <button
                  style={{ width: '100%' }}
                  className='table-icon-btn'
                  type='button'
                  onClick={() => onSelect(item, item.id)}
                >
                  <EditIcon />
                </button>
              ) : (
                <div />
              )}
            </td>
            <td style={{ textAlign: 'center' }}>
              {!item.isBloodComponentRefused && !item.isBloodComponentReceved ? (
                <button
                  style={{ width: '100%' }}
                  className='table-icon-btn'
                  type='button'
                  onClick={() => onSelecteDelete(item.databaseId, item.id)}
                >
                  <DeleteIcon />
                </button>
              ) : (
                <div />
              )}
            </td>
          </tr>
        </CustomTooltipBloodComponent>
      ))}
    </tbody>
  </TableStyles>
)
