import { Tooltip } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledTooltip = styled(Tooltip)`
  color: #fff;
  font-size: 12px;
  line-height: 1.5;
  border: none;
  border-radius: 4px;
  padding: 8px;
  text-align: center;
  offset: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0px;
  position: fixed !important;
  margin-left: -72px !important;

  .tooltip-inner {
    max-width: fit-content;
  }

  .tooltip-arrow {
    margin: auto;
  }


  && > div:first-child {
    position: unset !important;
    transform: none !important;
  }

  && > div:first-child::before {
    border-bottom-color: #868686;
    bottom: 15px;
    position: relative !important;
  }

  && > div:last-child {
    background-color: #868686;
    text-align: left;
  }

  && h6 {
    margin-top: 4px;
    font-weight: bold;
    color: #fff;
    font-size: 15px;

  }
`

export const Label = styled.span`
  color: #fff;
  font-size: 13px;
`

export const Info = styled.div`
  margin-bottom: 8px;
`
