import { FC, useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { Autocomplete } from '..'
import { createCodeProceedingAuthorization, updateCodeProceedingAuthorization } from '../../service'
import { DocInput, DocLabel, FormToggleCheck } from '../form/styles'
import { SubTitle } from '../page/styles'
import { ProciduriesTabTable } from '../tables/ProceduriesTabTable'
import { TabsPropTypes } from './types'

export const ProcedureTab: FC<TabsPropTypes> = ({ data, onSuccess, unities }) => {
  const [unitiesSuggestions, setUnitiesSuggestions] = useState([])
  const [status, setStatus] = useState(false)
  const [searchUnit, setSearchUnit] = useState('')
  const [searchComp, setSearchComp] = useState('')
  const [filtered, setFiltered] = useState([])
  const [proceedingCodeAuthorization, setProceedingCodeAuthorization] = useState('')
  const [currentTarget, setCurrentTarget] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [selectedId, setSelectedId] = useState(0)
  const [nameDefaultValue, setNameDefaultValue] = useState('')
  const [values, setValues] = useState<any>(null)
  const [isValid, setIsValid] = useState(false)
  const [proceedingCode, setProceedingCode] = useState('')

  const clear = () => {
    setCurrentTarget('clear')
    setStatus(false)
    setProceedingCodeAuthorization('')
    setProceedingCode('')
    setIsEdit(false)
    setSelectedId(0)
    setNameDefaultValue('')
    setValues(null)
  }

  const handleGetUnityValue = (value: string) => {
    if (!value) {
      clear()
      return
    }
    if (value.includes('-')) {
      const tempId = parseInt(value.split('-')[0], 10)
      if (tempId) {
        const unit = unities.filter((item) => item.seq === tempId)[0]
        setNameDefaultValue(`${unit.seq} - ${unit.name}`)
        if (unit) {
          setValues((cur) => ({
            ...cur,
            unitCode: unit?.id,
            unitName: unit?.name,
            unitSeq: unit?.seq,
          }))
        }
      }
    }
  }

  const onSelect = (id: number, unitCode: number) => {
    const unit = unities.filter((item) => item.seq === unitCode)[0]
    const selected = data.filter((item) => item.id === id)[0]

    if (unit && selected) {
      setIsEdit(true)
      setSelectedId(id)
      setValues({
        ...selected,
        unitCode: unit?.id || 'ND',
        unitName: unit?.name || 'ND',
        unitSeq: unit?.seq || 'ND',
      })
      setProceedingCode(selected.proceedingCode)
      return
    }
    clear()
  }

  const handleStatusChange = () => {
    setStatus((cur) => !cur)
  }

  const getPayload = () => {
    const tempFiltered = data.filter((item) => item.id === selectedId)[0]
    return {
      proceedingStatus: status,
      proceedingCodeAuthorization,
      descriptionProceeding:
        tempFiltered?.descriptionProceeding || values?.descriptionProceeding || '',
      proceedingCode:
        proceedingCode || tempFiltered?.proceedingCode || values?.proceedingCode || '',
      unitCode: values.unitSeq,
      unitName: values.unitName,
    }
  }

  const handleCreate = async () => {
    try {
      await createCodeProceedingAuthorization(getPayload())

      onSuccess()
      clear()
      toast.success('Operação realizada com sucesso!')
    } catch (error) {
      toast.error('Algo deu errado. Tente novamente mais tarde.')
    }
  }

  const handleUpdate = async () => {
    try {
      await updateCodeProceedingAuthorization(selectedId, getPayload())

      onSuccess()
      clear()
      toast.success('Operação realizada com sucesso!')
    } catch (error) {
      toast.error('Algo deu errado. Tente novamente mais tarde.')
    }
  }

  useEffect(() => {
    const tempUnitiesSuggestions = []
    unities.forEach((item) => {
      tempUnitiesSuggestions.push(`${item.seq} - ${item.name}`)
    })

    setUnitiesSuggestions(tempUnitiesSuggestions)
    clear()
  }, [data, unities])

  useEffect(() => {
    const formated = searchUnit.toLowerCase()
    if (!formated) {
      setFiltered(data)
      return
    }

    const temp = data.filter(
      (item) =>
        item.unitCode.toString().includes(formated) ||
        item.unitName.toLowerCase().includes(formated),
    )

    setFiltered(temp)
  }, [searchUnit, data])

  useEffect(() => {
    const formated = searchComp.toLowerCase()
    if (!formated) {
      setFiltered(data)
      return
    }

    const temp = data.filter(
      (item) =>
        item?.descriptionProceeding?.toLowerCase().includes(formated) ||
        item?.proceedingCode?.toString()?.toLowerCase().includes(formated),
    )

    setFiltered(temp)
  }, [searchComp, data])

  useEffect(() => {
    if (
      proceedingCodeAuthorization?.length > 3 &&
      proceedingCodeAuthorization?.length <= 20 &&
      nameDefaultValue &&
      !nameDefaultValue.includes('ND -') &&
      proceedingCode?.length > 3 &&
      proceedingCode?.length <= 20
    ) {
      setIsValid(true)
      return
    }

    setIsValid(false)
  }, [proceedingCodeAuthorization, nameDefaultValue, proceedingCode])

  useEffect(() => {
    if (values && selectedId) {
      setStatus(values.proceedingStatus)
      setProceedingCodeAuthorization(values.proceedingCodeAuthorization)

      if (values.unitName && !values.unitName.includes('ND -')) {
        setNameDefaultValue(`${values.unitSeq} - ${values.unitName}`)
      }
    }
  }, [values, selectedId])

  useEffect(() => {
    setValues((cur) => ({ ...cur, proceedingCode }))
  }, [proceedingCode])

  return (
    <>
      <Row>
        <Col className='d-flex flex-dir-col space-between' xs={2}>
          <DocLabel>Nome ou Código da Unidade:</DocLabel>
          <Autocomplete
            defaultValue={nameDefaultValue}
            suggestions={unitiesSuggestions}
            getValue={handleGetUnityValue}
            placeholder='Digite nome ou código da unidade'
            currentTarget={currentTarget}
            height={25}
          />
        </Col>
        <Col className='d-flex flex-dir-col space-between' xs={3}>
          <DocLabel>Código ou Descrição do Hemocomponente:</DocLabel>
          <DocInput
            onChange={({ target }) => setProceedingCode(target?.value)}
            value={proceedingCode || ''}
            minLength={4}
          />
          {/* <Autocomplete
            defaultValue={descDefaultValue}
            suggestions={descSuggestions}
            getValue={handleGetValue}
            placeholder=''
            currentTarget={currentTarget}
            height={25}
          /> */}
        </Col>
        <Col className='d-flex flex-dir-col space-between' xs={3}>
          <DocLabel>Código Procedimento Autorização:</DocLabel>
          <DocInput
            onChange={({ target }) => setProceedingCodeAuthorization(target?.value)}
            value={proceedingCodeAuthorization}
            minLength={4}
          />
        </Col>
        <Col className='d-flex flex-dir-col space-between' xs={1}>
          <DocLabel>Status:</DocLabel>
          <FormToggleCheck onChange={handleStatusChange} checked={status} label='Ativo' />
        </Col>
        <Col
          xs={1}
          className='d-flex flex-dir-col space-between'
          style={{ justifyContent: 'flex-end' }}
        >
          <Button
            type='button'
            className='w-100'
            onClick={clear}
            variant='outline-secondary'
            size='sm'
            style={{ height: 25, fontSize: 12, padding: 2 }}
          >
            Cancelar
          </Button>
        </Col>
        <Col
          className='d-flex flex-dir-col space-between'
          style={{ justifyContent: 'flex-end' }}
          xs={2}
        >
          <Button
            style={{ height: 25, fontSize: 12 }}
            type='button'
            size='sm'
            variant='primary'
            className='w-100'
            disabled={!isValid}
            onClick={isEdit ? handleUpdate : handleCreate}
          >
            {isEdit ? 'Atualizar' : 'Adicionar'}
          </Button>
        </Col>
      </Row>
      <hr style={{ marginBottom: 20, marginTop: 20 }} />
      <Row>
        <Col xs={5}>
          <SubTitle>UNIDADES</SubTitle>
        </Col>
        <Col xs={3}>
          <DocInput
            style={{ width: '100%' }}
            onChange={({ target }) => setSearchUnit(target.value)}
            placeholder='Pesquisar Unidade...'
          />
        </Col>
        <Col xs={3}>
          <DocInput
            style={{ width: '100%' }}
            onChange={({ target }) => setSearchComp(target.value)}
            placeholder='Pesquisar Hemocomponente...'
          />
        </Col>
      </Row>
      <ProciduriesTabTable
        data={filtered.length || searchComp || searchUnit ? filtered : data}
        onSelect={onSelect}
      />
    </>
  )
}
