import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components'

export const DropDownToggle = styled(Dropdown.Toggle)`
  border: 1px solid #c4c4c4 !important;
  background-color: transparent !important;
  width: 100%;
  padding: 0;
  text-align: left;
  padding-left: 8px;
  box-shadow: none !important;
  color: #4f4f4f !important;
  height: 25px;
  &::after {
    content: none;
  }
  span {
    display: inline-block;
    margin-left: 8px;
  }
  &:hover,
  &:active {
    background-color: transparent;
  }
`
