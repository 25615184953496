import VMasker from 'vanilla-masker'

export const formatAmount = (amount) => VMasker.toMoney(amount)
export const fromAmount = (amount) => amount / 100

export const maskDate = (date) => VMasker.toPattern(VMasker.toNumber(date), 'SS/SS/SSSS')

export const maskPhoneNumber = (phoneNumber) => VMasker.toPattern(phoneNumber, '(SS) SSSSS-SSSS')

export const maskHomePhoneNumber = (phoneNumber) => VMasker.toPattern(phoneNumber, '(SS) SSSS-SSSS')

export const unmaskPhoneNumber = (value) => value.replace(/\(|\)|\+|-|\s*/g, '')

export const unmaskCpfOrCnpj = (value) => value.replace(/\(|\)|\.|\/|-|\s*/g, '')

export const maskRg = (value) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')

export const maskCpf = (value) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')

export const maskCnpj = (value) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{4})/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+$/, '$1')

export const maskCpfOrCnpj = (value) => {
  if (!value) {
    return value
  }

  if (unmaskCpfOrCnpj(value).length <= 11) {
    return maskCpf(value)
  }

  return maskCnpj(value)
}
