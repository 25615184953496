import logohorizontal from '../../assets/icons/logohorizontal.svg'

export const Feedback = () => {

  const status = window.location.search === '' ? true : window.location.search.split("=")[1] === "true"

  return (<div className="container-sm d-flex justify-content-center align-items-center vh-100">
    <div className="d-flex align-content-center justify-content-center bd-highlight">
      <div className="pt-3 me-4  bd-highlight "><img src={logohorizontal} alt="" /></div>
      <div className="bd-highlight"><h1>Prescrição {status ? "concluída" : "cancelada"} com sucesso!</h1>
        <p>Por gentileza fechar a guia (aba) atual do navegador para dar continuidade do processo no PEP.</p></div>
    </div>
  </div>)


}