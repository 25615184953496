import styled from 'styled-components'

export const AppBadge = styled.button`
  border: 1px solid #b7b7b7;
  padding: 2px 4px;
  background-color: white;
  color: #4f4f4f;
  margin-right: 4px;
  border-radius: 3px;
  font-size: 10px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
`

export const AppBadgeWrapper = styled.div`
  padding: 2px 4px;
  border: 1px solid #c4c4c4;
  min-height: 25px;
  display: flex;
  border-radius: 5px;
  min-width: 140px;
  overflow: auto;
  flex-wrap: wrap;
  width: 100%;
`
