import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components'

export const DropdownStyles = styled(Dropdown)`
  &.show {
    .dropdown-toggle {
      background-color: transparent;
      border: none;
      outline: none;
      &:focus {
        box-shadow: none;
      }
    }
  }

  .dropdown {
    &-toggle {
      width: 100%;
      height: 20px;
      border-radius: 0px;
      background-color: transparent;
      border: none;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      &:focus {
        box-shadow: none;
      }
      &::after {
        display: none;
      }
    }

    &-menu {
      background: rgba(134, 134, 134, 0.95);
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
    }

    &-item {
      font-size: 12px;
      font-weight: bold;
      color: white;
      &:hover {
        background-color: unset;
        text-decoration: underline;
      }
    }
  }
`
