import { CustomCheckboxStyles } from './styles'

export const CustomCheckbox = ({
  label,
  checked,
  disabled,
}: {
  label: string
  checked: boolean
  disabled: boolean
}) => (
  <CustomCheckboxStyles className='container'>
    <span>{label}</span>
    <input name={label} disabled={disabled} type='checkbox' checked={checked} />
    <span className='checkmark' />
  </CustomCheckboxStyles>
)
