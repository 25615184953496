import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { SubHeader, SubTitle } from '../../page/styles'
import { CancelTransfusionalModalStyles } from './styles'

export const RecusedJustificationModal: FC<any> = ({
  handleClose,
  justification,
  show,
}) => (
  <CancelTransfusionalModalStyles show={show} onHide={handleClose}>
    <Modal.Body>
      <SubHeader style={{ justifyContent: 'center' }}>
        <SubTitle style={{ color: '#0064A9', fontSize: 13, textAlign: 'center' }}>
          Justificativa da Recusa
        </SubTitle>
      </SubHeader>
      <div>
        <textarea
          disabled
          style={{
            resize: "none",
            width: '100%',
            padding: '5px  10px',
            height: '270px',
            borderRadius: '5px',
            border: '2px solid'
          }}
          value={justification}
        />
      </div>

    </Modal.Body>

  </CancelTransfusionalModalStyles>
)
