import styled from 'styled-components'

export const SubBlockStyles = styled.div`
  background-color: white;
  padding: 20px 20px;
  border: 2px solid #868686;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;


  &.h-100 {
    height: 100%;
  }

  .create-user-page,
  .list-user-page {
    .dropdown,
    .dropdown-toggle {
      height: 25px;
    }
  }
`

export const SubBlockTitle = styled.h4`
  color: #4f4f4f;
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: bold;
  width: auto;
`
