import { ChangeEvent, FC, useState } from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import { SubHeader, SubTitle } from '../page/styles'
import { CancelTransfusionalModalStyles } from './styles'
import { DocLabel, FormTextArea } from '../form/styles'

export const InsertJustificationModal: FC<any> = ({
  handleClose,
  handleConfirm,
  show,
}) => {

  const [justification, setJustification] = useState("")

  function handleChangeTextArea(e: ChangeEvent<HTMLTextAreaElement>) {
    setJustification(e.target.value)
  }

  function handleSubmit() {
    setJustification("")
    return handleConfirm(justification)

  }

  return (
    <CancelTransfusionalModalStyles show={show} onHide={handleClose}>
      <Modal.Body>
        <SubHeader style={{ justifyContent: 'center' }}>
          <SubTitle style={{ color: '#0064A9', fontSize: 13, textAlign: 'center' }}>
            Informe a justificativa para a exclusão do hemocomponente.
          </SubTitle>
        </SubHeader>
        <Container>
          <Col>
            <Row className='no-padding-x'>
              <Col>
                <DocLabel style={{ marginBottom: 5, fontSize: 12 }}>Justificativa:</DocLabel>
                <FormTextArea
                  id="textarea-input"
                  autoComplete="off"
                  style={{ background: '#fff', transition: '0.3s' }}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) => handleChangeTextArea(event)}
                  height={70}
                  maxLength={200}
                  disabled={false}
                  value={justification}
                />
              </Col>
            </Row>
          </Col>
        </Container>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button size='sm' variant='outline-secondary' onClick={handleClose}>
          Fechar
        </Button>
        <Button
          size='sm'
          variant='primary'
          disabled={justification?.length < 5}
          onClick={() => handleSubmit()}
        >
          Enviar justificativa
        </Button>
      </Modal.Footer>
    </CancelTransfusionalModalStyles>
  )
}
