/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react'
import { validateToken } from '../../service'
import { AuthContextProps, AuthContextTypes, AuthTypes } from './types'

const AuthContext = createContext<AuthContextTypes>({
  host: null,
  setHost: () => null,
  auth: null,
  setAuth: () => null,
  appKey: '',
  setAppKey: () => null,
  token: '',
  setTokenInfo: () => null,
  acessos: null,
  tokenInfo: null,
  clearAuth: () => null,
  suggestions: [],
  profile: '',
})

export const useAuthContext = () => useContext(AuthContext)

export const appProfiles = [
  { label: 'Administração', value: '7' },
  { label: 'Banco de sangue', value: '1' },
  { label: 'Enfermagem', value: '2' },
  { label: 'Médico', value: '3' },
  { label: 'Técnico em Enfermagem', value: '6' },
]

export const AuthContextProvider: FC<AuthContextProps> = ({ children }) => {
  const [auth, setAuth] = useState<AuthTypes | null>(null)
  const [host, setHost] = useState<string>(sessionStorage.getItem('host'))
  const [appKey, setAppKey] = useState('')
  const [token, setToken] = useState('')
  const [tokenInfo, setTokenInfo] = useState<any>(null)
  const [acessos, setAcessos] = useState<any>(null)
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [profile, setProfile] = useState('')

  const doValidateToken = async () => {
    try {
      const { data } = await validateToken("", host)
      setTokenInfo(JSON.parse(data.content))
    } catch (error) {
      console.error(error)
    }
  }

  const clearAuth = () => {
    setToken('')
    setTokenInfo(null)
    setAcessos(null)
    sessionStorage.setItem('sgbs_token', '')
    sessionStorage.setItem('sgbs_appkey', '')
    sessionStorage.removeItem('dashboard_filter')
    sessionStorage.removeItem('bloodComponents')
    sessionStorage.removeItem('host')
  }

  useEffect(() => {
    setToken(auth?.token || '')
    setHost(sessionStorage.getItem('host') || '')
  }, [auth])

  useEffect(() => {
    setProfile(tokenInfo?.paperType?.toLowerCase() || '')
    setAcessos(tokenInfo?.access[0])
  }, [tokenInfo])

  useEffect(() => {
    const tempSuggestions = []

    acessos?.operator?.branches
      .sort((a, b) => a.branch.code.localeCompare(b.branch.code))
      .map((item) => {
        tempSuggestions.push(`${item.branch.code} - ${item.branch.description}`)
        return {
          ...item,
          value: item.branch.code,
          label: `${item.branch.code} - ${item.branch.description}`,
        }
      })

    setSuggestions(tempSuggestions)
  }, [acessos])

  useEffect(() => {
    if (token && appKey) {
      sessionStorage.setItem('sgbs_token', token)
      sessionStorage.setItem('sgbs_appkey', appKey)
    }
  }, [token, appKey])

  useEffect(() => {
    if (appKey && token) {
      doValidateToken()
    }
  }, [appKey, token])

  const removeSearchCache = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.key === 'r') {
      sessionStorage.removeItem('dashboard_filter')
      sessionStorage.removeItem('bloodComponents')
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', removeSearchCache)
    return () => {
      window.removeEventListener('keydown', removeSearchCache)
    }
  }, [])

  const authValues = useMemo(
    () => ({
      host,
      setHost,
      auth,
      setAuth,
      appKey,
      setAppKey,
      token,
      setTokenInfo,
      acessos,
      tokenInfo,
      clearAuth,
      suggestions,
      profile,
    }),
    [host, setHost, setAuth, auth, setAppKey, appKey, token, acessos, tokenInfo, suggestions],
  )

  return <AuthContext.Provider value={authValues}>{children}</AuthContext.Provider>
}
