import { FC, useId } from 'react'
import { Col, Row } from 'react-bootstrap'
import { getField } from '../../utils'
import { CustomCheckbox } from '../customCheckbox'
import { DocTextArea } from '../form/styles'
import { SubTitleSmall } from '../page/styles'
import { chunkArray } from '../../utils'

export const TabItemData: FC<{ data: any }> = ({ data }) => {

  const specialProds = chunkArray(getField('specialProced', data).split(","), 3);

  return (
    <Row className='reset-row tab-row'>
      <Col md={6}>
        <Row>
          <Row>
            <Col md={12}>
              <SubTitleSmall>{`${getField('bloodComponentName', data)}`}</SubTitleSmall>
            </Col>
            <Col md={4}>
              <SubTitleSmall>Qtd: {`${getField('amountDoseDrug', data)}`}</SubTitleSmall>
            </Col>
            <Col md={8}>
              <SubTitleSmall>
                Unid. Med.:{' '}
                {`${getField('unitMeasure', data)}`}
              </SubTitleSmall>
            </Col>
          </Row>
          <Col md={12}>
            <SubTitleSmall>Procedimentos Especiais:</SubTitleSmall>
          </Col>
          {specialProds.map((prods) => (
            <Row key={useId()}>
              {prods.map((prod) => (
                <Col md={3} key={prod}>
                  <CustomCheckbox
                    label={prod}
                    disabled
                    checked
                  />
                </Col>
              ))}
            </Row>
          ))}

        </Row>
        <SubTitleSmall>Justificativa:</SubTitleSmall>
        <DocTextArea
          as='textarea'
          placeholder=''
          value={getField('specialProcedJustDescription', data)}
          disabled
          name='specialProcedJustDescription'
        />
        <SubTitleSmall>Informações Adicionais:</SubTitleSmall>
        <DocTextArea
          as='textarea'
          placeholder=''
          value={getField('drugInformation', data)}
          disabled
          name='drugInformation'
        />
      </Col>
      <Col md={6}>
        <SubTitleSmall>Indicação da Transfusão:</SubTitleSmall>
        <DocTextArea
          as='textarea'
          placeholder=''
          value={getField('useIndicationDescription', data).split(',').join(', ')}
          disabled
          name='useIndicationDescription'
        />
        <SubTitleSmall>Contraindicações:</SubTitleSmall>
        <DocTextArea
          as='textarea'
          className='hpx-60'
          placeholder=''
          value={getField('useContraIndicationDescription', data).split(',').join('. ')}
          disabled
          name='useContraIndicationDescription'
        />
        <SubTitleSmall>Justificativa da Contraindicação:</SubTitleSmall>
        <DocTextArea
          as='textarea'
          className='hpx-60'
          placeholder=''
          disabled
          value={getField('justContraIndicationDescription', data)}
          name='justContraIndicationDescription'
        />
      </Col>
    </Row>
  )
}
