import { toast } from 'react-toastify'

export const DASHBOARD_TABLE_HEADERS = [
  { label: 'Solic.', value: 'statusRequest' },
  { label: 'Autor.', value: 'authorization' },
  { label: 'Tipagem', value: 'typing' },
  { label: 'Bolsa Env.', value: 'bag-sent' },
  { label: 'Bolsa Rec.', value: 'bag-receipt' },
  { label: 'Transf.', value: '' },
  { label: 'N° RT', value: 'id' },
  { label: 'Nº Atend.', value: 'attendanceCode' },
  { label: 'Paciente', value: 'patientName' },
  { label: 'Tipo de Atend.', value: 'attendanceType' },
  { label: 'Modalidade Transf.', value: 'modalityTransfName' },
  { label: 'Unidade', value: 'unityName' },
  { label: 'Posto', value: 'station' },
  { label: 'Ações', value: 'actions' },
]

export const INITIAL_VALUES = {
  requestId: '',
  attendanceCode: '',
  rtCode: '',
  patientName: '',
  unityId: '001',
  attendanceType: 'todos',
  status: 'todos',
  position: '0',
  initialDate: '',
  finalDate: '',
}

export const TIPO_ATENDIMENTO = [
  { value: 'todos', label: 'Todos' },
  { value: 'CONSULTA CLINICA', label: 'Consulta clínica' },
  { value: 'INTERNACAO', label: 'Internação' },
]

export const STATUS = [
  { value: 'todos', label: 'Todos' },
  { value: 'cancelado', label: 'Cancelado' },
  { value: 'pendente', label: 'Pendente' },
  { value: 'concluido', label: 'Concluído' },
]

export const PRAZO = [
  { value: '0', label: 'Prazo' },
  { value: '1', label: 'Dentro do prazo' },
  { value: '2', label: 'Fora do prazo' },
]

export const validateAction = (item) => {
  const isValidatedOrAuthorized = item.statusRequestDto.find(
    (obj) => obj.statusDescription.toLowerCase() === 'validado',
  )

  if (!isValidatedOrAuthorized) {
    toast.info(
      'A tipagem sanguínea só poderá ser realizada se a RT estiver com status Validada ou Autorizada.',
    )

    return false
  }

  return true
}

export const getPages = (total: number) => {
  const temp = [] as number[]
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < total; index++) {
    temp.push(index)
  }
  return temp
}

export const handleRefresh = (event: { keyCode: number }) => {
  if (event.keyCode === 116) {
    sessionStorage.removeItem('dashboard_filter')
  }
}

export const getDefaultUnity = (list: string[], id): string =>
  list.find((item) => item.includes(id || '')) || ''
