import { ChangeEvent, useEffect, useState } from 'react'
import { Badge, Button, Col, ProgressBar, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { AppSelect, Block } from '../../components'
import { DocInput, DocLabel } from '../../components/form/styles'
import { PageHeader, PageSection, PageTitle } from '../../components/page/styles'

type Profile = {
  label: string;
  value: string
}

export const CreateEditUserView = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const profiles = []

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordStrengh, setPasswordStrengh] = useState(0)
  const [userStatus, setUserStatus] = useState('')
  const [addedProfiles, setAddedProfiles] = useState<Profile[]>([])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleUserStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserStatus(event.target.value)
  }

  const addProfile = (event: ChangeEvent<HTMLInputElement>) => {
    setAddedProfiles((cur) => [...cur, { label: event.target.value, value: event.target.value }])
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const removeProfile = (item: Profile) => {
    setAddedProfiles((cur) => [...cur.filter(curItem => curItem.value !== item.value)])

  }

  const checkPasswordStrengh = () => {
    if (passwordStrengh < 40) return 'danger'
    if (passwordStrengh < 70) return 'warning'
    return 'success'
  }

  useEffect(() => {
    if (password.length >= 8 && /^(?=.*\d)(?=.*[a-z])[a-z\d]{2,}$/i.test(password)) {
      setPasswordStrengh(100)
      return
    }
    if (!password.length) {
      setPasswordStrengh(0)
      return
    }

    if (password.length < 8) {
      setPasswordStrengh(30)
      return
    }

    if (password.length < 10) {
      setPasswordStrengh(40)
      return
    }

    if (password.length < 12) {
      setPasswordStrengh(50)
      return
    }

    if (password.length < 14) {
      setPasswordStrengh(60)
      return
    }

    setPasswordStrengh(69)
  }, [password])

  return (
    <Block className='create-user-page'>
      <PageHeader>
        <PageTitle>{id ? 'EDITAR USUÁRIO' : 'CRIAR NOVO USUÁRIO'}</PageTitle>
      </PageHeader>
      <PageSection>
        <Row className='mb-10'>
          <Col className='d-flex flex-dir-col space-between' xs={12}>
            <DocLabel htmlFor='name'>Nome do operador:</DocLabel>
            <DocInput
              type='text'
              className='bold-text borderless'
              value='ROSA OLINDAS DAS CALDAS OLIVEIRA'
              name='name'
            />
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col className='d-flex flex-dir-col space-between' md={2}>
            <DocLabel htmlFor='user-code'>Código do usuário:</DocLabel>
            <DocInput
              type='text'
              disabled={Boolean(id)}
              className='bold-text'
              value='123456'
              name='user-code'
            />
          </Col>
          <Col className='d-flex flex-dir-col space-between' md={4}>
            <DocLabel htmlFor='user-name'>Nome do usuário:</DocLabel>
            <DocInput type='text' className='bold-text' value='Teste' name='user-name' />
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col className='d-flex flex-dir-col space-between' md={2}>
            <DocLabel htmlFor='password'>Senha:</DocLabel>
            <DocInput
              type='password'
              name='password'
              onChange={({ target }) => setPassword(target.value)}
            />
          </Col>
          <Col className='d-flex flex-dir-col space-between' md={2}>
            <DocLabel htmlFor='password-confirm'>Confirmação Senha:</DocLabel>
            <DocInput
              type='password'
              name='password-confirm'
              onChange={({ target }) => setConfirmPassword(target.value)}
            />
          </Col>
          <Col className='d-flex flex-dir-col space-between' md={2}>
            <DocLabel htmlFor='token'>Token:</DocLabel>
            <DocInput type='text' className='bold-text' name='token' />
          </Col>
          <Col className='d-flex flex-dir-col h-flex-end' md={1}>
            <DocLabel htmlFor='status'>Status:</DocLabel>
            <AppSelect
              name='status'
              value={userStatus}
              placeholderText='Status'
              options={[
                { label: 'Ativo', value: 'active' },
                { label: 'Inativo', value: 'inactive' },
              ]}
              onChange={handleUserStatusChange}
            />
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col>
            <p className='subtitle-text'>Senha entre 8 a 14 caracteres, alfanumérica.</p>
            <ProgressBar
              style={{ width: 100 }}
              variant={checkPasswordStrengh()}
              now={passwordStrengh}
            />
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col md={2}>
            <DocLabel htmlFor='token'>Perfís:</DocLabel>
            <AppSelect placeholderText='Perfís' options={profiles} onChange={addProfile} />
          </Col>
          <Col
            style={{
              display: 'inline-flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
            }}
          >
            <div>
              {addedProfiles.map((item) => (
                <Badge
                  style={{ cursor: 'pointer', marginRight: 2 }}
                  pill
                  bg='secondary'
                  key={item.value}
                  onClick={() => removeProfile(item)}
                >
                  {item.label}
                </Badge>
              ))}
            </div>

            <p className='subtitle-text'>Clique para remover perfil.</p>
          </Col>
        </Row>
      </PageSection>
      <Row className='h-flex-end'>
        <Col md={2}>
          <Button
            type='button'
            className='w-100'
            onClick={() => navigate(-1)}
            variant='outline-secondary'
            size='sm'
          >
            Voltar
          </Button>
        </Col>
        <Col md={2}>
          <Button
            disabled={passwordStrengh < 100 || password !== confirmPassword}
            type='button'
            size='sm'
            variant='primary'
            className='w-100'
          >
            Salvar
          </Button>
        </Col>
      </Row>
    </Block>
  )
}
