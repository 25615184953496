import axios from 'axios'
import { toast } from 'react-toastify'
import { getUrlData } from '../utils'
import {
  AutorizationUnitsPayloadTypes,
  CodeProceedingAuthorizationPayloadTypes,
  FilterTypes,
  LoginTypes,
  ReceivedBloodComponentTypes,
  RefusedBloodComponentTypes,
} from './types'
/* eslint-disable camelcase */

const getRequestAuth = () => ({
  sgbs_token: sessionStorage.getItem('sgbs_token') || '',
  sgbs_appkey: sessionStorage.getItem('sgbs_appkey') || '',
  baseAuth: sessionStorage.getItem('host') || '',
})

export const service = (appKey?: string, token?: string) => {
  const instance = axios.create({
    baseURL:
      process.env.REACT_APP_BFF_API ||
      'https://apimgmt-dev-001.azure-api.net/assistance/blood-bank/web/api/v1/',
    headers: {
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
      Origintype: getUrlData().OriginType ? getUrlData().OriginType : 'bloodbank',
      Authorization:
        sessionStorage.getItem('SAMtoken') ||
        getUrlData().token ||
        `Bearer ${token || getRequestAuth().sgbs_token}`,
      originAuth: getUrlData().Origin,
      baseAuth: getUrlData().baseAuth || getRequestAuth().baseAuth || '',
      'X-Hap-Application-Key': appKey || getRequestAuth().sgbs_appkey,
    },
  })


  instance.interceptors.response.use(
    (response) => response,
    (error) => {

      if (error?.response?.data?.notifications?.length) {
        error.response?.data?.notifications.map((notificationError: Error) => (
          toast.error(notificationError.message)
        ))

        return Promise.resolve();
      }

      return Promise.reject(error);
    },
  )

  return instance

}

export const serviceLegacy = () =>
  axios.create({
    baseURL:
      process.env.REACT_APP_LEGACY_API ||
      'https://apimgmt-dev-001.azure-api.net/assistance/blood-bank/identity/api/v1/',
    headers: {
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY_LEGACY,
      baseAuth: getUrlData().baseAuth || getRequestAuth().baseAuth || '',
      Origintype: 'bloodbank'
    },
  })

service().interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
)

export const getTransfutionRequest = async (code: string | any) =>
  service().get(`transfusions-requests/${code}`)

export const getTransfusionRequestTyping = async (id: string | any, responsibleTyping: string) =>
  service().post(`transfusions-requests/typing`, { transfusionRequestId: id, responsibleTyping })

export const cancelTransfutionRequest = async (id: string, justify: string, linkCancel: string) =>
  service().put(`transfusions-requests/${id}/cancelation`, { justify, linkCancel })

export const postUrlBiometric = async (doctorLoginName: string | any, baseAuth: string | any) =>
  serviceLegacy().post('/authentications/biometry/url', {
    doctorLoginName,
    baseAuth,
  })

export const bloodComponentOutput = async ({ bloodComponentOutputs: components, aboRh }: any) =>
  service().post(`blood-components-outputs`,
    {
      bloodComponentOutputs: components,
      aboRh
    })

export const editBloodComponentOutput = async ({ bloodComponentOutput: components }: any) =>
  service().put(`blood-components-outputs`, components)

export type DeleteBloodComponentOutput = {
  id: string;
  justification: string;
  responsable: string

}

export const deletebloodComponentOutput = async (component: DeleteBloodComponentOutput) =>
  service().delete(`blood-components-outputs/${component.id}`, {
    data: {
      deleteBloodComponentOutputDto: {
        "justification": component.justification,
        "responsable": component.responsable
      }
    }
  })

export const getBloodComponentOutputById = async (requestTransfusionId: number) =>
  service().get(`blood-components-outputs/${requestTransfusionId}`)

export const getBloodComponentOutputJustifications = async (requestTransfusionId: number) =>
  service().get(`blood-components-outputs-justifications/${requestTransfusionId}`)

export const validateBiometric = async ({ baseAuth, base64, token }) =>
  serviceLegacy().post(
    '/authentications/biometry/validation',
    {
      baseAuth,
      base64,
    },
    {
      headers: {
        token,
      },
    },
  )

export const validateRequisition = async (id: string, linkValidate) =>
  service().post(`transfusions-requests/${id}/validation`, { linkValidate })

export const login = async ({ profileCode, ...rest }: LoginTypes) =>
  serviceLegacy().post(
    '/authentications/login',
    {
      ...rest,
      // trocarSenha: false,
      profileCode: parseInt(profileCode, 10),
    },
    {
      headers: {
        'X-Hap-Application-Key': getRequestAuth().sgbs_appkey,
      },
    },
  )

export const validateToken = async (token, baseAuth?: string) =>
  serviceLegacy().post(
    '/authentications/token/validation',
    {},
    {
      headers: {
        Authorization: `Bearer ${(token || "") || getRequestAuth().sgbs_token}`,
        baseAuth
      },
    },
  )

export const getDashboardData = async (
  {
    attendanceCode,
    attendanceType,
    initialDate,
    finalDate,
    patientName,
    position,
    unityId,
    status,
    rtCode
  }: FilterTypes,
  appKey = '',
  token = '',
) => service(appKey, token).get(
  `dashboard?attendanceCode=${attendanceCode}&patientName=${patientName}&initialDate=${initialDate}&
finalDate=${finalDate}&
unityId=${unityId}&attendanceType=${attendanceType}&status=${status}&position=${position}&transfusionrequestId=${rtCode}`,
)

export const getAllProfiles = async () => service().get('profiles-hapvida')
export const getProfile = async (id: string) => service().get(`profiles/${id}`)

export const getAllCustomProfiles = async () => service().get('profiles')

export const createProfile = async (
  description: string,
  statusFlag: boolean,
  profilesHap: { profileCode: number; profileName: string }[],
) =>
  service().post('profiles', {
    profile: {
      description,
      statusFlag,
    },
    profileHapvida: profilesHap,
  })


export const updateProfile = async (
  id: string,
  description: string,
  statusFlag: boolean,
  profilesHap: { profileCode: number; profileName: string }[],
) =>
  service().put(`profiles/${id}`, {
    profile: {
      description,
      statusFlag,
    },
    profileHapvida: profilesHap,
  })

export const getAuthorizationUnitsData = async () => service().get('authorization-units')

export const getAuthorizationUnits = async () => service().get('authorization-units/unitys')
export const getUnits = async () => service().get('unitys')
export const createAuthorizationUnit = async (payload: AutorizationUnitsPayloadTypes) =>
  service().post('authorization-units', {
    ...payload,
  })

export const updateAuthorizationUnit = async (id: number, payload: AutorizationUnitsPayloadTypes) =>
  service().put(`authorization-units/${id}`, {
    ...payload,
  })

export const getCodeProceedingsAuthorization = async () =>
  service().get('codes-proceedings-authorizations')

export const createCodeProceedingAuthorization = async (
  payload: CodeProceedingAuthorizationPayloadTypes,
) => service().post('codes-proceedings-authorizations', { ...payload })

export const updateCodeProceedingAuthorization = async (
  id: number,
  payload: CodeProceedingAuthorizationPayloadTypes,
) => service().put(`codes-proceedings-authorizations/${id}`, { ...payload })

export const getReceivedBloodComponents = async (id: string) =>
  service().get(`blood-components-receveds/${id}`)

export const receiveBloodComponent = async (payload: ReceivedBloodComponentTypes) =>
  service().post('blood-components-receveds', payload)

export const refuseBloodComponent = async (payload: RefusedBloodComponentTypes) =>
  service().post('blood-components-refuseds', payload)

export const getTransfusionHistory = (id: number) => service().get(`transfusions-history/${id}`)
