/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Option } from './styles'

interface StyledOptionProps {
  value: any;
  children: any;
  disabled: boolean;
}

const StyledOption: React.FC<StyledOptionProps> = ({ value, children, disabled }) => (
  <Option style={{ color: '#ff6666', display: disabled ? 'none' : '' }} value={value} >{children}</Option>
)

export default StyledOption