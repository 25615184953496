import { FC } from 'react'
import { AppSelectStypes } from './styles'
import { AppSelectPropTypes } from './types'

export const AppSelect: FC<AppSelectPropTypes> = ({
  options,
  onChange,
  name = '',
  disabled,
  defaultValue,
  value,
}) => (
  <AppSelectStypes
    disabled={disabled}
    aria-label='Default select example'
    name={name}
    onChange={onChange}
    value={value}
  >
    {options.map((item: { label: string; value: string; disabled?: boolean }) => (
      <option
        key={item.value}
        value={item.value}
        disabled={item.disabled}
        defaultValue={defaultValue}
        selected={item.disabled}
      >
        {item.label}
      </option>
    ))}
  </AppSelectStypes>
)
