/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-duplicates */
import React, { FC } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { differenceInYears, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { ReactComponent as HapLogo } from "../../assets/icons/logo-hapvida.svg"
import { AssignatureLabel, CheckContainer, FieldRow, Header, HeaderText, Layout, PdfBox, Section, SignatureContainer } from './styles'
import { PatientData } from './types'

interface CustomPDFProps {
  data: PatientData
}

export const CustomPDF: FC<CustomPDFProps> = ({ data }) => {

  const date = new Date() // ou uma data específica
  const yrsOld = differenceInYears(new Date(), new Date(data.patientBornDate))
  const dataPorExtenso = format(date, "dd çç MMMM çç yyyy", { locale: ptBR }).replace(/çç/g, 'de')

  return (
    <Layout>
      <Section id="divToPrint1">
        <Header className="header">
          <Col>
            <HapLogo />
          </Col>
          <Col className="text">
            <HeaderText>TERMO DE CONSENTIMENTO INFORMADO PARA HEMOTRANSFUSÃO</HeaderText>
          </Col>
        </Header>
        <PdfBox className='box b'>
          <FieldRow>
            <div>Nome: {data?.patientName}</div>
          </FieldRow>
          <FieldRow>
            <div>Idade: {yrsOld}</div>
            <div>Identidade N°: {data?.patientRg}</div>
            <div>Órgão expedidor: {data?.patientUf}</div>
            <div>N° do Prontuário: {data?.hospitalProtuary}</div>
          </FieldRow>

          <div>OBS: NO CASO DE IMPOSSIBILIDADE DO PACIENTE ASSINAR, PREENCHER OS DADOS DO REPRESENTANTE OU RESPONSÁVEL LEGAL.</div>
          <div>REPRESENTANTE / RESPONSÁVEL LEGAL:</div>

          <FieldRow>
            <div style={{ display: "flex" }} >Nome:<AssignatureLabel width="730px" /></div>
          </FieldRow>
          <FieldRow>
            <div style={{ display: "flex" }} >Idade:<AssignatureLabel width="50px" /></div>
            <div style={{ display: "flex" }} >Grau de Parentesco:<AssignatureLabel width="140px" /></div>
            <div style={{ display: "flex" }} >Identidade N°:<AssignatureLabel width="200px" /></div>
            <div style={{ display: "flex" }} >Órgão expedidor:<AssignatureLabel width="60px" /></div>
          </FieldRow>
        </PdfBox>
        <PdfBox>
          <p>
            Por este instrumento particular o(a) paciente ou seu responsável (ACIMA CITADO), declara, para todos os
            fins legais, especialmente do disposto no artigo 39, VI, da Lei 8.078/90 que dá plena autorização ao (à)
            médico(a) <strong>{data.doctorName}</strong>, CRM  n° <strong>{data.doctorCrmNumber}</strong> para proceder <strong>“ TRANSFUSÃO DE HEMOCOMPONENTES E /OU
              HEMODERIVADOS”</strong> necessárias ao tratamento do seu estado de saúde. Declara, outrossim, que o
            referido(a) médico(a), após a apresentação de métodos alternativos, sugeriu o tratamento anteriormente
            citado, prestando informações detalhadas sobre o diagnóstico e sobre os procedimentos a serem adotados no
            tratamento sugerido e ora autorizado, especialmente as que se seguem:
            Estou ciente de que as transfusões podem causar reações imprevisíveis durante ou imediatamente após sua
            realização, tais como febre, calafrio, reações alérgicas, náuseas, hematúria e, mais raramente, problemas
            pulmonares ou cardíacos. Fui informado que todos os cuidados disponíveis na instituição foram tomados
            para se evitar ao máximo estas reações. As reações mais frequentes são sintomas leves e facilmente
            controlados na maioria das vezes e, raramente, podem levar a risco de morte. Nessa ocasião, serei avaliado
            e acompanhado pelo médico plantonista.
            Fui informado de que o fornecimento de hemocompeonentes é realizada pelo Instituto XXX, que cumpre as
            Normas Técnicas do Ministério da Saúde, Portaria MS no 2712, de 12.11.2013. Dessa forma estou ciente
            que, apesar da seleção dos doadores e dos testes laboratoriais previstos em lei, como, hepatite B e C, HIV,
            Chagas, Sífilis, HTLV e moleculares para HIV e Hepatite C, existe um risco, muito pequeno, de adquirir
            alguma dessas doenças infecciosas após a transfusão de sangue e/ou hemococomponente.
          </p>
          <CheckContainer>
            <p>
              <Form.Check
                type="checkbox"
                id={`default-${1}`}
                label="ACEITO receber transfusões de sangue e/ou seus componentes/hemoderivados."
              />
            </p>
            <p>
              <Form.Check
                type="checkbox"
                id={`default-${1}`}
                label="NÃO ACEITO receber transfusões de sangue e/ou seus componentes e declaro estar ciente dos riscos
              decorrentes desta decisão."
              />
            </p>
          </CheckContainer>
          <SignatureContainer>
            <Col>
              <div>{dataPorExtenso}</div>
            </Col>
            <Col>
              <div><AssignatureLabel width="500px" /></div>
              <div style={{ textAlign: 'center' }}>(Paciente) ou (Responsável / Representante Legal)</div>
            </Col>
          </SignatureContainer>
        </PdfBox>
      </Section>

      <Section id="divToPrint2">
        <Header className="header">
          <Col>
            <HapLogo />
          </Col>
          <Col className="text">
            <HeaderText>TERMO DE CONSENTIMENTO INFORMADO PARA HEMOTRANSFUSÃO</HeaderText>
          </Col>
        </Header>
        <PdfBox>
          <p>
            <strong>DEVE SER PREENCHIDO PELO MÉDICO</strong>
          </p>
          <p>
            Prestei todas as informações necessárias ao paciente acima identificado e/ou seu responsável, sobre
            os benefícios, riscos e alternativas, tendo respondido às perguntas formuladas pelos mesmos. De
            acordo com o meu entendimento, o paciente e/ou seu responsável, está em condições de
            compreender o que lhe foi informado.
          </p>
          <SignatureContainer>
            <Col>
              <div>{dataPorExtenso}</div>
            </Col>
            <Col style={{ textAlign: 'center' }}>
              <div><AssignatureLabel width="440px" /></div>
              <div>Carimbo e assinatura Médico</div>
            </Col>
          </SignatureContainer>
          <p>
            Não foi possível a coleta deste Termo de Consentimento Informado, por tratar-se de (em caso de
            emergência dois médicos devem assinar):
          </p>
          <CheckContainer>
            <Row>
              <Col>
                <Form.Check
                  type="checkbox"
                  id={`default-${1}`}
                  label="Situação de Emergência"
                />
              </Col>
              <Col>
                <Form.Check
                  type="checkbox"
                  id={`default-${1}`}
                  label=""
                />
              </Col>
              <Col>
                <Form.Check
                  type="checkbox"
                  id={`default-${1}`}
                  label=""
                />
              </Col>
            </Row>
          </CheckContainer>
          <SignatureContainer>
            <Col>
              <div>{dataPorExtenso}</div>
            </Col>
            <Col style={{ textAlign: 'center' }}>
              <div>
                <AssignatureLabel width="440px" />
              </div>
              <div>
                Carimbo e assinatura Médico
              </div>
            </Col>
          </SignatureContainer>
          <SignatureContainer>
            <Col />
            <Col style={{ textAlign: 'center' }}>
              <div>
                <AssignatureLabel width="440px" />
              </div>
              <div>
                Carimbo e assinatura Médico
              </div>
            </Col>
          </SignatureContainer>
        </PdfBox>
      </Section>
    </Layout>
  )
}

export default CustomPDF