/* eslint-disable react/jsx-no-useless-fragment */
import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { ABO_TYPES } from '../../views/bloodComponent/utils'
import { AppSelect } from '../appSelect'
import { PageTitle } from '../page/styles'
import { LoginModalStyles } from './styles'
import { LoginModalPropTypes } from './types'

export const BloodTypeModal = ({
  show,
  callbackLogin,
  handleClose,
}: LoginModalPropTypes) => {


  const [payload, setPayload] = useState({
    bloodType: undefined,
  })
  const [isValid, setIsValid] = useState(true)
  const bloodTypes = [
    { label: 'A-', value: '1' },
    { label: 'A+', value: '2' },
    { label: 'B-', value: '3' },
    { label: 'B+', value: '4' },
    { label: 'AB-', value: '5' },
    { label: 'AB+', value: '6' },
    { label: 'O-', value: '7' },
    { label: 'O+', value: '8' },
  ]

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setPayload((cur) => ({ ...cur, [target.name]: target.value }))
  }

  const handleSubmit = async () => {
    payload.bloodType = bloodTypes.find((type) => payload.bloodType === type.value)

    callbackLogin(payload)
    handleClose(false)
  }

  useEffect(() => {
    let tempCount = 0
    Object.values(payload)?.forEach((item) => {
      if (item) {
        tempCount += 1
      }
    })

    setIsValid(tempCount === 1)
  }, [payload])


  return (
    <LoginModalStyles show={show} >
      <Modal.Body >
        <div className='logo-wrapper'>
          <PageTitle style={{ textAlign: 'center', fontSize: 12, color: '#4F4F4F' }}>
            Informe o Tipo Sanguíneo:
          </PageTitle>
        </div>
        <AppSelect
          options={[
            { label: 'Selecione o Tipo Sanguíneo', value: '0', disabled: true },
            ...ABO_TYPES
          ]}
          name='bloodType'
          value={payload.bloodType}
          onChange={handleChange}
        />

      </Modal.Body>
      <Modal.Footer>
        <Row className='w-100' style={{ justifyContent: 'space-between', marginTop: 20 }} >
          <Col style={{ flex: 0, padding: 0 }} >
            <Button
              style={{ height: 42, fontSize: 12, width: 93 }}
              variant='primary'
              disabled={!isValid}
              onClick={handleSubmit}
            >
              OK!
            </Button>
          </Col>
          <Col style={{ flex: 0, padding: 0 }}>
            <Button
              style={{ height: 42, fontSize: 12 }}
              variant='outline-secondary'
              onClick={handleClose}
            >
              CANCELAR
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </LoginModalStyles>
  )
}
