import { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Layout } from './components'
import { getUrlData } from './utils'
import { BloodComponenteReceiptPage, Home, TransfusionRequestPage } from './views'
import { BloodComponentPage } from './views/bloodComponent'
import { Feedback } from './views/feedback'
import { ParametrizacaoPage } from './views/parametrizacao'
import { CreateListProfile } from './views/profile'

import { CreateEditUserView, ListUsersView } from './views/users'

declare global {
  interface Window {
    setBiometriaJSF: any
  }
}

export const Router = () => {
  useEffect(() => {
    sessionStorage.setItem('SAMtoken', getUrlData().token)
    return () => {
      sessionStorage.setItem('sgbs_token', '')
      sessionStorage.setItem('sgbs_appkey', '')
      sessionStorage.setItem('SAMtoken', '')
      sessionStorage.setItem('bio_base64', '')
    }
  }, [useLocation])

  window.setBiometriaJSF = (ret) => {
    if (ret[0].value) {
      sessionStorage.setItem('bio_base64', ret[0].value)
    }
  }

  return (
    <Routes>
      <Route path='/' element={<Layout samToken={getUrlData().token} />}>
        <Route index element={<Navigate to='/inicio' />} />
        <Route path='/inicio' element={<Home />} />
        <Route path='/requisicao-transfusao/:id' element={<TransfusionRequestPage />} />
        <Route path='/parametrizacao' element={<ParametrizacaoPage />} />
        <Route path='/hemocomponentes/:id' element={<BloodComponentPage />} />
        <Route path='/usuario/cadastrar' element={<CreateEditUserView />} />
        <Route path='/usuario/editar/:id' element={<CreateEditUserView />} />
        <Route path='/usuario/lista' element={<ListUsersView />} />
        <Route path='/perfis' element={<CreateListProfile />} />
        <Route path='/recebimento-hemocomponente/:id' element={<BloodComponenteReceiptPage />} />
      </Route>
      <Route path='/feedback' element={<Feedback />} />
      <Route path='*' element={<Navigate to='/inicio' />} />
    </Routes>
  )
}
