import { useNavigate } from 'react-router-dom'
// import { ReactComponent as BellIcon } from '../../assets/icons/bell.svg'
import { ReactComponent as CogIcon } from '../../assets/icons/cog.svg'
import { ReactComponent as DashboardIcon } from '../../assets/icons/home.svg'
import { ReactComponent as LogOutIcon } from '../../assets/icons/logout.svg'
import { ReactComponent as StackIcon } from '../../assets/icons/stack.svg'
// import { ReactComponent as UserBaseIcon } from '../../assets/icons/user-base.svg'
import { useAuthContext } from '../../context/auth'
import { validateProfile } from '../../utils'
import { NavItemStyles, NavListStyles, NavListWrapperStyles } from './styles'

export const NavList = () => {
  const { clearAuth, profile } = useAuthContext()
  const navigate = useNavigate()

  return (
    <NavListWrapperStyles>
      <NavListStyles>
        {validateProfile({
          profiles: 'all',
          description: profile || '',
          btn: (
            <NavItemStyles onClick={() => navigate('/inicio')}>
              <DashboardIcon />
              <span>Dashboard</span>
            </NavItemStyles>
          ),
        })}
        {validateProfile({
          profiles: ['administrador'],
          description: profile || '',
          btn: (
            <NavItemStyles onClick={() => navigate('/perfis')}>
              <StackIcon />
              <span>Perfis</span>
            </NavItemStyles>
          ),
        })}
        {validateProfile({
          profiles: ['administrador'],
          description: profile || '',
          btn: (
            <NavItemStyles onClick={() => navigate('/parametrizacao')}>
              <CogIcon />
              <span>Parametrização de Autorização</span>
            </NavItemStyles>
          ),
        })}

        {/* <AccordionStyledNavItem defaultActiveKey='0'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>
              <UserBaseIcon />
              Usuários
            </Accordion.Header>
            <Accordion.Body>
              <NavItemStyles onClick={() => navigate('/usuario/cadastrar')}>
                <span>Criar</span>
              </NavItemStyles>
              <NavItemStyles onClick={() => navigate('/usuario/lista')}>
                <span>Lista</span>
              </NavItemStyles>
            </Accordion.Body>
          </Accordion.Item>
        </AccordionStyledNavItem>
        <NavItemStyles onClick={() => navigate('/notificacoes')}>
          <BellIcon />
          <span>Notificações</span>
        </NavItemStyles> */}
        <NavItemStyles onClick={() => clearAuth()}>
          <LogOutIcon />
          <span>Logout</span>
        </NavItemStyles>
      </NavListStyles>
    </NavListWrapperStyles>
  )
}
